import React, { PureComponent, Fragment } from "react"
import { Navigate } from 'react-router-dom'
import PropTypes from "prop-types"
import { compose } from "redux"
import { connect } from "react-redux"
import View from "../pages/category"
import { getCategories, createCategory, deleteCategory, updateCategory, addChildCategory, removeChildCat } from "../hooks/content"
import { productCount } from "../hooks/dashboard"
import { SnackBar } from "../hooks/common"
import LoaderOutput from "../assets/models/loader-output"
import "./style.css"

const mapStateToProps = (state) => ({
    login: state.login,
    content: state.content,
    dashboard: state.dashboard
})

const mapDispatchToProps = dispatch => ({
    getCategories: () =>dispatch(getCategories()),
    createCategory:(data) =>dispatch(createCategory(data)),
    updateCategory:(data) =>dispatch(updateCategory(data)),
    deleteCategory:(id) =>dispatch(deleteCategory(id)),
    productCount:() =>dispatch(productCount()),
    addChildCategory:(index) =>dispatch(addChildCategory(index)),
    removeChildCat:(index, ChildIndex) =>dispatch(removeChildCat(index, ChildIndex)),
    SnackBar: (show, message, varient) =>dispatch(SnackBar(show, message, varient))
})

const getValidation = (state) => {
    var main_category = null
    var validate =true
    
    if (state.main_category == "") {
        main_category = "Required"
        validate = false
    }

    return {data:{ main_category: main_category },validate:validate}
}

class category extends PureComponent {

    constructor(props) {
        super(props)
        this.timeout =  0;
        this.state = {
            main_category:"",
            sub_category:"",
            error:{
                main_category:null
            },
            edit_id: null,
            edit_value: "",
            backUp: null,
            sub:false
        }
        this.props.getCategories() 
        this.props.productCount()
    }
    handleChange = (event) => {
        this.setState({
            [event.target.name]:event.target.value,
            error: {
                ...this.state.error,
                [event.target.name]:null
            }
        })
    }
    createCategory = () => {
        if(this.props.dashboard.credit_used >= this.props.dashboard.credit_total) {
            this.props.SnackBar(true,'Reached maximum','error')
            return false
        }
        if(!getValidation(this.state).validate) {
            this.setState({ error: getValidation(this.state).data })
        } else {
            this.props.createCategory({
                "id" : 0,
                "parent_id" : 0,
                "user_id" : this.props.login.user.id,
                "title":this.state.main_category,
                "children":null
            })
            this.setState({main_category:""})
        }
    }
    checkEditCategory = (state, edit_value) => {
        if(this.props.dashboard.credit_used >= this.props.dashboard.credit_total) {
            this.props.SnackBar(true,'Reached maximum','error')
            return false
        }
        for (let i = 0; i < this.props.content.categories.length; i++) {
            var data = this.props.content.categories[i]
            if (data.id == state.id && data.title !== edit_value) {
                this.props.updateCategory({
                    "id" : state.id,
                    "parent_id" : state.parent_id,
                    "user_id" : this.props.login.user.id,
                    "title": edit_value
                }) 
            }
            for (let r = 0; r < data.children.length; r++) {
                var child = data.children[r]
                if (child.id == state.id && child.title !== edit_value) {   
                        this.props.updateCategory({
                            "id" : state.id,
                            "parent_id" : state.parent_id,
                            "user_id" : this.props.login.user.id,
                            "title": edit_value
                        }) 
                }
            }
        }
    }
    handleEdit = (data) => {
        if(this.props.dashboard.credit_used >= this.props.dashboard.credit_total) {
            this.props.SnackBar(true,'Reached maximum','error')
            return false
        }
        if (this.timeout) clearTimeout(this.timeout);
        if (this.state.backUp != null) {
            this.checkEditCategory (this.state.backUp,this.state.edit_value)
        }
        this.timeout = setTimeout(() => {
            this.setState({
                edit_id: data.id,
                edit_value: data.title,
                parent_id: data.parent_id,
                backUp: data
            })
        }, 1000);
    }

    handleClose = () => { this.setState({edit_id: null,edit_value: "", backUp: null}) }

    handleUpdate = (cat) => {
        if(this.props.dashboard.credit_used >= this.props.dashboard.credit_total) {
            this.props.SnackBar(true,'Reached maximum','error')
            return false
        }
        if(this.state.edit_value!==null) {
            if(this.timeout) clearTimeout(this.timeout);
            if(this.state.edit_value!==this.state.backUp.title) {           
                this.props.updateCategory({
                    "id" : cat.id,
                    "parent_id" : this.state.parent_id,
                    "user_id" : this.props.login.user.id,
                    "title":this.state.edit_value
                })  
            }
            this.timeout = setTimeout(() => {
                this.handleClose()
              }, 1000);
        } 
    }
    createSubCategory= (id) => { 
        if(this.props.dashboard.credit_used >= this.props.dashboard.credit_total) {
            this.props.SnackBar(true,'Reached maximum','error')
            return false
        }
        if(this.state.sub_category!=="") {
            this.props.createCategory({
                "id" : 0,
                "parent_id" : id,
                "user_id" : this.props.login.user.id,
                "title":this.state.sub_category
            })
            this.setState({sub_category:"",sub: false})
        }
    }
    addChildCategory=(index)=> {
        if(this.props.dashboard.credit_used >= this.props.dashboard.credit_total) {
            this.props.SnackBar(true,'Reached maximum','error')
            return false
        }
        this.setState({ sub: true })
        this.props.addChildCategory(index)
    }
    removeChildCat =(index, childIndex)=> {
        this.setState({ sub: false })
        this.props.removeChildCat(index, childIndex)
    }
    
    render() {
        return (<Fragment>
            {this.props.login.auth !== null ?
                <View
                    {...this.props}
                    {...this.state}
                    handleChange={this.handleChange}
                    createCategory={this.createCategory}
                    handleEdit={this.handleEdit}
                    handleUpdate={this.handleUpdate}
                    handleClose={this.handleClose}
                    addChildCategory={this.addChildCategory}
                    createSubCategory={this.createSubCategory}
                    removeChildCat={this.removeChildCat}
                /> : <Navigate to="/login" replace={true} />} 
                <LoaderOutput />
        </Fragment>)
    }
}

category.propTypes = {
    classes: PropTypes.object.isRequired
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(category)
