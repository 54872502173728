import React from 'react'
import { Container, Row, Col, Button } from 'react-bootstrap'
import logo from "../assets/img/logo-dark.png"
import thankyoulogo from "../assets/img/thankyou-logo.png"

export default function Home(props) {

  return (
    <section className='thankyou-wrapper'>
      <div className='thankyou-wrap'>
        <div className='thankyou-header'>
          <Container>
            <Row>
              <Col md={12}>
                <div className='header-top'>
                  <img
                    src={logo}
                    className="d-inline-block img-fluid"
                    alt=""
                  />
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div className='thankyou-box'>
          <div className='thankinner'>
            <h1>Thank you for your purchase!</h1>
            <p>We are thrilled to have you along for the ride, and introduce to you our cutting-edge AI-generated product description tool, designed to ease the way you showcase your products.</p>
            <p>We believe that your business deserves the best, and that's why we've developed this tool with you in mind.</p>
            <p>Now go ahead and get started</p>
            <p className='teams'>Team eProdx</p>
            <div className='thankyou-icon'>
              <img
                src={thankyoulogo}
                className="d-inline-block img-fluid"
                alt=""
              />
            </div>
            <div className='thankyou-button-wrap'>
              <a href="/"><Button className='btn-getstarted' >Get Started</Button></a>
              <Button className='btn-use'>Learn How to Use</Button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
