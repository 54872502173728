export default function reducer(
    state = {
        output: null,
        searchValue: null,
        scrollOutPut: false,
        content_history: [],
        categories :[]
    }, action) {
    switch (action.type) {
        case "STORE_CONTENT_OUTPUT": {
            return {
                ...state,
                output: action.data != null ? action.data.results : null,
                searchValue: action.data != null ? action.data.searchValues : null
            }
        }
        case "STORE_PRODUCT_DASHBOARD_CLICK": {
            return {
                ...state,
                output: action.data != null ? action.data.results : null,
                searchValue: action.data != null ? action.data.searchValues : null,
                scrollOutPut: true
            }
        }
        case "STORE_EDITED_OUTPUT": {
            return {
                ...state,
                output: action.data.output,
                searchValue: {
                    ...state.searchValue,
                    output_count: action.data.output_count
                }
            }
        }
        case "STORE_REGENERATE_OUTPUT": {
            return {
                ...state,
                output: {
                    ...state.output,
                    [action.title]: Object.values({
                        ...state.output[action.title],
                        [action.index]: action.value
                    })
                }
            }
        }
        case "HANDLE_SCROLL_DOWN": {
            return {
                ...state,
                scrollOutPut: action.bool
            }
        }
        case "STORE_CONTENT_HISTORY": {
            return {
                ...state,
                content_history: action.data
            }
        }
        case "STORE_CATEGORIES": {
            return {
                ...state,
                categories: action.data
            }
        }
        case "ADD_CHILD_CATEGORY": {
            return {
                ...state,
                categories: Object.values({
                    ...state.categories,
                    [action.index]:{
                        ...state.categories[action.index],
                        children:[
                            ...state.categories[action.index].children,
                            {title:""}
                        ]
                    }
                })
            }
        }
        case "REMOVE_CHILD_CATEGORY": {
            return {
                ...state,
                categories: Object.values({
                    ...state.categories,
                    [action.index]:{
                        ...state.categories[action.index],
                        children:[
                            ...state.categories[action.index].children.slice(0, action.childIndex),
                            ...state.categories[action.index].children.slice(action.childIndex+1)
                        ]
                    }
                })
            }
        }
        default:
            break
    }
    return state
}