import React from 'react'
import { headerLogo } from "../assets/img/images"

const View = function (props) {

    return (
        <div className="contents"> <div className="content-inner">
            <div className="login-box regstep tellmore">
                <div className="logo-wrap">
                    <img src={headerLogo} alt="" />
                </div>
                <div className="logo-title">
                    <h1>Tell us a little about your business</h1>
                </div>
                <div className="step-form text-left bname mb-4" >
                    <div className="row">
                        <div className="col-12">
                            <label className="form-label">Brand or business name</label>
                            <input type="text" className="form-control" placeholder="Business name" aria-label="First name" value={props.conState.business_name}
                                name="business_name" onChange={props.handleChange} />
                            {props.conState.error.business_name !== null ? <div className="validate"> <span> * {props.conState.error.business_name} </span> </div> : ""}
                        </div>
                    </div>
                </div>
                <div className="step-form text-left bname mb-4">
                    <div className="row">
                        <div className="col">
                            <label className="form-label">Domain Name</label>
                            <input type="text" className="form-control" placeholder="Domain name" aria-label="First name" value={props.conState.domain}
                                name="domain" onChange={props.handleChange} />
                            {props.conState.error.domain !== null ? <div className="validate"> <span> * {props.conState.error.domain} </span> </div> : ""}
                        </div>
                    </div>
                </div>
                <a onClick={props.nextStep} className="btn-next-step">Proceed to next step</a>
            </div>
        </div>
        </div>

    );
};

export default View;