export default function reducer(
    state = {
        auth : null,
        user_pack: 0,
        data:null,
        loggedIn: false,
        user: null,
        verify:false,
        count:0,
        update_profile: false,
        pack: null,
        pack_list: [],
        project_id:null,
        recover: false,
        domain:[],
        otp_mail:null,
        otp_back:null
    }, action) {
    switch (action.type) {
        case "STORE_USER":{
            return{...state,
                user:action.data,
                update_profile: true
            }
        }
        case "STORE_USER_DETAILS":{
            return{...state,
                user: action.data
            }
        }
        case "STORE_TOKEN":{
            return{...state,
                auth:action.data.token,
                user_pack: action.data.user.current_package !== null ? 1 : 0,
                loggedIn:true
            }
        }
        case "CLEAR_LOGIN": {
            return{...state,
                auth: null,
                user_pack: 0,
                user:null,
                loggedIn: false
            }
        }
        case "STORE_EMAIL_VERIFIED": {
            return{...state,
                user:{
                    ...state.user,
                    email_verified_at:action.data
                }
            }
        }
        case "STORE_TOKEN_VERIFIED": {
            return{...state,
                auth:action.data,
            }
        }
        case "VERIFY_PAGE":{
            return{...state,
                verify:true
            }
        }
        case "STORE_GENERATED_COUNT":{
            return{...state,
                count:action.data
            }
        }
        case "STORE_SOCIAL_DETAILS":{
            return{...state,
                auth: action.data.token,
                user_pack: action.data.user.current_package !== null ? 1 : 0,
                user:action.data.user,
                loggedIn: true
            }
        }
        case "STOP_UPDATE_PROFILE": {
            return{...state,
                update_profile: false
            }
        }
        case "REDIRECT_LOGIN_PAGE": {
            return{...state,
                auth : null,
                user_pack: 0,
                loggedIn: false,
                user: null,
            }
        }
        case "STORE_SELECTED_PACK_DETAILS":{
            return{...state,
                pack_list: action.pack_list,
                pack: action.data
            }
        }
        case"STORE_HOME_SELECTED_PACK_DETAILS" : {
            return{...state,
                pack_list: action.pack_list,
                pack: action.data
            }
        }
        
        case "UPDATE_LOGIN_DETAILS": {
            return{...state,
                user_pack: action.data.user.current_package !== null ? 1 : 0,
                pack_list: action.data.package,
                domain: action.data.domain
            }
        }
        case "STORE_PROJECT_ID": {
            return{...state,
                project_id: action.id
            }
        }
        case "RESET_PASSWORD_LINK": {
            return{...state,
                recover: true
            }
        }
        case "FREE_SUBSCRIBE_PLANE": {
            return{...state,
                user:action.data.user,
            }
        }
        case "ONE_TIME_PASSWORD": {
            return{...state,
                otp_mail: action.mail,
                otp_back: action.data
            }
        }
        default:
            break
    }
    return state
}