import React, { Component } from "react"
import { compose } from "redux"
import { connect } from "react-redux"
import { startLoading } from "../../hooks/common"
import { Modal } from "react-bootstrap"
import '../css/loader.scss'

const mapStateToProps = (state) => ({
    common: state.common
})

const mapDispatchToProps = dispatch => ({
    startLoading: () => dispatch(startLoading())
})

class AlertPop extends Component {

    constructor(props) {
        super(props)

        this.state = {
        }
        
    }

    render() {
        
        return (
            <Modal
                show={this.props.common.spinner}
                className="content-loader-model"
                aria-labelledby="example-custom-modal-styling-title"
            >
                <Modal.Body>
                    <div class="loader">
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                </Modal.Body>
            </Modal>
        )
    }
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(AlertPop)
