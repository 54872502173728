export default function reducer(state = {
        customers: null,
        orders: null
}, action) {
    switch (action.type) {
        case "STORE_SUBSCRIPTION_CUSTOMER": {
            return { ...state, customers: action.data }
        }
        case "STORE_SUBSCRIPTION_ORDER": {
            return { ...state, orders: action.data }
        }
        default:
            break;
    }
    return state;
}