import React, { PureComponent, Fragment } from "react"
import { Navigate   } from 'react-router-dom'
import PropTypes from "prop-types"
import { compose } from "redux"
import { connect } from "react-redux"
import RecoverpasswordView from "../pages/recoverpassword"
import { rocovery, socialLogin } from "../hooks/login"
import Page from "../assets/page"
import "./style.css"

const mapStateToProps = (state) => ({
    login: state.login
})

const mapDispatchToProps = dispatch => ({
    rocovery: (data) => dispatch(rocovery(data)),
    socialLogin: (data) => dispatch(socialLogin(data))
})

const getValidate = (state) => {
    var email = null
    var validate =true
    
    if (state.email == "") {
        email = "Required"
        validate = false
    }
    else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(state.email)) {
        email = "invalid email address"
        validate = false
    }
    return {data:{ email: email },validate:validate}
}

class recoverpassword extends PureComponent {

    constructor(props) {
        super(props)

        this.state = {
            email: "",
            error: {
                email: null
            },
            redirect: false
        }
    }

    componentDidUpdate = () => {
        if(this.props.login.recover) {
            this.setState({
                redirect: true
            })
        }
    }

    handleChange = (e) => {
        this.setState({ 
            [e.target.name]: e.target.value,
            error: {
                ...this.state.error,
                [e.target.name]: null
            }
        })
    }

    handleRecovery = () => {
        if(getValidate(this.state).validate) {
            this.props.rocovery({"email":this.state.email})
        } else {
            this.setState({
                error: getValidate(this.state).data
            })
        }
    }

    render() {
        return (<Fragment>
            <RecoverpasswordView
                {...this.props}
                {...this.state}
                handleChange={this.handleChange}
                handleRecovery={this.handleRecovery}  
            />
            <Page/>
            {this.state.redirect ? <Navigate to="/recover-email" replace={true} /> : null}
        </Fragment>)
    }
}

recoverpassword.propTypes = {
    classes: PropTypes.object.isRequired
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(recoverpassword)
