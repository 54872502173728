import { API_URL } from "../Constants"
import { startLoading, stopLoading, SnackBar } from "./common"
import store from "../store"

export function storeCreditDetails(data) {
    return { type: 'STORE_CREDIT_DETAILS', data };
}
export function getCredits() {
    return function (dispatch) {
        dispatch(startLoading())
        dispatch(SnackBar(true, 'Loading....', 'info'))
        fetch(API_URL + "getCredits=" , {
            method: "GET",
            headers: {
                'Content-Type': 'application/json;charset=utf-8',
                'Authorization': 'Bearer ' + store.getState().login.auth
            }
        }).then(response => response.json())
            .then((json) => {
                // dispatch(storeCreditDetails(json))
                dispatch(stopLoading())
            })
            .catch((error) => {
                dispatch(SnackBar(true, "Connection error", 'error'))
            })
    }
}
export function storeProductsList(data) {
    return { type: 'STORE_PRODUCT_LIST', data };
}
export function getProductsList(search="") {
    return function (dispatch) {
        dispatch(startLoading())
        dispatch(SnackBar(true, 'Loading....', 'info'))
        fetch(API_URL + "products?search=" + search , {
            method: "GET",
            headers: {
                'Content-Type': 'application/json;charset=utf-8',
                'Authorization': 'Bearer ' + store.getState().login.auth
            }
        }).then(response => response.json())
            .then((json) => {
                if(json.status) {
                    dispatch(storeProductsList(json.products))
                }
                dispatch(SnackBar(false, 'Loading....', 'info'))
                dispatch(stopLoading())
            })
            .catch((error) => {
                dispatch(SnackBar(true, "Connection error", 'error'))
            })
    }
}

export function storeProjectId(id) {
    return { type: 'STORE_PROJECT_ID', id };
}

export function storeProductCount(data) {
    return { type: 'STORE_PRODUCT_COUNT', data };
}
export function productCount() {
    return function (dispatch) {
        dispatch(startLoading())
        dispatch(SnackBar(true, 'Loading....', 'info'))
        fetch(API_URL + "product-count" , {
            method: "GET",
            headers: {
                'Content-Type': 'application/json;charset=utf-8',
                'Authorization': 'Bearer ' + store.getState().login.auth
            }
        }).then(response => response.json())
            .then((json) => {
                if(json.status) {
                    dispatch(storeProductCount(json))
                }
                dispatch(stopLoading())
            })
            .catch((error) => {
                dispatch(SnackBar(true, "Connection error", 'error'))
            })
    }
}

export function storeProductDashboardClick(data) {
    return { type: 'STORE_PRODUCT_DASHBOARD_CLICK', data };
}
export function handleContentWriter(id) {
    return function (dispatch) {
        dispatch(startLoading())
        dispatch(SnackBar(true, 'Loading....', 'info'))
        fetch(API_URL + "get-content-history?id=" + id, {
            method: "GET",
            headers: {
                "Content-Type": "application/json;charset=utf-8",
                'Authorization': 'Bearer ' + store.getState().login.auth
            }
        }).then(response => response.json())
            .then((json) => {
                if(json.status) {
                    dispatch(storeProductDashboardClick(json))
                }
            })
            .catch((error) => {
                dispatch(SnackBar(true,'Operation timed out','error'))
            })
    }
}

export function updatedPackCount(data) {
    return { type: 'UPDATE_PACKAGE_COUNT', data };
}