import React, { Component, Fragment } from "react"
import { Navigate } from 'react-router-dom'
import { compose } from "redux"
import { connect } from "react-redux"
import { register, socialLogin, progressRunning } from "../hooks/register"
import { getHomeData } from "../hooks/home"
import Register from "../pages/register"
import Alert from "../assets/Alert"
import Page from "../assets/page"
import '../assets/css/register.css'

const mapStateToProps = (state) => {
    return {
        email: state.register.email,
        logReducer: state.login
    }
}
const mapDispatchToProps = dispatch => ({
    getHomeData: () => dispatch(getHomeData()),
    register: (data) => dispatch(register(data)),
    socialLogin: (data) => dispatch(socialLogin(data)),
    progressRunning: (bool) => dispatch(progressRunning(bool))
})

const getNavigation = (email, login) => {
    var red = ""
    if (email !== null) {
        red = "/verify-email"
    } else if (login.loggedIn && login.user != null && login.user.current_package !== null) {
        red = "/dashboard"
    }
    return red
}

const getValidate = (state) => {
    var first_name = null
    var last_name = null
    var email = null
    var password = null
    var retyp_assword = null
    if (state.first_name == "") {
        first_name = "Required"
    }
    if (state.last_name == "") {
        last_name = "Required"
    }
    if (state.email == "") {
        email = "Required"
    }
    else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(state.email)) {
        email = "invalid email address"
    }
    if (state.password == "") {
        password = "Required"
    }
    if (state.retyp_assword == "") {
        retyp_assword = "Required"
    }

    if (password == null && retyp_assword == null && state.password !== state.retyp_assword) {
        password = "Passwords do not match"
        retyp_assword = "Passwords do not match"
    }
    return { first_name: first_name, last_name: last_name, email: email, password: password, retyp_assword: retyp_assword }
}

class OrdersView extends Component {

    constructor(props) {
        super(props)

        this.state = {
            first_name: "",
            last_name: "",
            email: "",
            password: "",
            retyp_assword: "",
            robot: false,
            password_show: false,
            error: {
                first_name: null,
                last_name: null,
                email: null,
                password: null,
                retyp_assword: null
            },
            capture: null

        }
        this.props.getHomeData()
    }
    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value,
            error: {
                ...this.state.error,
                [e.target.name]: null
            }
        })
    }
    handleCheckbox = (e) => {
        this.setState({
            [e.target.name]: e.target.checked
        })
    }
    handleRegister = () => {
        var state = this.state

        if (state.first_name == "" || state.last_name == "" || state.email == "" || state.password == "" || state.retyp_assword == "") {
            this.setState({
                error: getValidate(this.state)
            })
            return false
        } else if (state.password !== state.retyp_assword) {
            this.setState({
                error: getValidate(this.state)
            })
            return false
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(this.state.email)) {
            this.setState({
                error: getValidate(this.state)
            })
            return false
        }
        if (!state.robot) {
            this.setState({ capture: "Complete the Captcha before proceeding" })
            return false
        }
        this.props.register({
            first_name: state.first_name,
            last_name: state.last_name,
            email: state.email,
            password: state.password,
            retyp_assword: state.retyp_assword
        })
    }
    handleRecaptcha = (value) => {
        this.setState({ robot: value, capture: false })
    }
    handleShowPassword = (e) => {
        this.setState({
            password_show: e.target.checked
        })
    }

    render() {
        return (<Fragment>
            <Register
                {...this.props}
                {...this.state}
                conState={this.state}
                socialLogin={this.props.socialLogin}
                progressRunning={this.props.progressRunning}
                handleChange={this.handleChange}
                handleCheckbox={this.handleCheckbox}
                handleRegister={this.handleRegister}
                handleShowPassword={this.handleShowPassword}
                handleRecaptcha={this.handleRecaptcha} />
            <Alert />
            <Page />
            {getNavigation(this.props.email, this.props.logReducer) !== "" ? <Navigate to={getNavigation(this.props.email, this.props.logReducer)} replace={true} /> : null}

        </Fragment>)
    }
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(OrdersView)
