import React, { PureComponent, Fragment } from "react"
import PropTypes from "prop-types"
import { compose } from "redux"
import { connect } from "react-redux"
import FaqView from "../pages/faq"
import { getFaq, getModel } from "../hooks/common"
import "./style.css"

const mapStateToProps = (state) => ({
    faq: state.common.faq
})

const mapDispatchToProps = dispatch => ({
    getFaq: () => dispatch(getFaq()),
    getModel: () => dispatch(getModel())
})

class Faq extends PureComponent {

    constructor(props) {
        super(props)

        this.state = {
            overview: [],
            title: "",
            key: "0",
            modified_date:"",
            active_key: 0,
            meta_title: "FAQ - Learn More About eProdx AI Solutions",
            meta_description: "Explore frequently asked questions about eProdx AI-powered eCommerce product descriptions. Find answers to common queries about our services and benefits.",
            url: "https://eprodx.ai/faq"
        }
        this.props.getFaq()
    }
    componentDidMount() {
        if (this.state.overview.length === 0 && this.props.faq.length > 0) {
            this.setState({
                title: this.props.faq[0].title,
                overview: this.props.faq[0].overview,
                modified_date: this.props.faq[0].modified_date
            })
        }
    }
    componentDidUpdate() {
        if (this.props.faq.length > 0 && this.state.modified_date === "") {
            this.setState({
                title: this.props.faq[0].title,
                overview: this.props.faq[0].overview,
                modified_date: this.props.faq[0].modified_date
            })
        }
    }

    handleFaq= (data, index) => {
        this.setState({
            title: data.title,
            overview: data.overview,
            key: index,
            modified_date: data.modified_date,
            active_key: index
        })
    }

    render() {
        
        return (
            <Fragment>                
                <FaqView
                    {...this.props}
                    {...this.state}
                    handleFaq={this.handleFaq}
                />
            </Fragment>)
    }
}

Faq.propTypes = {
    classes: PropTypes.object.isRequired
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(Faq)
