export default function reducer(state = {
    credit_used: 0,
    product_used: 0,
    credit_total: null,
    product_total: null,
    products: [],
    content: false
}, action) {
    switch (action.type) {
        case "STORE_PRODUCT_COUNT": {
            return {
                ...state,
                credit_used: action.data.category_count,
                product_used: action.data.product_count,
                credit_total: action.data.category,
                product_total: action.data.product
            }
        }
        case "UPDATE_PACKAGE_COUNT": {
            return{...state,
                credit_total: action.data.category,
                product_total: action.data.product
            }
        }
        case "STORE_PRODUCT_LIST": {
            return { ...state, products: action.data }
        }
        case "STORE_PRODUCT_DASHBOARD_CLICK": {
            return { ...state, content: true }
        }
        default:
            break;
    }
    return state;
}
