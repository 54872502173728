export default function reducer(
    state = {
        profile: {
             name : "",
             first_name : "",
             last_name : "",
             type : "",
             business_name : "",
             google_id : null
        },
        email:null,
        nextStep:false,
        token:null,
        resetPage: false
    }, action) {
    switch (action.type) {
        case "STORE_USER_DETAILS":{
            return{...state,
                profile: action.data
            }
        }
        case "REGISTRATION_SUCCESS":{
            return{...state,
                email: action.data
            }
        }
        case "HANDLE_REGISTER_CHANGE":{
            return{...state,
                profile:{
                    ...state.profile,  
                    [action.name]:action.value
                }
            }
        }
        case "EMAIL_VERIFY_NEXT_STEP":{
            return{...state,
                nextStep:true
            }
        }
        case "HANDLE_SAVE_TOKEN":{
            return{...state,
                token:action.token
            }
        }
        case "HANDLE_RESET_CREDENTIAL": {
            return{...state,
                token: action.token, email: action.email
            } 
        }
        case "RESET_PAGE_REDIRECTION": {
            return{...state,
                resetPage: action.bool
            } 
        }
        default:
            break
    }
    return state
}