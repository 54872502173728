import React, { Component, Fragment } from "react"
import { Navigate } from 'react-router-dom'
import { compose } from "redux"
import { connect } from "react-redux"
import View from "../pages/details"
import Page from "../assets/page"
import { updateProfile, getUser } from "../hooks/register"

const mapStateToProps = (state) => {
    return {
        logReducer: state.login

    }
}

const mapDispatchToProps = dispatch => ({
    getUser: () => dispatch(getUser()),
    updateProfile: (data) => dispatch(updateProfile(data)),
})

const getValidate = (state) => {
    var domain = null
    var business_name = null
    var validate = true
    if (state.domain == null || state.domain == "") {
        domain = "this field is required"
        validate = false
    }
    if (state.business_name == null || state.business_name == "") {
        business_name = "this field is required"
        validate = false
    }
    return { "validate": validate, "data": { "domain": domain, "business_name": business_name } }
}

const getRedirection = (redirect, auth) => {
    var page = ""
    if (auth == null||auth == "") {
        page = "/login"
    } else if (redirect) {
        page = "/dashboard"
    }
    return page
}
class OrdersView extends Component {

    constructor(props) {
        super(props)

        this.state = {
            type: "Brand",
            business_name: null,
            domain: "",
            error: {
                business_name: null,
                domain: null
            },
            redirect: false
        }
        this.props.getUser()

    }
    componentDidUpdate = () => {
        if (this.props.logReducer.user.business_name != null && this.props.logReducer.user.business_name != "" && !this.state.redirect) {
            this.setState({
                redirect: true
            })
        }
    }
    handleChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value,
            error: {
                ...this.state.error,
                [event.target.name]: null
            }
        })
    }
    nextStep = () => {
        var data = this.state
        if (!getValidate(data).validate) {
            this.setState({
                error: getValidate(data).data
            })
            return false
        } else {
            this.props.updateProfile({
                "name": this.props.logReducer.user.name,
                "first_name": this.props.logReducer.user.first_name,
                "last_name": this.props.logReducer.user.last_name,
                "type": "Brand",
                "business_name": this.state.business_name,
                "domain": this.state.domain,
                "google_id": this.props.logReducer.user.google_id,
            })
        }

    }

    render() {
        return (<Fragment>
            <View
                conState={this.state}
                conProps={this.props}
                handleChange={this.handleChange}
                nextStep={this.nextStep} />
            <Page />
            {getRedirection(this.state.redirect, this.props.logReducer.auth) !== "" ?
                <Navigate to={getRedirection(this.state.redirect, this.props.logReducer.auth)} replace={true} />
                : null}
        </Fragment>)
    }
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(OrdersView)