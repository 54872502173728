import React, { PureComponent, Fragment } from "react"
import { Navigate } from 'react-router-dom'
import PropTypes from "prop-types"
import { compose } from "redux"
import { connect } from "react-redux"
import Forgotpasswordiew from "../pages/forgotpassword"
import { handleResetCredential, updatePassword } from "../hooks/register"
import { SnackBar } from "../hooks/common"
import "./style.css"

const mapStateToProps = (state) => ({
    register: state.register
})

const mapDispatchToProps = dispatch => ({
    handleResetCredential: (token, email) => dispatch(handleResetCredential(token, email)),
    updatePassword: (data) => dispatch(updatePassword(data)),
    SnackBar: (show, message, varient) => dispatch(SnackBar(show, message, varient))
})

const getValidate = (state) => {
    var password = null
    var validate = true

    if (state.password == "") {
        password = "Required"
        validate = false
    }
    return { data: { password: password }, validate: validate }
}

class forgotpassword extends PureComponent {

    constructor(props) {
        super(props)

        this.state = {
            password: "",
            error: {
                password: null
            },
            redirect: false
        }
    }
    componentDidUpdate = () => {
        if (this.props.register.resetPage && !this.state.redirect) {
            this.setState({
                redirect: true
            })
        }
    }
    handleSaveToken = (token, email) => {
        this.props.handleResetCredential(token, email)
    }
    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value,
            error: {
                ...this.state.error,
                [e.target.name]: null
            }
        })
    }
    updatePassword = () => {
        if (getValidate(this.state).validate) {
            this.props.updatePassword({
                email: this.props.register.email,
                token: this.props.register.token,
                password: this.state.password
            })
        } else {
            this.setState({
                error: getValidate(this.state).data
            })
        }
    }

    render() {

        return (<Fragment>
            <Forgotpasswordiew
                {...this.props}
                {...this.state}
                storeToken={this.props.register.token == null && this.props.register.email == null}
                handleSaveToken={this.handleSaveToken}
                handleChange={this.handleChange}
                updatePassword={this.updatePassword}
            />
            {this.state.redirect ? <Navigate to="/login" replace={true} /> : null}
        </Fragment>)
    }
}

forgotpassword.propTypes = {
    classes: PropTypes.object.isRequired
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(forgotpassword)
