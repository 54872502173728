import React, { PureComponent, Fragment } from "react"
import { Navigate } from 'react-router-dom'
import PropTypes from "prop-types"
import { compose } from "redux"
import { connect } from "react-redux"
import SubscriptionView from "../pages/dashboardprofileSubscription"
import {
    stopProfileSubscriptionBillUpdate, getBillInfo, updateBillInfo, getPaymentHistory,
    subscriptionMyPlane
} from "../hooks/profileSubscriptionInfo"
import "./style.css"

const mapStateToProps = (state) => ({
    profile: state.login.user,
    profileSubcription: state.profileSubcription,
    login: state.login,
    home: state.home
})

const mapDispatchToProps = dispatch => ({
    getBillInfo: () => dispatch(getBillInfo()),
    subscriptionMyPlane: () => dispatch(subscriptionMyPlane()),
    getPaymentHistory: () => dispatch(getPaymentHistory()),
    stopProfileSubscriptionBillUpdate: () => dispatch(stopProfileSubscriptionBillUpdate()),
    updateBillInfo: (data) => dispatch(updateBillInfo(data)),
    // exportPdfPayment:(id)=> dispatch(exportPdfPayment(id))
})

const getPackage = (data, type) => {
    var dataArray = []
    for (let index = 0; index < data.length; index++) {
        if (!data[index].contact_us) {
            if (data[index].billing_cycle == "1" && type === "year" && data[index].status == "1") {
                dataArray = [
                    ...dataArray,
                    data[index]
                ]
            } else if (data[index].billing_cycle != "1" && type === "month" && data[index].status == "1") {
                dataArray = [
                    ...dataArray,
                    data[index]
                ]
            }
        }
    }
    return dataArray
}

class Dashboardsubscription extends PureComponent {

    constructor(props) {
        super(props)

        this.state = {
            bill_info: false,
            name: "",
            company: "",
            street_address: "",
            state: "",
            zip_code: "",
            country: "",
            additional_email: "",
            packageUpdate: true,
            pack_show: "month",
            packDetails: [],
        }
        this.props.subscriptionMyPlane()
        this.props.getPaymentHistory()
    }

    componentDidMount = () => {
        if (this.props.profileSubcription.billing_info_update) {
            this.props.stopProfileSubscriptionBillUpdate()
            this.setState({
                name: this.props.profileSubcription.billing_info.name,
                company: this.props.profileSubcription.billing_info.company,
                street_address: this.props.profileSubcription.billing_info.street_address,
                state: this.props.profileSubcription.billing_info.state,
                zip_code: this.props.profileSubcription.billing_info.zip_code,
                country: this.props.profileSubcription.billing_info.country,
                additional_email: this.props.profileSubcription.billing_info.additional_email
            })
        }
    }
    componentDidUpdate = () => {
        if (this.props.profileSubcription.billing_info_update) {
            this.props.stopProfileSubscriptionBillUpdate()
            this.setState({
                name: this.props.profileSubcription.billing_info.name,
                company: this.props.profileSubcription.billing_info.company,
                street_address: this.props.profileSubcription.billing_info.street_address,
                state: this.props.profileSubcription.billing_info.state,
                zip_code: this.props.profileSubcription.billing_info.zip_code,
                country: this.props.profileSubcription.billing_info.country,
                additional_email: this.props.profileSubcription.billing_info.additional_email
            })
        }
        if (this.props.home.data !== null && this.state.packageUpdate) {
            this.setState({
                packageUpdate: false,
                packDetails: getPackage(this.props.home.data.packages, this.state.pack_show)
            })
        }
    }
    billInfoEdit = () => {
        this.setState({ bill_info: true })
    }
    billInfoDone = () => {
        this.setState({ bill_info: false })
        this.props.updateBillInfo({
            name: this.state.name,
            company: this.state.company,
            street_address: this.state.street_address,
            state: this.state.state,
            zip_code: this.state.zip_code,
            country: this.state.country,
            additional_email: this.state.additional_email
        })
    }
    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }

    handleMode = (check) => {
        this.setState({
            pack_show: check,
            packDetails: getPackage(this.props.home.data.packages, check)
        })
    }


    render() {
        return (
            <Fragment>
                {this.props.login.auth !== null ?
                    <SubscriptionView
                        {...this.props}
                        {...this.state}
                        billInfoEdit={this.billInfoEdit}
                        handleChange={this.handleChange}
                        billInfoDone={this.billInfoDone}
                        handleMode={this.handleMode}
                    /> :
                    <Navigate to="/login" replace={true} />}
            </Fragment>)
    }
}

Dashboardsubscription.propTypes = {
    classes: PropTypes.object.isRequired
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(Dashboardsubscription)
