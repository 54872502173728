import React, { Fragment } from 'react'
import { Container, Row, Col, Button, Accordion, Tab, Tabs, Breadcrumb, Table, Form } from 'react-bootstrap'
import sidebanner from "../assets/img/ad-banner.png"
import Header from "../assets/header-dashboard"
import Footer from "../assets/footer"

export default function Home(props) {

  return (
    <div>
      <Header />
      <div className='content-wrap- dash'>
        <Container>
          <Row>
            <Col md={12}>
              <div className='dash-content reports'>
                <div className='breadscrumb-wrap'>
                  <Breadcrumb>
                    <Breadcrumb.Item href="#">Dashboard</Breadcrumb.Item>
                    <Breadcrumb.Item active>Reports</Breadcrumb.Item>
                  </Breadcrumb>
                </div>
                <h2>Profile Settings</h2>
                <div className='quick-info-bar'>
                  <span className='mailcheck'>malik@eprodx.com</span>
                  <span>ID:1234567890</span>
                  <span>Registration Date: Aug 11. 2022</span>
                </div>
                <div className='reports-wrap'>
                  <div className='report-result'>
                    <Accordion>
                      <Accordion.Item eventKey="0">
                        <Accordion.Header>Data</Accordion.Header>
                        <Accordion.Body>
                          <div className='report-inner'>
                            <Tabs
                              defaultActiveKey="home"
                              id="uncontrolled-tab-example"
                              className="mb-3"
                            >
                              <Tab eventKey="home" title="Generated Content">
                                <Row>
                                  <Col md={6}>
                                    <div className='reportcol1'>
                                      <div className='chartwrap'>
                                        <h3>Tokens</h3>
                                        <div class="chart">
                                          chart widget
                                        </div>
                                      </div>
                                      <div className='chartwrap'>
                                        <h3>Storage</h3>
                                        <div class="chart">
                                          chart widget
                                        </div>
                                      </div>

                                    </div>
                                  </Col>
                                  <Col md={6}>
                                    <div className='reportcol2'>
                                      <div class="report-tbl-wrap">
                                        <Table  hover>
                                          <thead>
                                            <tr>
                                              <th></th>
                                              <th>Tokens</th>
                                              <th>Storage</th>                                              
                                            </tr>
                                          </thead>
                                          <tbody>
                                            <tr>
                                              <td>Available</td>
                                              <td>120,000</td>
                                              <td>50 gb</td>
                                            </tr>
                                            <tr>
                                              <td>Available</td>
                                              <td>120,000</td>
                                              <td>50 gb</td>
                                            </tr>                                                
                                          </tbody>
                                        </Table>
                                      </div>
                                    </div>
                                  </Col>
                                </Row>
                                <Row className='mt-5'>
                                  <Col md={12}>
                                    <div className='report-tbl-wrap2'>
                                      <div className='report-tbl2-header'>
                                        <div className='tbl-header'>
                                          <h3>Token Usage by Customer</h3>
                                          <h6>July 9 - Sep , 2023</h6>
                                        </div>
                                        <Button className='btn-download'></Button>
                                      </div>
                                      <div className='tbl-report2'>
                                          <Table  hover>
                                            <thead>
                                              <tr>
                                                <th>Customer ID</th>
                                                <th>Account Name</th>
                                                <th>Tokens</th>
                                              </tr>
                                            </thead>
                                            <tbody>
                                              <tr>
                                                <td>C000001</td>
                                                <td>Amazon</td>
                                                <td>11,200</td>
                                              </tr>
                                              <tr>
                                                <td>C000001</td>
                                                <td>Amazon</td>
                                                <td>11,200</td>
                                              </tr>
                                              <tr>
                                                <td>C000001</td>
                                                <td>Amazon</td>
                                                <td>11,200</td>
                                              </tr>
                                         
                                            </tbody>
                                         </Table>                                    
                                      </div>
                                    </div>
                                    <div className='report-tbl-wrap2'>
                                      <div className='report-tbl2-header'>
                                        <div className='tbl-header'>
                                          <h3>Storage Usage by Customer</h3>
                                          <h6>July 9 - Sep , 2023</h6>
                                        </div>
                                        <Button className='btn-download'></Button>
                                      </div>
                                      <div className='tbl-report2'>
                                          <Table  hover>
                                            <thead>
                                              <tr>
                                                <th>Customer ID</th>
                                                <th>Account Name</th>
                                                <th>Storage in GB</th>
                                              </tr>
                                            </thead>
                                            <tbody>
                                              <tr>
                                                <td>C000001</td>
                                                <td>Amazon</td>
                                                <td>11,200</td>
                                              </tr>
                                              <tr>
                                                <td>C000001</td>
                                                <td>Amazon</td>
                                                <td>11,200</td>
                                              </tr>
                                              <tr>
                                                <td>C000001</td>
                                                <td>Amazon</td>
                                                <td>11,200</td>
                                              </tr>                                         
                                            </tbody>
                                         </Table>                                    
                                      </div>
                                    </div>
                                  </Col>
                                </Row>
                              </Tab>
                              <Tab eventKey="profile" title="Exported Content">
                              <Row>
                                  <Col md={12}>
                                    <div className='reportcol1 export'>
                                      <div className='duration-wrap'>
                                        <Form.Select aria-label="Default select example">
                                          <option>Last 90 days</option>
                                          <option value="1">Last 120 days</option>
                                        </Form.Select>
                                      </div>
                                      <div className='chartwrap'>
                                        <div class="chart">
                                          chart widget
                                        </div>
                                      </div>
                                    </div>
                                  </Col>                                  
                                </Row>
                                <Row className='mt-5'>
                                  <Col md={12}>
                                    <div className='report-tbl-wrap2'>
                                      <div className='report-tbl2-header'>
                                        <div className='tbl-header'>
                                          <h3>Token Usage by Customer</h3>
                                          <h6>July 9 - Sep , 2023</h6>
                                        </div>
                                        <Button className='btn-download'></Button>
                                      </div>
                                      <div className='tbl-report2'>
                                          <Table  hover>
                                            <thead>
                                              <tr>
                                                <th>Customer ID</th>
                                                <th>Account Name</th>
                                                <th>Tokens</th>
                                              </tr>
                                            </thead>
                                            <tbody>
                                              <tr>
                                                <td>C000001</td>
                                                <td>Amazon</td>
                                                <td>11,200</td>
                                              </tr>
                                              <tr>
                                                <td>C000001</td>
                                                <td>Amazon</td>
                                                <td>11,200</td>
                                              </tr>
                                              <tr>
                                                <td>C000001</td>
                                                <td>Amazon</td>
                                                <td>11,200</td>
                                              </tr>
                                         
                                            </tbody>
                                         </Table>                                    
                                      </div>
                                    </div>
                                   
                                  </Col>
                                </Row>
                              </Tab>
                            </Tabs>
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      {<Footer />}
    </div>
  );
};
