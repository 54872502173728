import React, { Fragment } from 'react'
import { Container, Row, Col, Button, Tab, Tabs, Breadcrumb, Nav, Form, Table, CloseButton } from 'react-bootstrap'
import sidebanner from "../assets/img/ad-banner.png"
import Header from "../assets/header-dashboard"
import Footer from "../assets/footer"
import Moment from 'moment'

export default function Home(props) {

  return (
    <div>
      <Header />
      <div className='content-wrap- dash'>
        <Container>
          <Row>
            <Col md={3}>
              <div className='dash-sidebar'>
                <Nav defaultActiveKey="" className="flex-column">
                  <Nav.Link href="/dashboardprofile" className='sidelink ps'>Profile Settings</Nav.Link>
                  <Nav.Link className='sidelink active si'>Subscription Info</Nav.Link>
                  <Nav.Link className='sidelink um'>User Management</Nav.Link>
                </Nav>
                <div className='sidebanner'>
                  <img
                    src={sidebanner}
                    className="d-inline-block img-fluid"
                  />
                </div>
              </div>

            </Col>
            <Col md={9}>
              <div className='dash-content'>
                <div className='breadscrumb-wrap'>
                  <Breadcrumb>
                    <Breadcrumb.Item>Dashboard</Breadcrumb.Item>
                    <Breadcrumb.Item active>Subscription Info</Breadcrumb.Item>
                  </Breadcrumb>
                </div>
                <h2>Subscription Information</h2>
                <div className='quick-info-bar'>
                  <span className='mailcheck'>{props.profile.email}</span>
                  <span>ID: {props.profile.id}</span>
                  <span>Registration Date: {Moment(props.profile.created_at).format('MMMM DD. YYYY')} </span>
                </div>
                <div className='dashtabs t2'>
                  <Tabs
                    defaultActiveKey="My Plan"
                    id="justify-tab-example"
                    className="mb-5"

                  >
                    <Tab eventKey="My Plan" title="My Plan">
                      <h3></h3>
                      {props.profileSubcription.my_plane != null ?
                        <div className='tab-content-wrap'>
                          <div className='tab-content-header'>
                            <h3>My Plan: <span>{props.profileSubcription.my_plane.package.package_name}</span></h3>
                            <div className='tab-header-btnwrap'>
                              <Button variant="primary">Upgrade</Button>
                            </div>
                          </div>
                          <div className='tab-contents-inner'>
                            <Row>
                              <Col md={8}>
                                <div className='plan-col1'>
                                  <div className='plan-info'>
                                    <h3>Users</h3>
                                    <div className='plan-row'>
                                      <h4>Admin</h4>
                                      <h5><span>1</span> of 1</h5>
                                    </div>
                                    <div className='plan-row'>
                                      <h4>Delegation</h4>
                                      <h5><span>X</span></h5>
                                    </div>
                                    <p>Note: The “available” number shows how many units the user has used from total available for the plan.</p>
                                  </div>

                                  <div className='plan-info'>
                                    <h3>Catalog</h3>
                                    <div className='plan-row'>
                                      <h4>Number of Categories</h4>
                                      <h5><span>2</span> of 3</h5>
                                    </div>
                                    <div className='plan-row'>
                                      <h4>Number of Products</h4>
                                      <h5><span>2</span> of 3</h5>
                                    </div>
                                    <div className='plan-row'>
                                      <h4>Number of Attributes</h4>
                                      <h5><span>2</span> of 3</h5>
                                    </div>
                                    <div className='plan-row'>
                                      <h4>Product Type – Standard*</h4>
                                      <h5><span>2</span> of 3</h5>
                                    </div>
                                    <p>Free Starter* - People will get only 10 free products. Once they use it, it's gone They can continue to use it by paying $4.99 for the Essential Elevate or upgrading to the Growth Accelerator package.</p>
                                  </div>

                                  <div className='plan-info'>
                                    <h3>Output Versions Per SKU</h3>
                                    <div className='plan-row'>
                                      <h4>Product Title</h4>
                                      <h5><span>{props.profileSubcription.my_plane.product_output != undefined ? props.profileSubcription.my_plane.product_output.product_titles : ""}</span></h5>
                                    </div>
                                    <div className='plan-row'>
                                      <h4>Number of Products</h4>
                                      <h5><span>3</span></h5>
                                    </div>
                                    <div className='plan-row'>
                                      <h4>Number of Attributes</h4>
                                      <h5><span>3</span></h5>
                                    </div>
                                    <div className='plan-row'>
                                      <h4>Product Type – Standard*</h4>
                                      <h5><span>3</span></h5>
                                    </div>
                                  </div>

                                  <div className='plan-info'>
                                    <h3>Bulk Operation</h3>
                                    <div className='plan-row'>
                                      <h4>Import</h4>
                                      <h5><span>n/a</span></h5>
                                    </div>
                                    <div className='plan-row'>
                                      <h4>Export</h4>
                                      <h5><span>1</span> of 2</h5>
                                    </div>
                                    <div className='plan-row'>
                                      <h4>Storage</h4>
                                      <h5><span>Filesize</span></h5>
                                    </div>

                                  </div>

                                </div>
                              </Col>
                              <Col md={4}>
                                <div className='plan-col2'>
                                  <div className='right-sidebar'>
                                    <h5>Help</h5>
                                    <Nav defaultActiveKey="/home" className="flex-column">
                                      <Nav.Link href="#">Billing and Account</Nav.Link>
                                      <Nav.Link href="/terms-of-service">Terms of Service</Nav.Link>
                                      <Nav.Link href="#">Refund Policy</Nav.Link>
                                      <Nav.Link href="#">Cancellation Policy</Nav.Link>
                                      <Nav.Link href="/privacy-policy">Privacy Policy</Nav.Link>
                                    </Nav>


                                  </div>
                                </div>
                              </Col>
                            </Row>
                          </div>
                          <div className='tab-content-footer'>
                            <div className='tab-footer-btnwrap'>
                              <Button variant="primary">Upgrade</Button>
                            </div>
                          </div>
                        </div> : ""}
                    </Tab>
                    <Tab eventKey="Upgrade" title="Upgrade">
                      <div className='tab-content-wrap'>
                        <div className='tab-content-header'>
                          <h3>Upgrade Your Plan</h3>
                          <div className='tab-header-btnwrap'>

                            <div class="btn-group switcher" role="group" aria-label="Basic example">
                              <button onClick={() => props.handleMode("month")} type="button" class={"btn btn-primary draft" + (props.pack_show == "month" ? " active" : "")}>Monthly</button>
                              <button onClick={() => props.handleMode("year")} type="button" class={"btn btn-primary live" + (props.pack_show == "year" ? " active" : "")}>Yearly</button>
                            </div>

                          </div>
                        </div>

                        <div className='tab-contents-inner'>
                          <div className='updateplan-box'>
                            <div className='updateplan-row-wrap'>
                              <div className='updateplan-row-head'>
                                <div className='updateplan-row-head-col1'>
                                  <h4>My Plan</h4>
                                </div>

                                {props.packDetails.map((pack) => (
                                  <div className='updateplan-row-head-col2'>
                                    <h5> {pack.package_name} </h5>
                                  </div>
                                ))}
                              </div>
                              <div className='updateplan-row'>
                                <div className='updateplan-row-col1'>
                                  <h6>Price</h6>
                                </div>
                                {props.packDetails.map((pack, index) => (
                                  <div className='updateplan-row-head-col2'>
                                    <h5> {pack.package_price} </h5>
                                    <Button className='btn-save-more'>Save 1 mos.</Button>
                                  </div>
                                ))}
                              </div>
                              <div className='updateplan-row no-border'>
                                <div className='updateplan-row-col1'>
                                  <h4></h4>
                                </div>
                                {props.packDetails.map((pack) => (
                                  <div className='updateplan-row-col2'>
                                    <Button variant="outline-primary" className='btn-select-plan' >Select Plan</Button>
                                  </div>
                                ))}
                              </div>
                            </div>
                            <div className='updateplan-row-wrap'>
                              <div className='updateplan-row-head'>
                                <div className='updateplan-row-head-col1'>
                                  <h4>Users</h4>
                                </div>
                                {props.packDetails.map((pack) => (
                                  <div className='updateplan-row-head-col2'>
                                    <h5> {pack.package_name} </h5>
                                  </div>
                                ))}
                              </div>
                              <div className='updateplan-row'>
                                <div className='updateplan-row-col1'>
                                  <h6>Users</h6>
                                </div>
                                {props.packDetails.map((pack) => (
                                  <div className='updateplan-row-col2'>
                                    <h4>1</h4>
                                  </div>
                                ))}
                              </div>
                              <div className='updateplan-row no-border'>
                                <div className='updateplan-row-col1'>
                                  <h4></h4>
                                </div>
                                <div className='updateplan-row-col2'>
                                </div>
                                <div className='updateplan-row-col3'>
                                </div>
                              </div>
                            </div>
                            <div className='updateplan-row-wrap rgap'>
                              <div className='updateplan-row-head'>
                                <div className='updateplan-row-head-col1'>
                                  <h4>Catalog</h4>
                                </div>
                                {props.packDetails.map((pack) => (
                                  <div className='updateplan-row-head-col2'>
                                    <h5> {pack.package_name} </h5>
                                  </div>
                                ))}
                              </div>

                              <div className='updateplan-row'>
                                <div className='updateplan-row-col1'>
                                  <h6>Number of Categories</h6>
                                </div>
                                {props.packDetails.map((pack) => (
                                  <div className='updateplan-row-col2'>
                                    <h4><span>2</span> of 3</h4>
                                  </div>
                                ))}
                              </div>
                              <div className='updateplan-row'>
                                <div className='updateplan-row-col1'>
                                  <h6>Number of Products</h6>
                                </div>
                                {props.packDetails.map((pack) => (
                                  <div className='updateplan-row-col3'>
                                    <h4><span>5</span> of 10</h4>
                                  </div>
                                ))}
                              </div>
                              <div className='updateplan-row'>
                                <div className='updateplan-row-col1'>
                                  <h6>Number of Attributes</h6>
                                </div>
                                {props.packDetails.map((pack) => (
                                  <div className='updateplan-row-col3'>
                                    <h4><span>5</span> of 10</h4>
                                  </div>
                                ))}
                              </div>
                              <div className='updateplan-row'>
                                <div className='updateplan-row-col1'>
                                  <h6>Product Type – Standard*</h6>
                                </div>
                                {props.packDetails.map((pack) => (
                                  <div className='updateplan-row-col3'>
                                    <h4><span>5</span> of 10</h4>
                                  </div>
                                ))}
                              </div>
                            </div>
                            <div className='updateplan-row-wrap rgap'>
                              <div className='updateplan-row-head'>
                                <div className='updateplan-row-head-col1'>
                                  <h4>Output Versions Per SKU</h4>
                                </div>
                                {props.packDetails.map((pack) => (
                                  <div className='updateplan-row-head-col2'>
                                    <h5> {pack.package_name} </h5>
                                  </div>
                                ))}
                              </div>
                              <div className='updateplan-row'>
                                <div className='updateplan-row-col1'>
                                  <h6>Product Title</h6>
                                </div>
                                {props.packDetails.map((pack) => (
                                  <div className='updateplan-row-col3'>
                                    <h4> 3 </h4>
                                  </div>
                                ))}
                              </div>
                              <div className='updateplan-row'>
                                <div className='updateplan-row-col1'>
                                  <h6>Introduction</h6>
                                </div>
                                {props.packDetails.map((pack) => (
                                  <div className='updateplan-row-col3'>
                                    <h4> 10 </h4>
                                  </div>
                                ))}
                              </div>
                              <div className='updateplan-row'>
                                <div className='updateplan-row-col1'>
                                  <h6>Feature Bullets</h6>
                                </div>
                                {props.packDetails.map((pack) => (
                                  <div className='updateplan-row-col3'>
                                    <h4>10</h4>
                                  </div>
                                ))}
                              </div>
                              <div className='updateplan-row'>
                                <div className='updateplan-row-col1'>
                                  <h6>Detailed Description</h6>
                                </div>
                                {props.packDetails.map((pack) => (
                                  <div className='updateplan-row-col3'>
                                    <h4>10</h4>
                                  </div>
                                ))}
                              </div>
                              <div className='updateplan-row'>
                                <div className='updateplan-row-col1'>
                                  <h6>Closing Statement</h6>
                                </div>
                                {props.packDetails.map((pack) => (
                                  <div className='updateplan-row-col3'>
                                    <h4>10</h4>
                                  </div>
                                ))}
                              </div>
                            </div>
                            <div className='updateplan-row-wrap rgap'>
                              <div className='updateplan-row-head'>
                                <div className='updateplan-row-head-col1'>
                                  <h4>Bulk Operation</h4>
                                </div>
                                {props.packDetails.map((pack) => (
                                  <div className='updateplan-row-head-col2'>
                                    <h5> {pack.package_name} </h5>
                                  </div>
                                ))}
                              </div>
                              <div className='updateplan-row'>
                                <div className='updateplan-row-col1'>
                                  <h6>Import</h6>
                                </div>
                                {props.packDetails.map((pack) => (
                                  <div className='updateplan-row-col3'>
                                    <h4><span>2</span> of 3</h4>
                                  </div>
                                ))}
                              </div>
                              <div className='updateplan-row'>
                                <div className='updateplan-row-col1'>
                                  <h6>Export</h6>
                                </div>
                                {props.packDetails.map((pack) => (
                                  <div className='updateplan-row-col3'>
                                    <h4><span>5</span> of 10</h4>
                                  </div>
                                ))}
                              </div>
                              <div className='updateplan-row'>
                                <div className='updateplan-row-col1'>
                                  <h6>Storage</h6>
                                </div>
                                {props.packDetails.map((pack) => (
                                  <div className='updateplan-row-col3'>
                                    <h4><span>Filesize</span></h4>
                                  </div>
                                ))}
                              </div>
                              <p className='note'>Retention Policy – Beyond 60 days it will be deleted for free and archived for Essential and Growth</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Tab>
                    <Tab eventKey="Billing Info" title="Billing Info">
                      <div className='tab-content-wrap billing'>
                        <div className='tab-content-header'>
                          <h3>Billing Information</h3>
                          <div className='tab-header-btnwrap'>
                            {props.bill_info ?
                              <Button variant="primary" onClick={props.billInfoDone}>Done</Button> : <Button variant="primary" onClick={props.billInfoEdit}>Edit</Button>
                            }
                          </div>
                        </div>
                        <div className='tab-contents-inner'>
                          <div className='bill-info'>
                            <p>Changes to your billing information will take effect starting with the next scheduled payment and will be reflected on your next invoice</p>
                          </div>
                          <div className='bill-detail'>
                            <div className='bill-detail-row'>
                              <h5>Name</h5>
                              {props.bill_info ?
                                <input className="prompt-engneering-input" name="name" value={props.name} onChange={props.handleChange} />
                                : <h6>{props.name}</h6>}
                            </div>
                            <div className='bill-detail-row'>
                              <h5>Company</h5>
                              {props.bill_info ?
                                <input className="prompt-engneering-input" name="company" value={props.company} onChange={props.handleChange} />
                                : <h6>{props.company}</h6>}
                            </div>
                            <div className='bill-detail-row'>
                              <h5>Street  Address</h5>
                              {props.bill_info ?
                                <input className="prompt-engneering-input" name="street_address" value={props.street_address} onChange={props.handleChange} />
                                : <h6>{props.street_address}</h6>}
                            </div>
                            <div className='bill-detail-row'>
                              <h5>State</h5>
                              {props.bill_info ?
                                <input className="prompt-engneering-input" name="state" value={props.state} onChange={props.handleChange} />
                                : <h6>{props.state}</h6>}
                            </div>
                            <div className='bill-detail-row'>
                              <h5>Zip Code</h5>
                              {props.bill_info ?
                                <input className="prompt-engneering-input" name="zip_code" value={props.zip_code} onChange={props.handleChange} />
                                : <h6>{props.zip_code}</h6>}
                            </div>
                            <div className='bill-detail-row'>
                              <h5>Country</h5>
                              {props.bill_info ?
                                <input className="prompt-engneering-input" name="country" value={props.country} onChange={props.handleChange} />
                                : <h6>{props.country}</h6>}
                            </div>
                            <div className='bill-detail-row'>
                              <h5>Additional Email</h5>
                              {props.bill_info ?
                                <input className="prompt-engneering-input" name="additional_email" value={props.additional_email} onChange={props.handleChange} />
                                : <h6>{props.additional_email}</h6>}
                            </div>
                          </div>
                        </div>
                      </div>
                    </Tab>
                    <Tab eventKey="Payment History" title="Payment History">
                      <div className='tab-content-wrap no-bg'>
                        <div className='payment-history-wrap'>
                          <div className='tab-content-header'>
                            <h3>Payments</h3>
                          </div>
                          <div className='tab-contents-inner'>
                            <div className='bill-info phistory'>
                              <p>We also send a copy of your invoice to </p>
                              <div className='copymail'>
                                <Form.Group as={Col} className='ps-wrap'>
                                  <Form.Control type="text" placeholder="someone@businessemail.com" />
                                  <Button className='btn-mailcopy'></Button>
                                </Form.Group>
                              </div>
                            </div>

                          </div>
                        </div>
                        <div className='payment-history-wrap'>
                          <div className='tab-content-header'>
                            <h3>Payment History</h3>
                          </div>
                          <div className='tab-contents-inner'>
                            <Table hover>
                              <thead>
                                <tr>
                                  <th>Payment ID</th>
                                  <th>Date (GMT -4)</th>
                                  <th>Product</th>
                                  <th>Transaction Amount</th>
                                  <th>Tax Amount</th>
                                  <th>Status</th>
                                  <th>Method</th>
                                  <th>Invoice</th>
                                </tr>
                              </thead>
                              <tbody>
                                {props.profileSubcription.payment_history !== null ? props.profileSubcription.payment_history.map((payment) => (
                                  <tr>
                                    <td>{payment.id}</td>
                                    <td> {Moment(payment.date).format('MMMM DD. YYYY')} </td>
                                    <td>{payment.package}</td>
                                    <td>${payment.order_value}</td>
                                    <td>$0.00</td>
                                    <td>Success</td>
                                    <td>Card</td>
                                    <td><Button className='invoice-download' onClick={() => props.exportPdfPayment(payment.id)}></Button></td>
                                  </tr>
                                )) : ""}
                              </tbody>
                            </Table>
                          </div>
                        </div>
                      </div>
                    </Tab>

                  </Tabs>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      {<Footer />}
    </div>
  );
};
