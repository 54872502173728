import React, { useRef, useEffect } from 'react'
import { Container, Row, Col, Button, Form, Table, Accordion, FloatingLabel } from 'react-bootstrap'
import topimagebg from "../assets/img/topimagebg.png"
import aboutimage from "../assets/img/about-img.jpg"
import zigimg2 from "../assets/img/zig2.png"
import zigimg3 from "../assets/img/zig3.png"
import shape1 from "../assets/img/shape1.png"
import shape2 from "../assets/img/shape2.png"
import shape3 from "../assets/img/shape3.png"
import Header from "../assets/header"
import Footer from "../assets/footer"
import Aos from "aos"
import { Helmet } from "react-helmet"
import { Link } from "react-router-dom"

const getPriceValidation = (price) => {
  var val = true
  if (!parseFloat(price) > 0) {
    val = false
  }
  return val
}

const getOutput = (output,id) => {
  var data = null
  for (let x = 0; x < output.length; x++) {
    if(output[x].catelog_id==id) {
      data = output[x]
    }
  }
  return data
}

export default function Home(props) {
  const textAreaRef = useRef(null);
  useEffect(() => { Aos.init(); }, [])
  useEffect(() => { Aos.refresh(); }, [])

  const resizeTextArea = () => {
    textAreaRef.current.style.height = "auto";
    textAreaRef.current.style.height = textAreaRef.current.scrollHeight + "px";
  };

  useEffect(resizeTextArea, [props.contact_us.msg]);

  return (
    <div>
      <Header />

      <Helmet>
        <meta charSet="utf-8" />
        <title>{props.title}</title>
        <meta name="description" content={props.meta_description} />
        <meta property="og:title" content={props.meta_title} />
        <meta property="og:type" content="article" />
        <meta property="og:url" content={props.url} />
      </Helmet>

      <div className='content-wrap'>
        <section className='introwrap'>
          <Container>
            {props.home.data !== null ?
              <Row>
                <Col md={6} className='h-order1'>
                  <div className='intro-text'>
                    <h1
                      className='intro-text-heading'
                      dangerouslySetInnerHTML={{ __html: props.home.data.slider.title }} />
                    <div class="intropara">
                      <p>{props.home.data.slider.description}</p>
                    </div>
                    <div className='hm-btnwraper'>
                      <Button href="/register " className='btn-get-start'>Get Started</Button>
                      <Button href="/about-us" className="btn-showall btn btn-primary">About us</Button>
                    </div>

                  </div>
                </Col >
                <Col md={6} className='h-order2'>
                  <div className='intro-bg'>
                    <img
                      src={topimagebg}
                      className="d-inline-block img-fluid topbg"
                      alt=""
                    />
                  </div>
                  <div className='inro-image'>
                    <img
                      src={props.home.data.slider.slider_image}
                      className="d-inline-block img-fluid"
                      alt=""
                    />
                  </div>
                </Col>
              </Row> : ""}
          </Container>
        </section>
        <section className='engage-contents-wrap'>
          <Container>
            <Row>
              <Col md={12}>
                {props.home.data !== null ?
                  // <div className='engage-content-box' data-aos="fade-up">
                  <div className='engage-content-box'>
                    <h2>Application Features</h2>
                    <h3>{props.home.data.content.content_title}</h3>
                    {/* <p>{props.home.data.content.content_description}</p> */}
                  </div> : ""}
              </Col>
            </Row>
          </Container>
          <Container className='ecwrap'>
            {props.home.data !== null ? props.home.data.features.map((row) => (
              <Row>
                {row.map((data) => (
                  <Col md={4}>
                    <div className='ec-box'>
                      <img
                        src={data.feature_image}
                        className="d-inline-block img-fluid"
                        alt=""
                      />
                      {/* <h4>{data.feature_test}</h4> */}
                      <h5>{data.feature_title}</h5>
                      <p>{data.feature_description}</p>
                    </div>
                  </Col>))}
              </Row>)) : ""}
          </Container>
        </section>
        {props.home.data !== null ? <section className='zig-block'>
          <Container>
            {console.log()}
            <Row className='zig-box'>
              <Col md={6}>
                <div className='zig-image zi-1'>
                  <div className='shapes-wrap1'>
                    <img
                      src={shape1}
                      className="d-inline-block img-fluid shape1"
                      alt=""
                    />
                    <img
                      src={shape2}
                      className="d-inline-block img-fluid shape2"
                      alt=""
                    />
                  </div>
                  <img
                    src={props.home.data.features_options[0][0].features_image}
                    className="d-inline-block img-fluid"
                    alt=""
                  />
                  <div className='shapes-wrap2'>
                    <img
                      src={shape3}
                      className="d-inline-block img-fluid shape3"
                      alt=""
                    />
                  </div>
                </div>
              </Col>
              <Col md={6}>
                <div className='zig-title'>
                  <h3>{props.home.data.features_options[0][0].features_title}</h3>
                  <p>{props.home.data.features_options[0][0].features_description}</p>

                </div>
              </Col>
            </Row>
            <Row className='zig-box'>
              <Col md={6} className='order1'>
                <div className='zig-title r2'>
                  <h3>{props.home.data.features_options[0][1].features_title}</h3>
                  <p>{props.home.data.features_options[0][1].features_description}</p>
                </div>

              </Col>
              <Col md={6} className='order2'>
                <div className='zig-image zi-2'>
                  <div className='shapes-wrap1'>
                    <img
                      src={shape1}
                      className="d-inline-block img-fluid shape4"
                      alt=""
                    />
                    <img
                      src={props.home.data.features_options[0][1].features_image}
                      className="d-inline-block img-fluid shape5"
                      alt=""
                    />
                  </div>
                  <img
                    src={zigimg2}
                    className="d-inline-block img-fluid"
                    alt=""
                  />
                </div>
              </Col>
            </Row>
            <Row className='zig-box'>
              <Col md={6}>
                <div className='zig-image zi-3'>
                  <div className='shapes-wrap1'>
                    <img
                      src={props.home.data.features_options[0][2].features_image}
                      className="d-inline-block img-fluid shape6"
                      alt=""
                    />
                    <img
                      src={shape2}
                      className="d-inline-block img-fluid shape2"
                      alt=""
                    />
                  </div>
                  <img
                    src={zigimg3}
                    className="d-inline-block img-fluid"
                    alt=""
                  />
                </div>
              </Col>
              <Col md={6}>
                <div className='zig-title'>
                  <h3>{props.home.data.features_options[0][2].features_title}</h3>
                  <p>{props.home.data.features_options[0][2].features_description}</p>

                </div>
              </Col>
            </Row>
          </Container>
        </section> :null }
        
        <div className='plans-wrap'>
          <Container>
            <Row>
              {props.home.data !== null ?
                <Col md={12}>
                  <div className='plan-header'>
                    <h3>Plans & Pricing</h3>
                    <h4>{props.home.data.plans.plans_title}</h4>
                    <h5>{props.home.data.plans.plans_description}</h5>
                  </div>
                </Col> : ""}
            </Row>
            <Row>
              <Col md={12} className="toggle-button text-center">
                <div class="btn-group switcher" role="group" aria-label="Basic example">
                  <button onClick={() => props.handleMode("month")} type="button" class={"btn btn-primary draft" + (props.pack_show == "month" ? " active" : "")}>Monthly</button>
                  <button onClick={() => props.handleMode("year")} type="button" class={"btn btn-primary live" + (props.pack_show == "year" ? " active" : "")}>Yearly</button>
                </div>
              </Col>
            </Row>

            {props.packDetails.length > 0 ?
              <section className='plan-box-wrap'>
                <Container>
                  <Row>
                    {props.packDetails.map((pack, index) => (
                      <Col md={4} onClick={() => props.planSelect(index)}>
                        {pack.contact_us ?
                          <div className="planbox">
                            <h3>{pack.package_name}</h3>
                            <h4>Need More? Get in touch with us to build a custom plan</h4>
                            <Link to="/contact-us"> <Button className='btntry'>  Contact Us </Button> </Link>
                          </div> :
                          <div className={"planbox" + (index == 1 ? " planbox-active" : "")}>
                            {index == 1 ? <h6 className="popular">Popular</h6> : null}
                            <h3>{pack.package_name}</h3>
                            <h4>Perfect for small teams</h4>
                            {getPriceValidation(pack.package_price) ?
                              <div className='p-pricewrap'>
                                <span>$</span>
                                <h5>{pack.package_price}</h5>
                                <h6>per {props.pack_show === "year" ? "year" : "month"}</h6>
                              </div> : <h2 className='free-pricewrap'>Free</h2>}
                            <Button className='btntry' onClick={() => props.handleCheckout(pack, props.home.data.packages)}>
                              {getPriceValidation(pack.package_price) ? "Buy" : "Subscribe"}
                            </Button>
                            <div className='plan-list'>
                              <ul>
                                <li>{pack.package_name == "Grow" ? "Up to 3 users" : "1 user"}</li>
                                <li>{pack.cat_allowed} Categories</li>
                                <li>{pack.product_count} Products/ mo</li>
                                {getPriceValidation(pack.package_price) ? <li>15 day free trail</li> :<br/>}
                              </ul>
                            </div>
                          </div>
                        }

                      </Col>
                    ))}
                  </Row>
                </Container>
              </section> : ""}

            {props.packDetails.length && props.home.data.output.length > 0 ?
              <section className='plan-tbl'>
                <Container>
                  <Row>
                    <Col md={12}>
                      <div className='our-plan-title'>
                        <h4>Compare Plans</h4>
                        <div className='plan-tick'>
                          <Form>
                            <FloatingLabel>Pay monthly</FloatingLabel>
                            <Form.Check // prettier-ignore
                              type="switch"
                              checked={props.pack_show === "year"}
                              onClick={props.handleComparePlane}
                              id="custom-switch"
                            />
                            <FloatingLabel>Pay annually</FloatingLabel>
                          </Form>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12}>
                      <div className='tbl-compare-plan'>
                        <Table striped responsive>
                          <thead className='pricing-head'>
                            <tr>
                              <th></th>
                              {props.packDetails.map((pack, pIndx) => (
                                pack.contact_us ? null :
                                  <th>
                                    {pIndx == 1 ? <h6 className="popular">Popular</h6> : null}
                                    <p>{pack.package_name}</p>
                                  </th>
                              ))}
                            </tr>
                          </thead>
                          <tbody>
                            <tr className='pricerow'>
                              <td className='pricing-head'><h6>PRICING</h6></td>
                              {props.packDetails.map((pack) => (
                                pack.contact_us ? null :
                                  <td className='pricing-head'><h4>$ {pack.package_price}/{props.pack_show === "year" ? "yr" : "mo"}</h4></td>
                              ))}
                            </tr>
                            <tr className='gray'>
                              <td><h6>Users</h6></td>
                              <td></td>
                              <td></td>
                            </tr>
                            <tr>
                              <td><h5>Admin Owner</h5></td>
                              {props.packDetails.map((pack) => (
                                pack.contact_us || getOutput(props.home.data.output,pack.catelog_id)==null ? null :
                                  <td>{getOutput(props.home.data.output,pack.catelog_id).admin_owner}</td>
                              ))}
                            </tr>
                            <tr>
                              <td><h5>Contributors</h5></td>
                              {props.packDetails.map((pack) => (
                                pack.contact_us || getOutput(props.home.data.output,pack.catelog_id)==null ? null :
                                  <td>{getOutput(props.home.data.output,pack.catelog_id).contributors}</td>
                              ))}
                            </tr>
                            <tr className='gray'>
                              <td><h6>Catalog</h6></td>
                              <td></td>
                              <td></td>
                            </tr>
                            <tr>
                              <td><h5>Number of Categories</h5></td>
                              {props.packDetails.map((pack) => (
                                pack.contact_us ? null :
                                  <td>{pack.cat_allowed}</td>
                              ))}
                            </tr>
                            <tr>
                              <td><h5>Number of Products</h5></td>
                              {props.packDetails.map((pack) => (
                                pack.contact_us ? null :
                                  <td>{pack.product_count}</td>
                              ))}
                            </tr>
                            <tr>
                              <td><h5>Number of Attributes</h5></td>
                              {props.packDetails.map((pack) => (
                                pack.contact_us ? null :
                                  <td>{pack.attribute}</td>
                              ))}
                            </tr>
                            <tr>
                              <td><h5>Product Type  </h5></td>
                              {props.packDetails.map((pack) => (
                                pack.contact_us || getOutput(props.home.data.output,pack.catelog_id)==null ? null :
                                  <td>{getOutput(props.home.data.output,pack.catelog_id).product_type}</td>
                              ))}
                            </tr>
                            <tr className='gray'>
                              <td><h6>Output Elements</h6></td>
                              <td></td>
                              <td></td>
                            </tr>
                            <tr>
                              <td><h5>Product TItle</h5></td>
                              {props.packDetails.map((pack) => (
                                pack.contact_us || getOutput(props.home.data.output,pack.catelog_id)==null ? null :
                                  <td>{getOutput(props.home.data.output,pack.catelog_id).product_titles}</td>
                              ))}
                            </tr>
                            <tr>
                              <td><h5>Product Description Introduction</h5></td>
                              {props.packDetails.map((pack) => (
                                pack.contact_us || getOutput(props.home.data.output,pack.catelog_id)==null ? null :
                                  <td>{getOutput(props.home.data.output,pack.catelog_id).introduction}</td>
                              ))}
                            </tr>
                            <tr>
                              <td><h5>Number of Feature Bullets</h5></td>
                              {props.packDetails.map((pack) => (
                                pack.contact_us || getOutput(props.home.data.output,pack.catelog_id)==null ? null :
                                  <td>{getOutput(props.home.data.output,pack.catelog_id).feature_bullets}</td>
                              ))}
                            </tr>
                            <tr>
                              <td><h5>Featured Bullets </h5></td>
                              {props.packDetails.map((pack) => (
                                pack.contact_us || getOutput(props.home.data.output,pack.catelog_id)==null ? null :
                                  <td>{getOutput(props.home.data.output,pack.catelog_id).feature_bullets}</td>
                              ))}
                            </tr>
                            <tr>
                              <td><h5>Detailed Descriptions</h5></td>
                              {props.packDetails.map((pack) => (
                                pack.contact_us || getOutput(props.home.data.output,pack.catelog_id)==null ? null :
                                  <td>{getOutput(props.home.data.output,pack.catelog_id).detailed_descriptions}</td>
                              ))}
                            </tr>

                            <tr>
                              <td><h5>Closing Statement</h5></td>
                              {props.packDetails.map((pack) => (
                                pack.contact_us || getOutput(props.home.data.output,pack.catelog_id)==null ? null :
                                  <td>{getOutput(props.home.data.output,pack.catelog_id).closing_statement}</td>
                              ))}
                            </tr>
                            <tr className='gray'>
                              <td><h6>Bulk Operation</h6></td>
                              <td></td>
                              <td></td>
                            </tr>
                            <tr>
                              <td><h5>Import</h5></td>
                              {props.packDetails.map((pack) => (
                                pack.contact_us || getOutput(props.home.data.output,pack.catelog_id)==null ? null :
                                  <td>{getOutput(props.home.data.output,pack.catelog_id).import}</td>
                              ))}
                            </tr>
                            <tr>
                              <td><h5>Export</h5></td>
                              {props.packDetails.map((pack) => (
                                pack.contact_us || getOutput(props.home.data.output,pack.catelog_id)==null ? null :
                                  <td>{getOutput(props.home.data.output,pack.catelog_id).export}</td>
                              ))}
                            </tr>
                            <tr>
                              <td><h5>Maximum Downloads Storage</h5></td>
                              {props.packDetails.map((pack) => (pack.contact_us ? null :
                               <td>{pack.storage}</td>
                              ))}
                            </tr>
                          </tbody>
                        </Table>
                      </div>
                    </Col>
                  </Row>
                </Container>
              </section> : ""}
          </Container>
        </div>
        <section className='hm-faq'>
          <Container>
            <Row>
              <Col md={5}>
                <div className='faq-col1'>
                  <h4>FAQs</h4>
                  <h5>Have questions?</h5>
                  <h6>We have answers!</h6>
                  <p>If you don’t find what you are looking for you can always reach out to us.</p>
                  <Button href="/faq" className='btn-showall'>Show All FAQs</Button>

                </div>
              </Col>
              <Col md={7}>
                <div className='faq-col2'>
                  {props.home.data !== null ?
                    <Accordion defaultActiveKey={0}>
                      {props.home.data.faq.overview.map((faq, index) => (
                        <Accordion.Item onClick={() => props.handleFAQ(index)} eventKey={index} className={props.faq == index ? 'active' : null}>
                          <Accordion.Header>Q. {faq.question}</Accordion.Header>
                          <Accordion.Body>{faq.answer}</Accordion.Body>
                        </Accordion.Item>
                      ))}
                    </Accordion> : ""}
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        <section className='hm-about'>
          <Container>
            <Row>
              <Col md={7}>
                <div className='hmabout-image'>
                  <img
                    src={aboutimage}
                    className="d-inline-block img-fluid"
                    alt=""
                  />
                </div>
              </Col>
              <Col md={5}>
                {props.home.data !== null ?
                  <div className='hmabout-content'>
                    <h4>ABOUT US</h4>
                    <h5>{props.home.data.about.about_us_title}</h5>
                    <p>{props.home.data.about.about_us_description}</p>
                    <Button href="/about-us" className='btn-showall'>Our Team</Button>
                  </div> : ""}
              </Col>
            </Row>
          </Container>
        </section>
        <section className='hm-contact-wrap'>

          <Container>
            <Row>
              <Col lg={6} md={12}>
                <div className='contact-col1'>
                  <h3>Contact US</h3>
                  {props.home.data !== null ?
                    <h4>{props.home.data.contact_us[0]}</h4> : ""}
                </div>
              </Col>
              <Col lg={6} md={12}>
                <div className='contact-col2'>
                  <h5>How can we help you ?</h5>
                  <Form>
                    <Form.Group className="mb-2" controlId="">
                      <FloatingLabel
                        controlId="floatingTextarea"
                        label="Your Name"
                        className={"mb-3 " + (props.error.name !== null ? "error-field" : null)}
                      >
                        <Form.Control type="text" placeholder="" name="name" value={props.contact_us.name} onChange={props.handleContactDetails} />
                        {props.error.name !== null ? <div className="validate"> <span> * {props.error.name} </span> </div> : ""}
                      </FloatingLabel>
                    </Form.Group>
                    <Form.Group className="mb-2" controlId="">
                      <FloatingLabel
                        controlId="floatingTextarea"
                        label="Business Name"
                        className={"mb-3 " + (props.error.business !== null ? "error-field" : null)}
                      >
                        <Form.Control type="text" placeholder="" name="business" value={props.contact_us.business} onChange={props.handleContactDetails} />
                        {props.error.business !== null ? <div className="validate"> <span> * {props.error.business} </span> </div> : ""}
                      </FloatingLabel>
                    </Form.Group>
                    <Form.Group className="mb-2" controlId="">
                      <FloatingLabel
                        controlId="floatingTextarea"
                        label="Email"
                        className={"mb-3 " + (props.error.email !== null ? "error-field" : null)}
                      >
                        <Form.Control type="email" placeholder="" name="email" value={props.contact_us.email} onChange={props.handleContactDetails} />
                        {props.error.email !== null ? <div className="validate"> <span> * {props.error.email} </span> </div> : ""}
                      </FloatingLabel>
                    </Form.Group>
                    <Form.Group className="mb-2" controlId="">
                      <FloatingLabel
                        controlId="floatingTextarea"
                        label="Message"
                        className={"mb-3 " + (props.error.msg !== null ? "error-field" : null)}
                      >
                        <Form.Control className='text-area' as="textarea" ref={textAreaRef} rows={2} placeholder="" name="msg" value={props.contact_us.msg} onChange={props.handleContactDetails} />
                        {props.contact_us.msg.length > 0 ? <p className='text-area-count'>{props.contact_us.msg.length}</p> : null}
                        {props.error.msg !== null ? <div className="validate"> <span> * {props.error.msg} </span> </div> : ""}
                      </FloatingLabel>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="">
                      <Button className='csubmit' onClick={props.contactButonClick}>Send</Button>
                    </Form.Group>
                  </Form>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </div>
      {<Footer />}
    </div>
  );
};
