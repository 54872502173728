import { API_URL } from "../Constants"
import { startLoading, stopLoading, SnackBar } from "./common"
import store from "../store"

export function stopPackageStep() {
    return { type: 'STOP_PACKAGE_STEP' };
}
export function storePackages(data) {
    return { type: 'STORE_PACKEGES', data };
}
export function getPackages() {
    return function (dispatch) {
        dispatch(startLoading())
        dispatch(SnackBar(true, 'Loading....', 'info'))
        fetch(API_URL + "general-settings", {
            method: "GET",
            headers: {
                'Content-Type': 'application/json;charset=utf-8',
                'Authorization': 'Bearer ' + store.getState().login.auth
            }
        }).then(response => response.json())
            .then((json) => {
                
                dispatch(storePackages(json.data))  
                // if(json.status) {
                //     dispatch(storePackages(json))  
                // }
                dispatch(stopLoading())
            })
            .catch((error) => {
                dispatch(SnackBar(true, "Connection error", 'error'))
            })
    }
}

export function packageStatusUpdate(data) {
    return { type: 'PACKAGE_STATUS_UPDATE', data };
}
export function updatePackageStatus(data) {
    return function (dispatch) {
        dispatch(startLoading())
        dispatch(packageStatusUpdate(data))
        dispatch(startLoading())
        fetch(API_URL + "update-status-settings", {
            method: "POST",
            headers: {
                "Content-Type": "application/json;charset=utf-8",
                'Authorization': 'Bearer ' + store.getState().login.auth
            },
            body: JSON.stringify({"id": data.id, "status": data.status})
        }).then(response => response.json())
            .then(() => {
                dispatch(getPackages())
                dispatch(stopLoading())
            })
            .catch((error) => {
                dispatch(SnackBar(true, "Connection error dashboard", 'error'))
            })
    }
}
export function deletePackage(id) {
    return function (dispatch) {
        dispatch(startLoading())
        dispatch(SnackBar(true, 'Loading....', 'info'))
        fetch(API_URL + "delete-packages", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json;charset=utf-8',
                'Authorization': 'Bearer ' + store.getState().login.auth
            },
            body: JSON.stringify({ "id": id })
        }).then(response => response.json())
            .then((json) => {
                dispatch(getPackages())
                dispatch(stopLoading())
            })
            .catch((error) => {
                dispatch(SnackBar(true, "Connection error", 'error'))
            })
    }
}

export function storeEditPackages(data) {
    return { type: 'STORE_EDIT_PACKEGES', data };
}
export function editpackage(id) {
    return function (dispatch) {
        dispatch(startLoading())
        dispatch(SnackBar(true, 'Loading....', 'info'))
        fetch(API_URL + "current-package?id=" + id , {
            method: "GET",
            headers: {
                'Content-Type': 'application/json;charset=utf-8',
                'Authorization': 'Bearer ' + store.getState().login.auth
            }
        }).then(response => response.json())
            .then((json) => {
                dispatch(storeEditPackages(json))
                dispatch(stopLoading())
            })
            .catch((error) => {
                dispatch(SnackBar(true, "Connection error", 'error'))
            })
    }
}
export function stopUpdateDashboardPackage() {
    return { type: 'STOP_UPDATE_DASHBOARD_PACKAGE' };
}
export function handleCreate(data) {
    return function (dispatch) {
        dispatch(startLoading())
        dispatch(SnackBar(true, 'Loading....', 'info'))
        fetch(API_URL + "create-general-settings", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json;charset=utf-8',
                'Authorization': 'Bearer ' + store.getState().login.auth
            },
            body: JSON.stringify(data)
        }).then(response => response.json())
            .then((json) => {
                dispatch(getPackages())
                dispatch(stopLoading())
            })
            .catch((error) => {
                dispatch(SnackBar(true, "Connection error", 'error'))
            })
    }
}
export function handleUpdate(data) {
    return function (dispatch) {
        dispatch(startLoading())
        dispatch(SnackBar(true, 'Loading....', 'info'))
        fetch(API_URL + "update-general-settings", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json;charset=utf-8',
                'Authorization': 'Bearer ' + store.getState().login.auth
            },
            body: JSON.stringify(data)
        }).then(response => response.json())
            .then((json) => {
                dispatch(getPackages())
                dispatch(stopLoading())
            })
            .catch((error) => {
                dispatch(SnackBar(true, "Connection error", 'error'))
            })
    }
}

export function handleUpdateStatus(data) {
    return function (dispatch) {
        dispatch(startLoading())
        dispatch(SnackBar(true, 'Loading....', 'info'))
        fetch(API_URL + "updategeneral-settings", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json;charset=utf-8',
                'Authorization': 'Bearer ' + store.getState().login.auth
            },
            body: JSON.stringify(data)
        }).then(response => response.json())
            .then((json) => {
                dispatch(getPackages())
                dispatch(stopLoading())
            })
            .catch((error) => {
                dispatch(SnackBar(true, "Connection error", 'error'))
            })
    }
}