import React, { PureComponent } from "react"
import { compose } from "redux"
import { connect } from "react-redux"
import { Nav, Navbar, NavDropdown, Container } from 'react-bootstrap'
import { logout } from "../hooks/login"
import { headerLogo, infoIcon } from "./img/images"
import Page from "./page"

const mapStateToProps = (state) => ({
  login: state.login
})

const mapDispatchToProps = dispatch => ({
  logout: () => dispatch(logout())
})

class Header extends PureComponent {

  constructor(props) {
    super(props)

    this.state = {

    }
  }

  render() {

    return (<Navbar expand="lg" className="">
      <Container>
        <div className="navwrap">
          <Navbar.Brand href="/">
            <img
              src={headerLogo}
              className="d-inline-block align-top"
              alt=""
            />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="">
              <Nav.Link href="/"> Home</Nav.Link>
              <Nav.Link href="/about-us"> About </Nav.Link>
              <Nav.Link href="/dashboard/subscription" className={(this.props.page == "dashboard" ? "page-name" : null)}> Subscription</Nav.Link>
              <Nav.Link href="/dashboard/prompt/step1" className={(this.props.page == "dashboard" ? "page-name" : null)}> Prompt Engineering</Nav.Link>
              <Nav.Link href="/dashboard/packages" className={(this.props.page == "dashboard" ? "page-name" : null)}> Packages</Nav.Link>
              <Nav.Link href="/dashboard/settings" className={(this.props.page == "faq" ? "page-name" : null)}>Settings</Nav.Link>
              <Nav.Link href="/faq" className={(this.props.page == "faq" ? "page-name" : null)}> FAQ </Nav.Link>
              <Nav.Link href="https://eprodx.ai/blog/">Blog</Nav.Link>
            </Nav>
          </Navbar.Collapse>
          <div class="profile-submenu">
            <NavDropdown title={this.props.login.user !== undefined && this.props.login.user !== null ? this.props.login.user.name : null} id="basic-nav-dropdown">
              <NavDropdown.Item  href="/dashboard">My projects</NavDropdown.Item>
              <NavDropdown.Item className="ps" href="/dashboardprofile">Profile Settings</NavDropdown.Item>
              <NavDropdown.Item className="si" href="/dashboard/profile/subscription">Subscription Information</NavDropdown.Item>
              <NavDropdown.Item className="um">User Management</NavDropdown.Item>
              <NavDropdown.Item onClick={this.props.logout}> Sign Off </NavDropdown.Item>
            </NavDropdown>
          </div>
        </div>
      </Container>
      <Page />
      <div class="mobile-note">
        <img src={infoIcon} />
        <p>For a better expereience use the desktop version to manage more than 10 SKUs</p>
      </div>
    </Navbar>)
  }
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(Header)