import React, { Fragment } from 'react'

export default function category(props) {
    return (
        <Fragment>
            {props.data.id == props.edit_id ?
                <input type="text" className="form-control addval" id="exampleInputEmail1"
                    name="edit_value" value={props.edit_value} onChange={props.handleChange} /> :
                <input type="text" className="form-control addval" id="exampleInputEmail1"
                    name="edit_value" value={props.data.title} disabled />
            }
        </Fragment>
    );
};
