import React, { PureComponent, Fragment } from "react"
import PropTypes from "prop-types"
import { compose } from "redux"
import { connect } from "react-redux"
import DefaultView from "../pages/default"
import "./style.css"

const mapStateToProps = (state) => ({
    
})

const mapDispatchToProps = dispatch => ({
    
})

class Default extends PureComponent {

    constructor(props) {
        super(props)

        this.state = {
           
        }
    }
   

    render() {
        return (
        <Fragment>
            <DefaultView
                {...this.props}
                {...this.state}       
            />
          
        </Fragment>)
    }
}

Default.propTypes = {
    classes: PropTypes.object.isRequired
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(Default)
