import { API_URL } from "../Constants"
import { startLoading, stopLoading, SnackBar } from "./common"
import store from "../store"

export function storeDashboardUsers(data) {
    return { type: 'STORE_DASHBOARD_USERS', data };
}
export function getUsers() {
    return function (dispatch) {
        dispatch(startLoading())
        dispatch(SnackBar(true, 'Loading....', 'info'))
        fetch(API_URL + "all-user", {
            method: "GET",
            headers: {
                'Content-Type': 'application/json;charset=utf-8',
                'Authorization': 'Bearer ' + store.getState().login.auth
            }
        }).then(response => response.json())
            .then((json) => {
                if(json.status) {
                    dispatch(storeDashboardUsers(json.users))
                }
                dispatch(stopLoading())
            })
            .catch((error) => {
                dispatch(SnackBar(true, "Connection error", 'error'))
            })
    }
}
export function addNewUser(data) {
    return function (dispatch) {
        dispatch(startLoading())
        dispatch(SnackBar(true,'Creating User....','info'))
        fetch(API_URL + "user-create", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json;charset=utf-8',
                'Authorization': 'Bearer ' + store.getState().login.auth
            },
            body: JSON.stringify(data)
        }).then(response => response.json())
            .then((json) => {
                dispatch(getUsers())
                dispatch(stopLoading())
            })
            .catch((error) => {
                // dispatch(SnackBar(true, error.response.data.errors.map((data) => (<p>{data}</p>)), 'error'))
            })
    }
}

export function settingUpdateAPI(bool) {
    return { type: 'API_SETTINGS_UPDATE', bool };
}
export function settingAPIUpdate(data) {
    return { type: 'SETTINGS_API_UPDATE', data };
}
export function getAPIKeys() {
    return function (dispatch) {
        dispatch(startLoading())
        dispatch(SnackBar(true, 'Loading....', 'info'))
        fetch(API_URL + "get-api", {
            method: "GET",
            headers: {
                'Content-Type': 'application/json;charset=utf-8',
                'Authorization': 'Bearer ' + store.getState().login.auth
            }
        }).then(response => response.json())
            .then((json) => {
                if(json.status) {
                    dispatch(settingAPIUpdate(json.api[0]))
                    dispatch(settingUpdateAPI(true))
                }
                dispatch(stopLoading())
            })
            .catch((error) => {
                dispatch(SnackBar(true, "Connection error", 'error'))
            })
    }
}

export function updateAPIKey(data) {
    return function (dispatch) {
        dispatch(startLoading())
        dispatch(SnackBar(true,'Loading....','info'))
        fetch(API_URL + "update-api", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json;charset=utf-8',
                'Authorization': 'Bearer ' + store.getState().login.auth
            },
            body: JSON.stringify(data)
        }).then(response => response.json())
            .then(() => {
                dispatch(getAPIKeys())
                dispatch(stopLoading())
            })
            .catch((error) => {
                // dispatch(SnackBar(true, error.response.data.errors.map((data) => (<p>{data}</p>)), 'error'))
            })
    }
}
export function stopUserUpdateLocal() {
    return { type: 'STOP_USER_UPDATE_LOCAL' };
}
export function storeEditUserDetails(data) {
    return { type: 'STORE_EDIT_USER_DETAILS', data };
}
export function handleEditUser(id) {
    return function (dispatch) {
        dispatch(startLoading())
        dispatch(SnackBar(true, 'Loading....', 'info'))
        fetch(API_URL + "get-user?id=" + id, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json;charset=utf-8',
                'Authorization': 'Bearer ' + store.getState().login.auth
            }
        }).then(response => response.json())
            .then((json) => {
                if(json.status) {
                    dispatch(storeEditUserDetails(json.user))
                }
                dispatch(stopLoading())
            })
            .catch((error) => {
                dispatch(SnackBar(true, "Connection error", 'error'))
            })
    }
}


export function updateUser(data) {
    return function (dispatch) {
        dispatch(startLoading())
        dispatch(SnackBar(true,'Updating User....','info'))
        fetch(API_URL + "edit-user?id=" + data.id, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json;charset=utf-8',
                'Authorization': 'Bearer ' + store.getState().login.auth
            },
            body: JSON.stringify(data)
        }).then(response => response.json())
            .then((json) => {
                dispatch(getUsers())
                dispatch(stopLoading())
            })
            .catch((error) => {
                // dispatch(SnackBar(true, error.response.data.errors.map((data) => (<p>{data}</p>)), 'error'))
            })
    }
}
export function stopUpdateLocalStateUpdate() {
    return { type: 'STOP_UPDATE_LOCAL_STATUS' };
}