import React, { PureComponent, Fragment } from "react"
import { Navigate } from 'react-router-dom'
import PropTypes from "prop-types"
import { compose } from "redux"
import { connect } from "react-redux"
import Dashboard_settingsView from "../pages/dashboard-settings"
import {getUsers, addNewUser, settingUpdateAPI, getAPIKeys, updateAPIKey, handleEditUser,
   stopUserUpdateLocal, updateUser, stopUpdateLocalStateUpdate} from "../hooks/settings"
import "./style.css"

const mapStateToProps = (state) => ({
    profile: state.login.user,
    settings: state.settings,
    login: state.login
})

const mapDispatchToProps = dispatch => ({
    getUsers: () => dispatch(getUsers()),
    getAPIKeys: () => dispatch(getAPIKeys()),
    settingUpdateAPI: (bool) => dispatch(settingUpdateAPI(bool)),
    addNewUser: (data) => dispatch(addNewUser(data)),
    updateAPIKey: (data) => dispatch(updateAPIKey(data)),
    handleEditUser: (id) => dispatch(handleEditUser(id)),
    stopUserUpdateLocal: () => dispatch(stopUserUpdateLocal()),
    updateUser: (data) => dispatch(updateUser(data)),
    stopUpdateLocalStateUpdate: ()=> dispatch(stopUpdateLocalStateUpdate())
})


const getValidation = (state) => {
    var first_name = null
    var email = null
    var password = null
    var validate = true

    if (state.first_name == "") {
        first_name = "Required"
        validate = false
    }
    if (state.email == "") {
        email = "Required"
        validate = false
    }
    if (state.password == "" && state.id == 0) {
        password = "Required"
        validate = false
    }

    return { data: { first_name: first_name, email: email,  password: password }, validate: validate }
}

class Dashboard_settings extends PureComponent {

    constructor(props) {
        super(props)

        this.state = {
            activeKey:"allusers",
            id:0,
            name:"",
            first_name:"",
            last_name:"",
            email:"",
            role:"",
            password:"",
            notification: 0,
            error: {
                first_name: null,
                email: null,
                password: null
            },
            bearer_token:"",
            base_url:""
        }
        this.props.getUsers()
        this.props.getAPIKeys()
    }
    componentDidMount=()=> {
        if(this.props.settings.api_update) {
            this.props.settingUpdateAPI(false)
            this.setState({
                bearer_token:this.props.settings.api.token,
                base_url:this.props.settings.api.url
            })
        }
    }
    componentDidUpdate=()=> {
        if(this.props.settings.api_update) {
            this.props.settingUpdateAPI(false)
            this.setState({
                bearer_token:this.props.settings.api.token,
                base_url:this.props.settings.api.url
            })
        }
        if(this.props.settings.edite_user_update) {
            this.props.stopUserUpdateLocal()
            this.setState({
                activeKey: "addnew",
                id: this.props.settings.edit_user_details.id,
                name: this.props.settings.edit_user_details.name,
                first_name: this.props.settings.edit_user_details.first_name,
                last_name: this.props.settings.edit_user_details.last_name,
                email: this.props.settings.edit_user_details.email,
                role: this.props.settings.edit_user_details.is_admin,
                notification:  this.props.settings.edit_user_details.notification
            })
        }
        
        if (this.props.settings.localUseState) {
            this.props.stopUpdateLocalStateUpdate()
            this.setState({
                activeKey:"allusers",
                id:0,
                name:"",
                first_name:"",
                last_name:"",
                email:"",
                role:"",
                password:"",
                notification: 0,
            })
        }
    }
    handleChange = (e) => {
        var name = e.target.name
        if (name == "notification") {
            this.setState({
                [name]: e.target.checked ? 1 : 0
            })
            return false
        }
        if (name == "first_name" || name == "email" || name == "password") {
            this.setState({
                error: {
                    ...this.state.error,
                    [name]: null
                }
            })
        }
        this.setState({
            [name]: e.target.value
        })
    }
    addNewUser = () => {
        if (getValidation(this.state).validate && this.state.id === 0) {
            this.props.addNewUser({
                id: this.state.id,
                name: this.state.name,
                first_name: this.state.first_name,
                last_name: this.state.last_name,
                email: this.state.email,
                is_admin: this.state.role,
                password: this.state.password,
                notification: this.state.notification
            })
        } else if (getValidation(this.state).validate && this.state.id != 0) {
            this.props.updateUser({
                id: this.state.id,
                name: this.state.name,
                first_name: this.state.first_name,
                last_name: this.state.last_name,
                email: this.state.email,
                is_admin: this.state.role,
                [this.state.password !== "" ? "password" : ""]: this.state.password !== "" ? this.state.password : "",
                notification: this.state.notification
            })
        }
        else {
            this.setState({ error: getValidation(this.state).data })
        }
    }
    handleActiveKey=()=> {
        this.setState({
            activeKey: this.state.activeKey === "allusers" ? "addnew" : "allusers"
        })
    }
    updateAPIKey = () => {
        if (this.state.bearer_token !== "" && this.state.url !== "") {
            this.props.updateAPIKey({
                id: 1,
                token: this.state.bearer_token,
                url: this.state.base_url
            })
        }
    }

    handleEditUser=(id)=>{
        this.setState({
            id:0,
            first_name:"",
            last_name:"",
            email:"",
            role:"",
            password:"",
            notification: 0
        },()=>this.props.handleEditUser(id))
    }
   
    render() {
        return (
        <Fragment>
        {this.props.login.auth !== null ?
            <Dashboard_settingsView
                {...this.props}
                {...this.state}
                handleChange={this.handleChange}
                addNewUser={this.addNewUser}
                handleActiveKey={this.handleActiveKey} 
                updateAPIKey={this.updateAPIKey}
                handleEditUser={this.handleEditUser}
            /> :
            <Navigate to="/login" replace={true} />}
        </Fragment>)
    }
}

Dashboard_settings.propTypes = {
    classes: PropTypes.object.isRequired
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(Dashboard_settings)
