import React, { PureComponent, Fragment } from "react"
import PropTypes from "prop-types"
import { compose } from "redux"
import { connect } from "react-redux"
import Terms from "../pages/terms"
import { termsCms } from "../hooks/common"
import "./style.css"

const mapStateToProps = (state) => ({
    common: state.common
})

const mapDispatchToProps = dispatch => ({
    termsCms: () => dispatch(termsCms()),
})

class terms extends PureComponent {

    constructor(props) {
        super(props)

        this.state = {
            title:"Terms of Service - eProdx AI-Powered Content Services",
            meta_title: "Terms of Service - eProdx AI-Powered Content Services",
            meta_description: "Read the terms and conditions of eProdx AI-powered content services. Understand our guidelines and policies for using our platform and services.",
            url: "https://eprodx.ai/terms-of-service"
        }
        this.props.termsCms()
    }  

    render() {
        return (<Fragment>
            <Terms
                {...this.props}
                {...this.state}       
            />
          
        </Fragment>)
    }
}

terms.propTypes = {
    classes: PropTypes.object.isRequired
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(terms)
