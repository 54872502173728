import {useEffect} from "react"
import { Nav, Navbar, Container } from 'react-bootstrap'
import footerlogo from "./img/f-logo.png"

function Footer() {
  

  useEffect(() => {
    window.addEventListener('scroll', function() {
      
      var header = document.getElementById('main-navbar')

      if (header != null) {
        if (window.scrollY > 30) {
            header.classList.add('scrolled');
        } else {
            header.classList.remove('scrolled');
        }
      }
  });
  }, []);

  

  return (
    <div className="footer">
      <Navbar expand="lg" className="bg-body-tertiary">
        <Container>
          <Navbar.Brand className="footer-img" href="/">
            <img
              src={footerlogo}
              className="d-inline-block align-top"
              alt="" />
          </Navbar.Brand>
          <Nav className="">
            <Nav.Link href="/privacy-policy" >Privacy</Nav.Link>
            <Nav.Link href="/terms-of-service" >Terms</Nav.Link>
            <Nav.Link href="/contact-us" >Contact</Nav.Link>
          </Nav>
          <p className="copyright">{(new Date()).getFullYear()}</p>
        </Container>
      </Navbar>
    </div>

  );
}

export default Footer;

