import React from "react"
import {eProdx} from "../img/images"


function HomeLoader() {

    return (
        <div class="load-static">
            <img src={eProdx} />
        </div>
    );
}

export default HomeLoader
