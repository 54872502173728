import React from 'react'
import { Container, Row, Col, Button, Breadcrumb, Table } from 'react-bootstrap'
import Moment from 'moment'
import Header from "../assets/header-admin"
import Footer from "../assets/footer"

export default function Home(props) {

  return (
    <div>
      <Header />
      <div className='content-wrap- dash'>
        <Container>
          <Row>
            <Col md={12}>
              <div className='dash-content reports'>
                <div className='breadscrumb-wrap'>
                  <Breadcrumb>
                    <Breadcrumb.Item>Dashboard</Breadcrumb.Item>
                    <Breadcrumb.Item active>Prompt Engineering</Breadcrumb.Item>
                  </Breadcrumb>
                </div>
                <h2>Back Office</h2>
                <div className='quick-info-bar'>
                  <span className='mailcheck'>{props.profile.email}</span>
                  <span>ID: {props.profile.id}</span>
                  <span>Registration Date: {Moment(props.profile.created_at).format('MMMM DD. YYYY')} </span>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <div className='prompt-engineering-wrap'>
                <div className='pro-eng-header'>
                  <div className='pro-eng-steps'>
                    {/* <div className='pro-eng-stepwrap active'>
                      <h3>1</h3>
                      <h4>Input</h4>
                    </div>
                    <div className='pro-eng-stepwrap'>
                      <h3>2</h3>
                      <h4>Output</h4>
                    </div> */}
                  </div>
                  <div className='status-wrap'>
                    <div class="btn-group switcher" role="group" aria-label="Basic example">
                      <button onClick={props.handleMode} name="test" type="button" class={"btn btn-primary draft" + (props.type == "test" ? " active" : "")}>Test</button>
                      <button onClick={props.handleMode} name="live" type="button" class={"btn btn-primary live" + (props.type == "live" ? " active" : "")}>Live</button>
                    </div>
                  </div>
                </div>
                <div className='pro-eng-tblwrap'>
                  <Table hover>
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Input</th>
                        <th>Edit</th>
                        <th>Available Variables</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>AI Make</td>
                        <td>{props.ai_make}</td>
                        <td></td>
                        <td> </td>
                      </tr>
                      <tr>
                        <td>Model</td>
                        <td>
                          {props.editVariable === "model" ?
                            <select className="form-select" value={props.value} onChange={props.handleChange} >
                              {props.prompt.model.map((model) => (
                                <option value={model.id}>{model.id}</option>))}
                            </select> : <> {props.model}</>}
                        </td>
                        <td>
                          <Button className={props.editVariable === "model" ? 'btn-save' : "btn-edit"} name="model" onClick={props.handleEdit}></Button>
                          {props.editVariable === "model" ?
                            <button className="btn-close" onClick={props.handleClose}></button> : null}
                        </td>
                        <td></td>
                      </tr>
                      <tr>
                        <td>Title prompt Input</td>
                        <td>
                          {props.editVariable === "input_prompt_title" ?
                            <textarea className="prompt-engneering-input" rows="2" name="input_prompt_title" value={props.value} onChange={props.handleChange} />
                            : <> {props.input_prompt_title}</>}
                        </td>
                        <td>
                          <Button className={props.editVariable === "input_prompt_title" ? 'btn-save' : "btn-edit"} name="input_prompt_title" onClick={props.handleEdit}></Button>
                          {props.editVariable === "input_prompt_title" ?
                            <Button className="btn-close" onClick={props.handleClose}></Button> : null}
                        </td>
                        <td class="text2">
                          <p>
                            <strong>User Input Attributes</strong><br></br>
                            [name]<br/>
                            [category]<br/>
                            [attributes]
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td>Title prompt output</td>
                        <td>
                          {props.editVariable === "output_prompt_title" ?
                            <textarea className="prompt-engneering-input" rows="2" name="output_prompt_title" value={props.value} onChange={props.handleChange} />
                            : <> {props.output_prompt_title}</>}
                        </td>
                        <td>
                          <Button className={props.editVariable === "output_prompt_title" ? 'btn-save' : "btn-edit"} name="output_prompt_title" onClick={props.handleEdit}></Button>
                          {props.editVariable === "output_prompt_title" ?
                            <Button className="btn-close" onClick={props.handleClose}></Button> : null}
                        </td>
                        <td class="text2">
                          {/* <p>
                            <strong>User Input Attributes</strong><br></br>
                            {props.available_variables.output_prompt_title}
                          </p> */}
                        </td>
                      </tr>
                      <tr>
                        <td>Description prompt Input</td>
                        <td>
                          {props.editVariable === "input_prompt_description" ?
                            <textarea className="prompt-engneering-input" rows="2" name="input_prompt_description" value={props.value} onChange={props.handleChange} />
                            : <> {props.input_prompt_description}</>}
                        </td>
                        <td>
                          <Button className={props.editVariable === "input_prompt_description" ? 'btn-save' : "btn-edit"} name="input_prompt_description" onClick={props.handleEdit}></Button>
                          {props.editVariable === "input_prompt_description" ?
                            <Button className="btn-close" onClick={props.handleClose}></Button> : null}
                        </td>
                        <td class="text2">
                          <p>
                            <strong>User Input Attributes</strong><br></br>
                            [name]<br/>
                            [category]<br/>
                            [attributes]
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td>Description prompt output</td>
                        <td>
                          {props.editVariable === "output_prompt_description" ?
                            <textarea className="prompt-engneering-input" rows="2" name="output_prompt_description" value={props.value} onChange={props.handleChange} />
                            : <> {props.output_prompt_description}</>}
                        </td>
                        <td>
                          <Button className={props.editVariable === "output_prompt_description" ? 'btn-save' : "btn-edit"} name="output_prompt_description" onClick={props.handleEdit}></Button>
                          {props.editVariable === "output_prompt_description" ?
                            <Button className="btn-close" onClick={props.handleClose}></Button> : null}
                        </td>
                        <td class="text2">
                        </td>
                      </tr>
                      <tr>
                        <td>Fearure list prompt Input</td>
                        <td>
                          {props.editVariable === "input_prompt_features" ?
                            <textarea className="prompt-engneering-input" rows="2" name="input_prompt_features" value={props.value} onChange={props.handleChange} />
                            : <> {props.input_prompt_features}</>}
                        </td>
                        <td>
                          <Button className={props.editVariable === "input_prompt_features" ? 'btn-save' : "btn-edit"} name="input_prompt_features" onClick={props.handleEdit}></Button>
                          {props.editVariable === "input_prompt_features" ?
                            <Button className="btn-close" onClick={props.handleClose}></Button> : null}
                        </td>
                        <td class="text2">
                          <p>
                            <strong>User Input Attributes</strong><br></br>
                            [name]<br/>
                            [category]<br/>
                            [attributes]
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td>Fearure list prompt output</td>
                        <td>
                          {props.editVariable === "output_prompt_features" ?
                            <textarea className="prompt-engneering-input" rows="2" name="output_prompt_features" value={props.value} onChange={props.handleChange} />
                            : <> {props.output_prompt_features}</>}
                        </td>
                        <td>
                          <Button className={props.editVariable === "output_prompt_features" ? 'btn-save' : "btn-edit"} name="output_prompt_features" onClick={props.handleEdit}></Button>
                          {props.editVariable === "output_prompt_features" ?
                            <Button className="btn-close" onClick={props.handleClose}></Button> : null}
                        </td>
                        <td class="text2">
                        </td>
                      </tr>
                      <tr>
                        <td>Closing statement prompt Input</td>
                        <td>
                          {props.editVariable === "input_prompt_closing" ?
                            <textarea className="prompt-engneering-input" rows="2" name="input_prompt_closing" value={props.value} onChange={props.handleChange} />
                            : <> {props.input_prompt_closing}</>}
                        </td>
                        <td>
                          <Button className={props.editVariable === "input_prompt_closing" ? 'btn-save' : "btn-edit"} name="input_prompt_closing" onClick={props.handleEdit}></Button>
                          {props.editVariable === "input_prompt_closing" ?
                            <Button className="btn-close" onClick={props.handleClose}></Button> : null}
                        </td>
                        <td class="text2">
                          <p>
                            <strong>User Input Attributes</strong><br></br>
                            [name]<br/>
                            [category]<br/>
                            [attributes]
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td>Closing statement  prompt output</td>
                        <td>
                          {props.editVariable === "output_prompt_closing" ?
                            <textarea className="prompt-engneering-input" rows="2" name="output_prompt_closing" value={props.value} onChange={props.handleChange} />
                            : <> {props.output_prompt_closing}</>}
                        </td>
                        <td>
                          <Button className={props.editVariable === "output_prompt_closing" ? 'btn-save' : "btn-edit"} name="output_prompt_closing" onClick={props.handleEdit}></Button>
                          {props.editVariable === "output_prompt_closing" ?
                            <Button className="btn-close" onClick={props.handleClose}></Button> : null}
                        </td>
                        <td class="text2">
                        </td>
                      </tr>
                      <tr>
                        <td>Temprature</td>
                        <td>
                          {props.editVariable === "temperature" ?
                            <input className="prompt-engneering-input" name="temperature" value={props.value} onChange={props.handleChange} />
                            : <> {props.temperature}</>}
                        </td>
                        <td>
                          <Button className={props.editVariable === "temperature" ? 'btn-save' : "btn-edit"} name="temperature" onClick={props.handleEdit}></Button>
                          {props.editVariable === "temperature" ?
                            <Button className="btn-close" onClick={props.handleClose}></Button> : null}
                        </td>
                        <td> 0 </td>
                      </tr>
                      <tr>
                        <td>Max Token</td>
                        <td>
                          {props.editVariable === "max_tokens" ?
                            <input className="prompt-engneering-input" name="max_tokens" value={props.value} onChange={props.handleChange} />
                            : <> {props.max_tokens}</>}
                        </td>
                        <td>
                          <Button className={props.editVariable === "max_tokens" ? 'btn-save' : "btn-edit"} name="max_tokens" onClick={props.handleEdit}></Button>
                          {props.editVariable === "max_tokens" ?
                            <Button className="btn-close" onClick={props.handleClose}></Button> : null}
                        </td>
                        <td>7</td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
                <div className='prompt-eng-wrapper'>
                  <Button variant="primary" className="btn-proeng" onClick={props.promptNextPage}>Update</Button>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      {<Footer />}
    </div>
  );
};
