import React, { PureComponent, Fragment } from "react"
import { Navigate } from 'react-router-dom'
import PropTypes from "prop-types"
import { compose } from "redux"
import { connect } from "react-redux"
import ReportView from "../pages/dashboardreports"
import "./style.css"

const mapStateToProps = (state) => ({
    login: state.login
})

const mapDispatchToProps = dispatch => ({
    
})

class Dashboardreports extends PureComponent {

    constructor(props) {
        super(props)

        this.state = {
           
        }
    }
   
    render() {
        return (
            <Fragment>
                {this.props.login.auth !== null ?
                    <ReportView
                        {...this.props}
                        {...this.state}
                    /> :
                    <Navigate to="/login" replace={true} />}
            </Fragment>)
    }
}

Dashboardreports.propTypes = {
    classes: PropTypes.object.isRequired
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(Dashboardreports)
