import React, { PureComponent, Fragment } from "react"
import { Navigate } from 'react-router-dom'
import PropTypes from "prop-types"
import { compose } from "redux"
import { connect } from "react-redux"
import Dashboard_SubscriptionView from "../pages/dashboard-subscription"
import { getCustomers, getOrders, deleteCustomerTransection, deleteOrderTransection } from "../hooks/subscription"
import "./style.css"

const mapStateToProps = (state) => ({
    profile: state.login.user,
    subscription: state.subscription,
    login: state.login
})

const mapDispatchToProps = dispatch => ({
    getCustomers: (sort)=> dispatch(getCustomers(sort)),
    getOrders: (sort)=> dispatch(getOrders(sort)),
    deleteCustomerTransection: (id)=> dispatch(deleteCustomerTransection(id)),
    deleteOrderTransection: (id)=> dispatch(deleteOrderTransection(id))
})

class Dashboard_Subscription extends PureComponent {

    constructor(props) {
        super(props)

        this.state = {
           activeKey:"customers"
        }
        this.props.getCustomers("name")
        this.props.getOrders("transaction_id")
    }
   
    hadelEventKey=()=> {
        if(this.state.activeKey==="customers") {
            this.props.getOrders("transaction_id")
            this.setState({activeKey:"orders"})
        }
        else {
            this.props.getCustomers("name")
            this.setState({activeKey:"customers"})
        }
    }

    handleSort = (event)=> {
        if(this.state.activeKey==="customers") {
            this.props.getCustomers(event.target.value)
        }
        if(this.state.activeKey==="orders") {
            this.props.getOrders(event.target.value)
        }
    }

    render() {
        return (
        <Fragment>
        {this.props.login.auth !== null ?
            <Dashboard_SubscriptionView
                {...this.props}
                {...this.state}
                hadelEventKey={this.hadelEventKey}
                handleSort={this.handleSort}   
            /> :
            <Navigate to="/login" replace={true} />}
        </Fragment>)
    }
}

Dashboard_Subscription.propTypes = {
    classes: PropTypes.object.isRequired
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(Dashboard_Subscription)

