import React, { PureComponent, Fragment } from "react"
import PropTypes from "prop-types"
import { compose } from "redux"
import { connect } from "react-redux"
import Promptengineering2View from "../pages/dashboard-prompt-engineering-2"
import { prompt_engneering, handleSave,promptPage, handleUpdatePrompt } from "../hooks/propmt"
import { Navigate } from 'react-router-dom'
import "./style.css"

const mapStateToProps = (state) => ({
    profile: state.login.user,
    prompt: state.prompt,
    login: state.login
})

const mapDispatchToProps = dispatch => ({
    prompt_engneering: (type) => dispatch(prompt_engneering(type)),
    handleSave: (data) => dispatch(handleSave(data)),
    promptPage: (page) => dispatch(promptPage(page)),
    handleUpdatePrompt: (data) => dispatch(handleUpdatePrompt(data))
})

class Promptengineering2 extends PureComponent {

    constructor(props) {
        super(props)

        this.state = {
           
        }
    }
    handleMode=(button)=> {
        this.props.prompt_engneering(button.target.name)
    }
    handleSave=()=>{
        this.props.handleSave(this.props.prompt.prompt_engneering_local)
    }
    
    promptPreviousPage=()=> {
        this.props.promptPage(1)
    }
    handleUpdatePrompt=()=> {
        this.props.handleUpdatePrompt(this.props.prompt.prompt_engneering_local.input[0])
    }
   
    render() {
        return (
        <Fragment>
        {this.props.login.auth !== null ?
            <Promptengineering2View
                {...this.props}
                {...this.state}  
                handleMode={this.handleMode}     
                handleSave={this.handleSave}
                promptPreviousPage={this.promptPreviousPage}
                handleUpdatePrompt={this.handleUpdatePrompt}  
            />:
            <Navigate to="/login" replace={true} />}
            {this.props.prompt==null||this.props.prompt.page==1 ? <Navigate to="/dashboard/prompt/step1" replace={true} /> : null}
        </Fragment>)
    }
}

Promptengineering2.propTypes = {
    classes: PropTypes.object.isRequired
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(Promptengineering2)
