import React, { PureComponent, Fragment } from "react"
import { Navigate   } from 'react-router-dom'
import { compose } from "redux"
import { connect } from "react-redux"
import EmailVerifyView from "../pages/email-verify"
import { resendEmail } from "../hooks/login"

const mapStateToProps = (state) => ({
    register: state.register
})

const mapDispatchToProps = dispatch => ({
    resendEmail: (data) => dispatch(resendEmail(data))
})

const get_direction = (register) => {
    if (register.email == undefined || register.email == null) {
        return true
    }

    return false
}

class verifyContainer extends PureComponent {

    constructor(props) {
        super(props)

        this.state = {

        }
    }

    resendEmail = () => {
        if (this.props.register.email !== undefined && this.props.register.email !== null) {
            this.props.resendEmail({ "email": this.props.register.email })
        }
    }

    render() {
        
        return (<Fragment>
            <EmailVerifyView
                {...this.props}
                {...this.state}
                resendEmail={this.resendEmail}
            />
            {get_direction(this.props.register) ? <Navigate to="/" replace={true} /> : null}
        </Fragment>)
    }
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(verifyContainer)
