import React, { PureComponent, Fragment } from "react"
import { Navigate } from 'react-router-dom'
import PropTypes from "prop-types"
import { compose } from "redux"
import { connect } from "react-redux"
import { contentGenerate, storeContent, regenerate, editOutput,generatedCount, scrollDown,
    showHistoryContent, contentHistory, getCategories } from "../hooks/content"
import { productCount } from "../hooks/dashboard"
import { SnackBar } from "../hooks/common"
import ContentGenerate from "../pages/contentGenerate"
import LoaderOutput from "../assets/models/loader-output"
import "./style.css"

const mapStateToProps = (state) => ({
    auth: state.login.auth,
    content: state.content,
    count: state.login.count,
    login: state.login,
    dashboard: state.dashboard
})

const mapDispatchToProps = dispatch => ({
    productCount:() =>dispatch(productCount()),
    scrollDown: (bool) => (dispatch(scrollDown(bool))),
    generatedCount: () => (dispatch(generatedCount())),
    contentGenerate: (data) => (dispatch(contentGenerate(data))),
    storeContent: (data) => (dispatch(storeContent(data))),
    regenerate: (data) => (dispatch(regenerate(data))),
    editOutput: (data) => (dispatch(editOutput(data))),
    showHistoryContent: (data) => (dispatch(showHistoryContent(data))),
    contentHistory: () => (dispatch(contentHistory())),
    getCategories: () =>dispatch(getCategories()),
    SnackBar: (show, message, varient) =>dispatch(SnackBar(show, message, varient))
})

const optionsArray = (categories) => {
    var cat = []
    for (let i = 0; i < categories.length; i++) {
        if(categories[i].children!==undefined &&categories[i].children.length>0) {
            cat.push({ value: categories[i].id, parent_id: categories[i].parent_id, title: categories[i].title,children:optionsArray(categories[i].children)})
        } else {
            cat.push({ value: categories[i].id, parent_id: categories[i].parent_id, title: categories[i].title})
        }
        
    }
    return cat
  }

const arrangeCategories = (select, data) => {
    var cat = []
    var selCat = []
    for (let s = 0; s < data.length; s++) {
        for (let r = 0; r < select.length; r++) {
            if (data[s].children !== undefined) {
                if (data[s].value == select[r] || data[s].parent_id == select[r]) {
                    cat.push({ id: data[s].value, title: data[s].title, children: arrangeCategories(select, data[s].children) })
                } else {
                    for (let l = 0; l < data[s].children.length; l++) {
                        var added = cat.find(obj => { return obj.id === data[s].value })
                        console.log(added)
                        if (added == undefined && data[s].children[l].value == select[r]) {
                            cat.push({ id: data[s].value, title: data[s].title, children: arrangeCategories(select, data[s].children) })
                        }
                    }
                }
            } else if (data[s].value == select[r] && data[s].children == undefined) {
                cat.push({ id: data[s].value, title: data[s].title })
            }
        }
    }
    for (let i = 0; i < cat.length; i++) {
        if (cat[i].children !== undefined && cat[i].children.length > 0) {
            for (let f = 0; f < cat[i].children.length; f++) {
                var text = cat[i].title
                text = text + ">" + cat[i].children[f];
                selCat.push(text);
            }
        } else {
            selCat.push(cat[i].title)
        }

    }
    return selCat
}

class ContantGeneraye extends PureComponent {

    constructor(props) {
        super(props)

        this.state = {
            advance: false,
            name: "",
            cat_value: "",
            category: [],
            attributes: [],
            choises: {
                title: true,
                description: true,
                close_statement: true,
                feature_bullet: true
            },
            att_name: "",
            att_value: [],
            attribute_value: "",
            error: {
                name: null,
                category: null
            },
            history: false,
            categories: [],
            category_id: [],
            selectCategories: []
        }
        this.props.contentHistory()
        this.props.getCategories() 
        this.props.productCount()
    }
    componentDidUpdate = () => {
        if(this.state.categories.length==0&&this.props.content.categories.length!=0) {
            this.setState({
                categories: optionsArray(this.props.content.categories)
            })
        }
    }
    handleAdvance = () => {
        this.setState({
            advance: !this.state.advance
        })
    }
    handleChange = (name, value) => {
        this.setState({
            [name]: value,
            error: {
                ...this.state.error,
                [name]: null
            }
        })
    }
    clearInput = () => {
        this.setState({
            name: "",
            cat_value: "",
            category: [],
            category_id: [],
            attributes: [],
            choises: {
                title: true,
                description: true,
                close_statement: true,
                feature_bullet: true
            },
            att_name: "",
            att_value: [],
            cat_value: ""
        })
    }
    addAttribute = () => {
        if (this.state.att_name === "" || this.state.att_value.length === 0) {
            return false
        }
        this.setState({
            ...this.state,
            attributes: [
                ...this.state.attributes,
                { name: this.state.att_name, values: this.state.att_value }
            ],
            att_name: "",
            att_value: []
        })
    }
    removeAttribute = (index) => {
        this.setState({
            ...this.state,
            attributes: [
                ...this.state.attributes.slice(0, index),
                ...this.state.attributes.slice(index + 1)
            ]
        })

    }
    handleChoises = (name, value) => {
        this.setState({
            ...this.state,
            choises: {
                ...this.state.choises,
                [name]: value
            }
        })
    }
    contentGenerate = () => {
        var state = this.state
        if (state.name == "" || state.selectCategories.length == 0|| state.attributes.length == 0) {
            this.props.SnackBar(true,'Add missing fields','error')
            return false
        } else if(this.props.dashboard.product_used >= this.props.dashboard.product_total) {
            this.props.SnackBar(true,'Reached maximum','error')
            return false
        }
        this.props.contentGenerate({
            project_id: this.props.login.project_id,
            name: this.state.name,
            category: arrangeCategories(state.selectCategories, state.categories),
            attributes: this.state.attributes,
            count: 1,
            choises: this.state.choises
        })
    }
    regenerate = (type, index) => {
        this.props.regenerate({ project_id: this.props.login.project_id, type: type, index: index, id: this.props.content.searchValue.id })
    }
    editOutput = (type, index, value) => {
        var output = this.props.content.output
        output = {
            ...output,
            [type]: Object.values({
                ...output[type],
                [index]: value
            })
        }
        this.props.editOutput({ project_id: this.props.login.project_id, output: output, id: this.props.content.searchValue.id })
    }
    goTo(URL) {
        window.location.target = "_blank"
        window.location.href = URL
    }
    redirectFeedback() {
        window.location.href = "https://eprodx.ai/feedback/"
    }
    addAttributeValue = () => {
        if (this.state.attribute_value === "") {
            return false
        }

        this.setState({
            att_value: [
                ...this.state.att_value,
                this.state.attribute_value
            ],
            attribute_value: ""
        })
    }
    removeAttributeValue = (index) => {
        this.setState({
            ...this.state,
            att_value: [
                ...this.state.att_value.slice(0, index),
                ...this.state.att_value.slice(index + 1)
            ]
        })

    }
    contentHistory=()=> {
        this.setState({
            history: !this.state.history
        })
    }
    showHistoryContent=(data)=> {
        this.props.showHistoryContent(data)
        this.setState({
            history: false
        })
    }
    selectCategory=(selected)=> {
        this.setState({
            selectCategories:selected
        })
    }
    render() {
        return (<Fragment>
            {this.props.login.auth !== null ?
                <ContentGenerate
                    {...this.props}
                    {...this.state}
                    showHistoryContent={this.showHistoryContent}
                    contentHistory={this.contentHistory}
                    goTo={this.goTo}
                    handleChange={this.handleChange}
                    handleAdvance={this.handleAdvance}
                    clearInput={this.clearInput}
                    addAttribute={this.addAttribute}
                    handleChoises={this.handleChoises}
                    contentGenerate={this.contentGenerate}
                    selectCategory={this.selectCategory}
                    removeAttribute={this.removeAttribute}
                    regenerate={this.regenerate}
                    addAttributeValue={this.addAttributeValue}
                    removeAttributeValue={this.removeAttributeValue}
                    editOutput={this.editOutput}
                    redirectFeedback={this.redirectFeedback} /> :
                <Navigate to="/login" replace={true} />}
            <LoaderOutput />
        </Fragment>)
    }
}

ContantGeneraye.propTypes = {
    classes: PropTypes.object.isRequired
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(ContantGeneraye)
