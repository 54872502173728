export default function reducer(state = {
    billing_info:{
        name:"United States of America",
        company:"Califonia",
        street_address:92833,
        state:"Califonia",
        zip_code:92833,
        country:"United States of America",
        additional_email:"abc@gmail.com"
    },
    billing_info_update: true,
    payment_history:null,
    my_plane: null
}, action) {
    switch (action.type) {
        case "STORE_PACKEGES": {
            return { ...state, package_list: action.data}
        }
        case "STOP_PROFILE_SUBSCRIPTION_Bill_UPDATE": {
            return { ...state, billing_info_update: false}
        }
        case "STORE_PAYMENT_HISTORY":{
            return { ...state, payment_history: action.data}
        }
        case "STORE_SUBSCRIPTION_MY_PLANE": {
            return { ...state, my_plane: action.data}
        }
        default:
            break;
    }
    return state;
}
