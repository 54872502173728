export default function reducer(state = {
    loading: false,
    spinner:false,
    users: [],
    books: [],
    showAlert: false,
    alertMessage: null,
    alertVarient: 'info',
    userRole: {
        reportAccess: false,
        reportModify: false,
        analysisAccess: false,
        analysisModify: false,
        dnaAccess: false,
        dnaModify: false
    },
    refresh: false,
    pauseRefresh: false,
    nextStep: "",
    faq: [],
    client_secret:null,
    tookPackage: false,
    pack_pop: false,
    privacy: null,
    terms: null
}, action) {
    switch (action.type) {
        case "LOADING_START": {
            return { ...state, loading: true }
        }
        case "LOADING_STOP": {
            return { ...state, loading: false }
        }
        case "STORE_FAQ": {
            return { ...state, faq: action.data }
        }
        case "PROGRESS_RUNNING": {
            return { ...state, spinner: action.bool }
        }
        case "STORE_USERS": {
            return { ...state, users: action.users }
        }
        case "STORE_BOOKS": {
            return { ...state, books: action.books }
        }
        case "SHOW_ALERT": {
            return { ...state, showAlert: action.show, alertMessage: action.message, alertVarient: action.varient }
        }
        case "UPDATE_USER_ROLE": {
            return { ...state, userRole: action.role }
        }
        case "REFRESH_START": {
            return { ...state, refresh: true }
        }
        case "REFRESH_STOP": {
            return { ...state, refresh: false }
        }
        case "STORE_STATUS_REFRESH": {
            return { ...state, refresh: action.data.status == 1 ? true : false }
        }
        case "LOADING_START": {
            return { ...state, pauseRefresh: true }
        }
        case "LOADING_STOP": {
            return { ...state, pauseRefresh: false }
        }
        case "HANDLE_NEXT_STEP": {
            return { ...state, nextStep: action.data }
        }
        case "STORE_PAYMENT_DETAILS":{
            return { ...state, client_secret: action.data }
        }
        case "SELECT_PACKAGE": {
            return { ...state, pack_pop: true }
        }
        case "STORE_PRIVACY": {
            return { ...state, privacy: action.data }
        }
        case "STORE_TERMS": {
            return { ...state, terms: action.data }
        }

    }
    return state;
}
