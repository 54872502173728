import React from 'react'
import { Button } from 'react-bootstrap'
import { headerLogo } from "../assets//img/images"
import { Navigate, useSearchParams } from 'react-router-dom'
import Page from "../assets/page"

const get_direction = (register,token) => {
  var page = ""
  if (token == null) {
      page ="/"
  } else if (register.nextStep) {
      page ="/login"
  }

  return page
}

export default function Verified(props) {
  const [searchParams] = useSearchParams();
  var token = searchParams.get("token");


  if (props.storeToken&&token!==null) {
     props.handleSaveToken(token)
  }

  return (
    <div>
      <div className='content-wrap login verified'>
        <div className='login-wrap'>
          <div className='loginbox'>
            <div className='loginwrap-inner'>
              <div className='login-logo'>
                <img
                  src={headerLogo}
                  className="d-inline-block img-fluid"
                  alt=""
                />
              </div>
              <h5>You email address is verified</h5>
              <div className='loginbox-form'>
                <div className="sendtext">
                  <p>At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet</p>
                </div>
                <Button className='btn-sign-in' onClick={props.handleNextStep}>Proceed to next step</Button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Page/>
      {get_direction(props.register,token) != "" ?
                <Navigate to={get_direction(props.register,token)} replace={true} /> : null}
    </div>
  );
};
