import React, { PureComponent, Fragment } from "react"
import { Navigate   } from 'react-router-dom'
import PropTypes from "prop-types"
import { compose } from "redux"
import { connect } from "react-redux"
import PromptengineeringView from "../pages/dashboard-prompt-engineering-1"
import { prompt_engneering, getModels, changeVariable, promptPage,handleUpdatePrompt, stopUpdatePrompt } from "../hooks/propmt"
import "./style.css"

const mapStateToProps = (state) => ({
    profile: state.login.user,
    prompt: state.prompt,
    login: state.login
})

const mapDispatchToProps = dispatch => ({
    getModels: () => dispatch(getModels()),
    stopUpdatePrompt: () => dispatch(stopUpdatePrompt()),
    prompt_engneering: (type) => dispatch(prompt_engneering(type)),
    changeVariable: (name, value) => dispatch(changeVariable(name, value)),
    promptPage: (page) => dispatch(promptPage(page)),
    handleUpdatePrompt: (data) => dispatch(handleUpdatePrompt(data))
})


const getVariableValue = (name, input) => {
    var editVariable = ""

    switch (name) {
        case "model":
            editVariable = input.model
            break;
        case "input_prompt_title":
            editVariable = input.input_prompt_title
            break;
        case "output_prompt_title":
            editVariable = input.output_prompt_title
            break;
        case "input_prompt_description":
            editVariable = input.input_prompt_description
            break;
        case "output_prompt_description":
            editVariable = input.output_prompt_description
            break;
        case "input_prompt_features":
            editVariable = input.input_prompt_features
            break;
        case "output_prompt_features":
            editVariable = input.output_prompt_features
            break;
        case "input_prompt_closing":
            editVariable = input.input_prompt_closing
            break;
        case "output_prompt_closing":
            editVariable = input.output_prompt_closing
            break;
        case "temperature":
            editVariable = input.temperature
            break;
        case "max_tokens":
            editVariable = input.max_tokens
            break;
        default:
            break;
    }

    return editVariable
}

class Promptengineering extends PureComponent {

    constructor(props) {
        super(props)

        this.state = {
            editVariable: "",
            value: "",
            nextPage:false,
            id: 0,
            ai_make: "",
            status: 1,
            type: "test",
            model: "",
            temperature: "",
            max_tokens: "",
            input_prompt_title: "",
            input_prompt_description: "",
            input_prompt_features: "",
            input_prompt_closing: "",
            output_prompt_title: "",
            output_prompt_description: "",
            output_prompt_features: "",
            output_prompt_closing: "",
            available_variables:{
                ai_make:  "",
                type:  "",
                model:  "",
                temperature:  "",
                max_tokens:  "",
                input_prompt_title:  "",
                input_prompt_description:  "",
                input_prompt_features:  "",
                input_prompt_closing: "",
                output_prompt_title:  "",
                output_prompt_description:"",
                output_prompt_features: "",
                output_prompt_closing:  ""
            }
            
        }
        this.props.promptPage(1)
    }
    componentDidMount=()=> {
        if(this.props.prompt.prompt_engneering_local==null) {
            this.props.prompt_engneering("test")
            this.props.getModels()
        }
    }
    componentDidUpdate=()=> {
        if(this.props.tempData!==null) {
            this.setState({ nextPage:true })
        }
        if(this.props.prompt.promptUpdate) {
            this.props.stopUpdatePrompt()
            this.setState({
                id: this.props.prompt.prompt_engneering_local.input[0].id,
                ai_make: this.props.prompt.prompt_engneering_local.input[0].ai_make,
                type: this.props.prompt.prompt_engneering_local.input[0].type,
                model: this.props.prompt.prompt_engneering_local.input[0].model,
                temperature: this.props.prompt.prompt_engneering_local.input[0].temperature,
                max_tokens: this.props.prompt.prompt_engneering_local.input[0].max_tokens,
                input_prompt_title: this.props.prompt.prompt_engneering_local.input[0].input_prompt_title,
                input_prompt_description: this.props.prompt.prompt_engneering_local.input[0].input_prompt_description,
                input_prompt_features: this.props.prompt.prompt_engneering_local.input[0].input_prompt_features,
                input_prompt_closing: this.props.prompt.prompt_engneering_local.input[0].input_prompt_closing,
                output_prompt_title: this.props.prompt.prompt_engneering_local.input[0].output_prompt_title,
                output_prompt_description: this.props.prompt.prompt_engneering_local.input[0].output_prompt_description,
                output_prompt_features: this.props.prompt.prompt_engneering_local.input[0].output_prompt_features,
                output_prompt_closing: this.props.prompt.prompt_engneering_local.input[0].output_prompt_closing,
                available_variables: this.props.prompt.prompt_engneering_local.available_variables[0]
            })
        }
    }
    handleMode=(button)=> {
        this.props.prompt_engneering(button.target.name)
    }
    handleEdit=(event)=> {
        if(this.state.editVariable==="") {
            var editVariable = getVariableValue(event.target.name, this.state )
            this.setState({
                editVariable: event.target.name,
                value: editVariable
            })
        }
        else {
            this.setState({ 
                [this.state.editVariable]: this.state.value,
                editVariable: "",
                value: "" })
            // this.props.changeVariable(this.state.editVariable, this.state.value)
            if(this.state.editVariable==="model") {
                var model = this.props.prompt.model.find((mo)=>(mo.id===this.state.value))
                this.setState({
                    ai_make:model.owned_by
                })
                // this.props.changeVariable("ai_make", model.owned_by)
            }
        }
    }
    handleChange =(e) => {
        this.setState({
            value: e.target.value
        })
    }
    handleClose = () => {
        this.setState({ editVariable: "", value: "" })
    }

    promptNextPage=()=> {
        this.props.handleUpdatePrompt(
            {
                id: this.state.id,
                type: this.state.type,
                ai_make: this.state.ai_make,
                model: this.state.model,
                input_prompt_title: this.state.input_prompt_title,
                output_prompt_title: this.state.output_prompt_title,
                input_prompt_description: this.state.input_prompt_description,
                output_prompt_description: this.state.output_prompt_description,
                input_prompt_features: this.state.input_prompt_features,
                output_prompt_features: this.state.output_prompt_features,
                input_prompt_closing: this.state.input_prompt_closing,
                output_prompt_closing: this.state.output_prompt_closing,
                temperature: this.state.temperature,
                max_tokens: this.state.max_tokens,
                status: this.state.status
            }
        )
        //To do this.props.promptPage(2)
    }

    render() {
        return (
        <Fragment>
        {this.props.login.auth !== null ?
            <PromptengineeringView
                {...this.props}
                {...this.state}
                handleMode={this.handleMode}
                handleEdit={this.handleEdit}
                handleChange={this.handleChange}
                handleClose={this.handleClose}
                promptNextPage={this.promptNextPage}
            />:
            <Navigate to="/login" replace={true} />}
            {this.props.prompt.page==2 ? <Navigate to="/dashboard/prompt/step2" replace={true} /> : null}
        </Fragment>)
    }
}

Promptengineering.propTypes = {
    classes: PropTypes.object.isRequired
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(Promptengineering)
