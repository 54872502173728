import { useRef, useEffect, Fragment } from 'react'
import { Container, Row, Col, Button, Form, Accordion, FloatingLabel } from 'react-bootstrap'
import Header from "../assets/header"
import Footer from "../assets/footer"
import { Helmet } from "react-helmet"

export default function About(props) {
  const textAreaRef = useRef(null);

  const resizeTextArea = () => {
    textAreaRef.current.style.height = "auto";
    textAreaRef.current.style.height = textAreaRef.current.scrollHeight + "px";
  };
  useEffect(resizeTextArea, [props.msg]);

  return (
    <div>
      <Header />
      <Helmet>
        <meta charSet="utf-8" />
        <title>{props.title}</title>
        <meta name="description" content={props.meta_description} />
        <meta property="og:title" content={props.meta_title} />
        <meta property="og:type" content="article" />
        <meta property="og:url" content={props.url} />
      </Helmet>
      <div className='content-wrap'>
        <section className='content-rows-wrap gfix'>
          <div className='introwrap'>
            <Container>
            {props.home.about !== null ?
              <Fragment>
              <Row>
                <Col lg={8} md={12} className='h-order1'>
                  <div className='intro-text'>
                    <h1>{props.home.about.title}</h1>
                    <p>{props.home.about.description}</p>
                  </div>
                </Col >
              </Row>
              <Row>
                <Col lg={8} md={12}>
                  <div className='content-rows gfix boiler'>
                    {props.home.about.content.map((item)=><Col md={12}>
                      <div className='content-rows-inner'>
                        <h3>{item.content_title}</h3>
                        <p>{item.content_description}</p>
                      </div>
                    </Col>)}
                    
                    
                  </div>

                </Col>
              </Row>
              </Fragment>
            :null}
            </Container>
            
          </div>

        </section>
        <section className='peopleask'>
          <Container>
            <Row>
              <Col lg={8} md={12}>
                <div className='faq-col1 boiler'>
                  <h5>People Also Ask</h5>
                </div>
                <div className='faq-col2 boiler'>
                  <Accordion defaultActiveKey="0">
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>Q. Is there a free trial available?</Accordion.Header>
                      <Accordion.Body>
                        Yes, you can try us out for 15 days, and we’ll provide you with a personalized onboarding to get you up and running.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                      <Accordion.Header>Q. Can I change my plan later?</Accordion.Header>
                      <Accordion.Body>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                        eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                        minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                        aliquip ex ea commodo consequat. Duis aute irure dolor in
                        reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                        pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                        culpa qui officia deserunt mollit anim id est laborum.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2">
                      <Accordion.Header>Q. How does billing work?</Accordion.Header>
                      <Accordion.Body>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                        eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                        minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                        aliquip ex ea commodo consequat. Duis aute irure dolor in
                        reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                        pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                        culpa qui officia deserunt mollit anim id est laborum.
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </div>
              </Col>

            </Row>
          </Container>
        </section>

        <section className='hm-contact-wrap exgap'>
          <Container>
            <Row>
              <Col md={6}>
                <div className='contact-col1'>
                  <h3>Contact US</h3>
                  <h4>We 'd love to hear more from you! Tell us what you have in mind and we’ll get back to you</h4>
                </div>
              </Col>
              <Col md={6}>
              <div className='contact-col2'>
                  <Form>
                    <Form.Group className="mb-3" controlId="">
                      <FloatingLabel
                        controlId="floatingTextarea"
                        label="Your Name"
                        className={"mb-3 " + (props.error.name !== null ? "error-field" : null)}
                      >
                        <Form.Control type="text" placeholder="" name="name" value={props.name} onChange={props.handleContactDetails} />
                        {props.error.name !== null ? <div className="validate"> <span> * {props.error.name} </span> </div> : ""}
                      </FloatingLabel>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="">
                      <FloatingLabel
                        controlId="floatingTextarea"
                        label="Business Name"
                        className={"mb-3 " + (props.error.business !== null ? "error-field" : null)}
                      >
                        <Form.Control type="text" placeholder="" name="business" value={props.business} onChange={props.handleContactDetails} />
                        {props.error.business !== null ? <div className="validate"> <span> * {props.error.business} </span> </div> : ""}
                      </FloatingLabel>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="">
                      <FloatingLabel
                        controlId="floatingTextarea"
                        label="Email"
                        className={"mb-3 " + (props.error.email !== null ? "error-field" : null)}
                      >
                        <Form.Control type="email" placeholder="" name="email" value={props.email} onChange={props.handleContactDetails} />
                        {props.error.email !== null ? <div className="validate"> <span> * {props.error.email} </span> </div> : ""}
                      </FloatingLabel>
                    </Form.Group>
                    <Form.Group className="mb-4" controlId="">
                      <FloatingLabel
                        controlId="floatingTextarea"
                        label="Message"
                        className={"mb-3 " + (props.error.msg !== null ? "error-field" : null)}
                      >
                        <Form.Control ref={textAreaRef} className='text-area' as="textarea" rows={2} placeholder="" name="msg" value={props.msg} onChange={props.handleContactDetails} />
                        {props.msg.length > 0 ? <p className='text-area-count'>{props.msg.length}</p> : null}
                        {props.error.msg !== null ? <div className="validate"> <span> * {props.error.msg} </span> </div> : ""}
                      </FloatingLabel>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="">
                      <Button className='btn-showall' onClick={props.contactButonClick}>Send</Button>
                    </Form.Group>
                  </Form>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </div>
      {<Footer />}
    </div>

  );
};
