import React, { PureComponent, Fragment } from "react"
import { Navigate } from 'react-router-dom'
import { compose } from "redux"
import { connect } from "react-redux"
import LoginView from "../pages/login"
import { login, socialLogin, restPageRedirection } from "../hooks/login"
import { getHomeData } from "../hooks/home"
import Page from "../assets/page"

const mapStateToProps = (state) => ({
    loginRed: state.login,
    common: state.common,
})

const mapDispatchToProps = dispatch => ({
    login: (data) => dispatch(login(data)),
    socialLogin: (data) => dispatch(socialLogin(data)),
    getHomeData: () => dispatch(getHomeData()),
    restPageRedirection: (bool) => dispatch(restPageRedirection(bool))
})

const getValidate = (state) => {
    var email = null
    var password = null

    if (state.email == "") {
        email = "Required"
    }
    else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(state.email)) {
        email = "invalid email address"
    }
    if (state.password == "") {
        password = "Required"
    }
    return { email: email, password: password }
}


class loginContainer extends PureComponent {

    constructor(props) {
        super(props)

        this.state = {
            email: "",
            password: "",
            error: {
                email: null,
                password: null
            },
            password_show: false,
            logged_in: false,
            otpRedirect:false
        }
        this.props.getHomeData()
        this.props.restPageRedirection(false)
    }
    componentDidUpdate = () => {
        if (this.props.loginRed.loggedIn && this.props.loginRed.user_pack == 1) {
            this.setState({ logged_in: true })
        }
        if( this.props.loginRed.otp_mail != null && !this.state.otpRedirect) {
            this.setState({otpRedirect:true})
        }
    }

    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value,
            error: {
                ...this.state.error,
                [e.target.name]: null
            }
        })
    }

    handleShowPassword = () => {
        this.setState({ password_show: !this.state.password_show })
    }
    handleLogin = () => {
        var state = this.state

        if (state.email == "" || state.password == "") {
            this.setState({
                error: getValidate(this.state)
            })
            return false
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(state.email)) {
            this.setState({
                error: getValidate(this.state)
            })
            return false
        }
        this.props.login({ "email": this.state.email, "password": this.state.password })
    }

    render() {
        return (<Fragment>
            <LoginView
                {...this.props}
                {...this.state}
                handleShowPassword={this.handleShowPassword}
                handleChange={this.handleChange}
                handleLogin={this.handleLogin}
            />
            <Page />
            {this.state.logged_in ? <Navigate to="/dashboard" replace={true} /> : null}
            {this.state.otpRedirect ? <Navigate to="/otp-verify" replace={true} /> : null}
        </Fragment>)
    }
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(loginContainer)
