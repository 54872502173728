import { API_URL } from "../Constants"
import { startLoading, stopLoading, SnackBar,progressRunning } from "./common"
import store from "../store"

export function storeUser(data) {
    return { type: 'STORE_USER', data };
}

export function getUsers(token) {
    return function (dispatch) {
        dispatch(startLoading())
        dispatch(SnackBar(true, 'Loading....', 'info'))
        fetch(API_URL + "user", {
            method: "GET",
            headers: {
                'Content-Type': 'application/json;charset=utf-8',
                'Authorization': 'Bearer ' + token
            }
        }).then(response => response.json(), error => console.log('An error occurred.', error))
            .then((json) => {
                dispatch(storeUser(json))
                dispatch(stopLoading())
            })
            .catch((error) => {
                // dispatch(SnackBar(true, error.response.data.errors.map((data) => (<p>{data}</p>)), 'error'))
            })
    }
}

export function UpdateLoginDetails(data) {
    return { type: 'UPDATE_LOGIN_DETAILS', data };
}
export function getPackUser(id, token) {
    return function (dispatch) {
        dispatch(startLoading())
        dispatch(SnackBar(true, 'Loading....', 'info'))
        fetch(API_URL + "get-user?id=" + id, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json;charset=utf-8',
                'Authorization': 'Bearer ' + token
            }
        }).then(response => response.json(), error => console.log('An error occurred.', error))
            .then((json) => {
                dispatch(UpdateLoginDetails(json))
                dispatch(stopLoading())
            })
            .catch((error) => {
                // dispatch(SnackBar(true, error.response.data.errors.map((data) => (<p>{data}</p>)), 'error'))
            })
    }
}
export function getUsersById(token) {
    return function (dispatch) {
        dispatch(startLoading())
        dispatch(SnackBar(true, 'Loading....', 'info'))
        fetch(API_URL + "user", {
            method: "GET",
            headers: {
                'Content-Type': 'application/json;charset=utf-8',
                'Authorization': 'Bearer ' + token
            }
        }).then(response => response.json(), error => console.log('An error occurred.', error))
            .then((json) => {
                dispatch(storeUser(json))
                dispatch(getPackUser(json.id, token))
                dispatch(stopLoading())
            })
            .catch((error) => {
                // dispatch(SnackBar(true, error.response.data.errors.map((data) => (<p>{data}</p>)), 'error'))
            })
    }
}

export function storeAuthToken(data) { return { type: 'STORE_TOKEN', data }; }

export function verifyPage() { return { type: 'VERIFY_PAGE' }; }


export function requestOTP(email) {
    return function (dispatch) {
        dispatch(startLoading())
        fetch(API_URL + "request-otp", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json;charset=utf-8',
                'accept': 'application/json;charset=utf-8'
            },
            body: JSON.stringify({"email":email})
        }).then(response => response.json())
            .then((json) => {
                dispatch(stopLoading())
            })
            .catch((error) => {
                dispatch(SnackBar(true, "Connection error", 'error'))
            })
    }
}

export function verifyOtp(data, backUp) {
    return function (dispatch) {
        dispatch(startLoading())
        dispatch(SnackBar(true, 'Loading....', 'info'))
        fetch(API_URL + "verify-otp", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json;charset=utf-8',
                'accept': 'application/json;charset=utf-8'
            },
            body: JSON.stringify(data)
        }).then(response => response.json())
            .then((json) => {
                if (json.status) { 
                    dispatch(storeAuthToken(backUp))
                    dispatch(getUsers(backUp.token))
                    dispatch(oneTimePassword(null,null))
                } else if(json.message!="") {
                    dispatch(SnackBar(true, json.message, 'error'))
                }
                dispatch(stopLoading())
            })
            .catch((error) => {
                dispatch(SnackBar(true, "Connection error", 'error'))
            })
    }
}

export function oneTimePassword(mail, data) { return { type: 'ONE_TIME_PASSWORD', mail, data }; }

export function login(data) {
    return function (dispatch) {
        dispatch(startLoading())
        dispatch(SnackBar(true, 'Loading....', 'info'))
        fetch(API_URL + "auth/login", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json;charset=utf-8',
                'accept': 'application/json;charset=utf-8'
            },
            body: JSON.stringify(data)
        }).then(response => response.json())
            .then((json) => {
                if (json.token !== undefined && json.user!=null &&json.user.two_factor_status=="1") {
                    dispatch(oneTimePassword(data.email,json))
                } else if(json.token !== undefined) {
                    dispatch(storeAuthToken(json))
                    dispatch(getUsers(json.token))
                }
                if (json.message == "Please Verify User") {
                    dispatch(SnackBar(true, json.message , 'error'))
                }
                if (!json.status) {
                    dispatch(SnackBar(true,json.message, 'error'))
                }
                dispatch(stopLoading())
            })
            .catch((error) => {
                dispatch(SnackBar(true, "Connection error", 'error'))
            })
    }
}

export function clearAuthToken() {
    return { type: 'CLEAR_LOGIN' };
}
export function logout(data) {
    return function (dispatch) {
        dispatch(startLoading())
        dispatch(SnackBar(true, 'Loading....', 'info'))
        fetch(API_URL + "auth/logout", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json;charset=utf-8',
                'Authorization': 'Bearer ' + store.getState().login.auth
            },
            body: JSON.stringify(data)
        }).then(response => response.json(), error => console.log('An error occurred.', error))
            .then((json) => {
                if (json.message === "User Logout") {
                    dispatch(clearAuthToken())
                    dispatch(SnackBar(false, 'Loading....', 'info'))
                } else {
                    dispatch(SnackBar(true, json.errors.map((data) => (<p>{data}</p>)), 'error'))
                }
                dispatch(stopLoading())
            })
            .catch((error) => {
                dispatch(SnackBar(true, error.response.data.errors.map((data) => (<p>{data}</p>)), 'error'))
            })
    }
}

export function registered(bool) {
    return { type: 'REGISTRATION_SUCCESS', bool };
}

export function resetPasswordLink() {
    return { type: 'RESET_PASSWORD_LINK' };
}

export function rocovery(data) {
    return function (dispatch) {
        dispatch(startLoading())
        dispatch(SnackBar(true, 'Loading....', 'info'))
        fetch(API_URL + "password/email", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json;charset=utf-8',
                'accept': 'application/json;charset=utf-8'
            },
            body: JSON.stringify(data)
        }).then(response => response.json())
            .then((json) => {
                if (json.message == "Undefined array key 0") {
                    dispatch(SnackBar(true, "Con not find Email address ", 'error'))
                }
                if(json.msg == "Reset password link sent on your email id.") {
                    dispatch(resetPasswordLink())
                }
                dispatch(stopLoading())
            })
            .catch((error) => {
                dispatch(SnackBar(true, "Connection error", 'error'))
            })
    }
}
export function storeSocialDetails(data) {
    return { type: 'STORE_SOCIAL_DETAILS', data };
}

export function socialLogin(data) {
    return function (dispatch) {
        dispatch(startLoading())
        dispatch(SnackBar(true,'Loading....','info'))
        fetch(API_URL + "auth/social-login", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json;charset=utf-8',
                'Authorization': 'Bearer ' + data.token
            },
            body: JSON.stringify(data)
        }).then(response => response.json(), error => console.log('An error occurred.', error))
            .then((json) => {
                dispatch(storeSocialDetails(json))
                dispatch(progressRunning(false))
                dispatch(stopLoading())
            })
            .catch((error) => {
                // dispatch(SnackBar(true, error.response.data.errors.map((data) => (<p>{data}</p>)), 'error'))
            })
    }
}

export function resendEmail(data) {
    return function (dispatch) {
        dispatch(startLoading())
        dispatch(SnackBar(true, 'Loading....', 'info'))
        fetch(API_URL + "email/resend", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json;charset=utf-8',
                'Authorization': 'Bearer ' + store.getState().login.auth
            },
            body: JSON.stringify(data)
        }).then(response => response.json())
            .then((json) => {
                if (json.errors !== undefined) {
                    dispatch(SnackBar(true, json.errors.map((data) => (<p>{data}</p>)), 'error'))
                }
                else {
                    dispatch(SnackBar(true, json.message, 'error'))
                }
                dispatch(stopLoading())
            })
            .catch((error) => {
                // dispatch(SnackBar(true, error.response.data.errors.map((data) => (<p>{data}</p>)), 'error'))
            })
    }
}

export function restPageRedirection(bool) {
    return{type:'RESET_PAGE_REDIRECTION', bool};
}