import React, { Fragment, useRef, useState } from 'react'
import { helpCircleOutline, helpCircleOutlineLabels, plus, remove } from "../assets/img/images"
import { Button } from 'react-bootstrap'
import Header from "../assets/header-dashboard"
import Output from "./contentOutput"
import Footer from "../assets/footer"
import { Tooltip as ReactTooltip } from "react-tooltip"
import 'react-dropdown-tree-select/dist/styles.css'
import { TreeSelect } from 'antd';

const getContentCount = (type, character) => {
  const addCommas = num => num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  const removeNonNumeric = num => num.toString().replace(/[^0-9]/g, "");
  var count = 10000
  var persentage = 100
  if (character !== undefined && character !== null) { count = 10000 - character }
  if (count == 0) { persentage = 0 } else { persentage = (count / 10000) * 100 + "%" }
  if (type == "count") { return addCommas(removeNonNumeric(count)) } else { return persentage }
}

export default function ContentGenerate(props) {
  const bottomRef = useRef();

  if (props.content.scrollOutPut && props.content.output !== null) {
    props.scrollDown(false)
    setTimeout(() => {
      bottomRef.current.scrollIntoView()
    }, 1000)
  }

  return (
    <div className="contents input-output">
      <Header />
      <div className="content-inner credit">
        <div className="credit-wrap">
          <h5>Credits Status
            <button type="button" className="btn tip" data-bs-placement="top" data-bs-toggle="popover" title="" data-bs-content="And here's some amazing content. It's very engaging. Right?"><img src={helpCircleOutline} alt="" /></button>
          </h5>
          <div className="line"><span className="fill" style={{ width: getContentCount("per", props.count) }}></span></div>
          <h6>{getContentCount("count", props.count)} / 10,000 remaining </h6>
        </div>
        <div className="input-details-wrap">
          <h3>Input details</h3>
          <div className="input-form">
            <div className="mb-3">
              <label className="form-label"> Product Name
                <button id="product-tool" type="button" className="btn tip" data-bs-placement="top" data-bs-toggle="popover" title="" data-bs-content="And here's some amazing content. It's very engaging. Right?"><img src={helpCircleOutlineLabels} alt="" /></button>
              </label>
              <ReactTooltip
                anchorId="product-tool"
                place="top"
                variant="info"
              >
                <p>Enter the product name one at a time</p>
              </ReactTooltip>
              <input type="text" className="form-control" id="exampleInputEmail1" placeholder="Nike"
                name="name" value={props.name} onChange={(e) => props.handleChange("name", e.target.value)} />

            </div>
            <div className="mb-3">
              <label className="form-label">Categories <button id="cat-tool" type="button" className="btn tip" data-bs-placement="top" data-bs-toggle="popover" title="" data-bs-content="And here's some amazing content. It's very engaging. Right?"><img src={helpCircleOutlineLabels} alt="" /></button>
                <ReactTooltip anchorId="cat-tool" place="top" variant="info" >
                  <p>{"Add the categories and the subcategories as 1>2>3 and press enter after each option"}</p>
                </ReactTooltip>
              </label>
              <div className="dropdownMultiselect"> 
                <TreeSelect
                  style={{ width: '100%' }}
                  value={props.selectCategories}
                  dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                  placeholder="Please select"
                  multiple
                  treeDefaultExpandAll
                  onChange={props.selectCategory}
                  treeData={props.categories}
                />
                 </div>
            </div>
            <div className="row attributes-title">
              <label className="form-label"><span>Attributes</span>
                <button id="attributes-tool" type="button" className="btn tip" data-bs-placement="top" data-bs-toggle="popover" title="" data-bs-content="And here's some amazing content. It's very engaging. Right?"><img src={helpCircleOutlineLabels} alt="" /></button>
              </label>
              <ReactTooltip
                anchorId="attributes-tool"
                place="top"
                variant="info"              >
                <p>a. The first field “Add your product attributes one by one”</p>
                <p>b. Second field “Type the values and press Enter”</p>
              </ReactTooltip>
            </div>

            <div className="row attributes">
              <div className="col-md-5">
                <input type="text" className="form-control addval" id="exampleInputEmail1" placeholder="Size"
                  value={props.att_name} onChange={(e) => props.handleChange("att_name", e.target.value)} />
              </div>
              <div className="row col-md-7" id="attri-cat">
                <div className="tagsInput">
                  {props.att_value.length !== 0 ?
                    <div id="att-tag-container" className="row">
                      {props.att_value.map((attribute, index) => (
                        <span id="tag-span">
                          <label className="tag-label"> {attribute} </label>
                          <span onClick={() => props.removeAttributeValue(index)} id="close-span">x</span>
                        </span>))}
                    </div> : null}
                  <div className="catagory-value">
                    <input type="text" className="form-control" id="exampleInputEmail1" placeholder="Add a value"
                      name="attribute_value" value={props.attribute_value} onChange={(e) => props.handleChange("attribute_value", e.target.value)} />
                    <img src={plus} alt="" onClick={props.addAttributeValue} />
                  </div>
                </div>
                <div className="button">
                  <button className="add-attribute" onClick={props.addAttribute}><img src={plus} alt="" /></button>
                </div>
              </div>
            </div>
            {props.attributes.length !== 0 ? props.attributes.map((attribute, index) => (
              <div className="results">
                <div className="row">
                  <div className="col-6">
                    <h4>{attribute.name}</h4>
                  </div>
                  <div className="col-6 ">
                    <h4>{attribute.values.map((data, index) => (data + (attribute.values.length - 1 !== index ? ", " : "")))}</h4>
                  </div>
                  <button className="remove-attribute" onClick={() => props.removeAttribute(index)}><img src={remove} alt="" /></button>
                </div>
              </div>
            )) : null}
            <button onClick={props.handleAdvance} className="btn-setting"><span>{props.advance ? "-" : "+"}</span>Advance Settings</button>
            {props.advance ? <Fragment>
              <label className="form-label">Select your choices you would like to output</label>

              <div className='outset'>
                <div className="row">
                  <div className="form-check col-md-6">
                    <input className="form-check-input" type="checkbox" checked={props.choises.title} onClick={(e) => props.handleChoises("title", e.target.checked)} />
                    <label className="form-check-label" for="flexCheckDefault">  Product Title </label>
                  </div>
                  <div className="form-check col-md-6">
                    <input className="form-check-input" type="checkbox" checked={props.choises.description} onClick={(e) => props.handleChoises("description", e.target.checked)} />
                    <label className="form-check-label" for="flexCheckDefault">  Product Description </label>
                  </div>
                  <div className="form-check col-md-6">
                    <input className="form-check-input" type="checkbox" checked={props.choises.close_statement} onClick={(e) => props.handleChoises("close_statement", e.target.checked)} />
                    <label className="form-check-label" for="flexCheckDefault">  Closing Statement </label>
                  </div>
                  <div className="form-check col-md-6">
                    <input className="form-check-input" type="checkbox" checked={props.choises.feature_bullet} onClick={(e) => props.handleChoises("feature_bullet", e.target.checked)} />
                    <label className="form-check-label" for="flexCheckDefault">  Feature Bullets </label>
                  </div>
                </div>
              </div>

            </Fragment> : null}
            <button onClick={props.contentHistory} className="btn-setting content-history-button"><span>{props.history ? "-" : "+"}</span>Content History</button>

            {props.history ? <Fragment>
              {props.content.content_history.map((cont, index) => (
                <div className='content-history-data' onClick={() => props.showHistoryContent(cont)}><span>{cont.product}</span></div>
              ))}
            </Fragment> : null}
            <div className="btn-wraps"  >
              <button className="btn btn-pgenerate" onClick={props.contentGenerate}>Generate Content</button>
              <button className="btn btn-reset" onClick={props.clearInput}>Reset</button>
            </div>
          </div>
        </div>

        {props.content.output !== null ? <Fragment>
          <Output
            {...props}
            clearData={() => props.storeContent(null)}
            data={props.content.output}
            regenerate={props.regenerate}
            editOutput={props.editOutput}
            id={props.content.searchValue.id}
            goTo={props.goTo}
            bottomRef={bottomRef} />
          <div>
            <button className="btn btn-feedback" onClick={props.redirectFeedback}>Submit Feedback & Get 50 SKUs FREE!</button>
          </div>
        </Fragment> : null}
      </div>
      <Footer />
    </div>
  );
};
