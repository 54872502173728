import React from 'react'
import { Container, Row, Col, Button, Breadcrumb } from 'react-bootstrap'
import Moment from 'moment'
import Header from "../assets/header-dashboard"
import Footer from "../assets/footer"

export default function PromptEngineeringTwoView(props) {

  return (
    <div>
      <Header />
      <div className='content-wrap- dash'>
        <Container>
          <Row>
            <Col md={12}>
              <div className='dash-content reports'>
                <div className='breadscrumb-wrap'>
                  <Breadcrumb>
                    <Breadcrumb.Item>Dashboard</Breadcrumb.Item>
                    <Breadcrumb.Item active>Prompt Engineering</Breadcrumb.Item>
                  </Breadcrumb>
                </div>
                <h2>Back Office</h2>
                <div className='quick-info-bar'>
                  <span className='mailcheck'>{props.profile.email}</span>
                  <span>ID: {props.profile.id}</span>
                  <span>Registration Date: {Moment(props.profile.created_at).format('MMMM DD. YYYY')} </span>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <div className='prompt-engineering-wrap'>
                <div className='pro-eng-header'>
                  <div className='pro-eng-steps'>
                    <div className='pro-eng-stepwrap completed'>
                      <h3>1</h3>
                      <h4>Input</h4>
                    </div>
                    <div className='pro-eng-stepwrap active'>
                      <h3>2</h3>
                      <h4>Output</h4>
                    </div>
                  </div>
                  <div className='status-wrap'>
                    <div class="btn-group switcher" role="group" aria-label="Basic example">
                      <button onClick={props.handleMode} name="test" type="button" class={"btn btn-primary draft" + (props.prompt.prompt_engneering_local !== null && props.prompt.prompt_engneering_local.type == "test" ? " active" : "")}>Test</button>
                      <button onClick={props.handleMode} name="live" type="button" class={"btn btn-primary live" + (props.prompt.prompt_engneering_local !== null && props.prompt.prompt_engneering_local.type == "live" ? " active" : "")}>Live</button>
                    </div>
                  </div>
                </div>
                {props.prompt.prompt_engneering_local !== null ?
                  <div className='output-wrap'>
                    <p>{props.prompt.prompt_engneering_local.output}</p>
                  </div> : ""}
                <div className='prompt-eng-wrapper'>
                  <Button variant="primary" className="btn-proeng" onClick={props.handleUpdatePrompt}>Update</Button>
                  <Button variant="light" className="btn-proeng" onClick={props.promptPreviousPage}>Back</Button>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      {<Footer />}
    </div>
  );
};
