import React from 'react'
import { helpCircleOutline, helpCircleOutlineLabels, plus, remove, edit, done, close } from "../assets/img/images"
import Header from "../assets/header-dashboard"
import Footer from "../assets/footer"
import { Tooltip as ReactTooltip } from "react-tooltip"
import CategoryEdit from './categoryEdit'

const getContentCount = (type, character) => {
  const addCommas = num => num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  const removeNonNumeric = num => num.toString().replace(/[^0-9]/g, "");
  var count = 10000
  var persentage = 100
  if (character !== undefined && character !== null) { count = 10000 - character }
  if (count == 0) { persentage = 0 } else { persentage = (count / 10000) * 100 + "%" }
  if (type == "count") { return addCommas(removeNonNumeric(count)) } else { return persentage }
}
export default function category(props) {

  return (
    <div className="contents input-output">
      <Header />
      <div className="content-inner credit">
        {/* <div className="credit-wrap">
          <h5>Credits Status
            <button type="button" className="btn tip" data-bs-placement="top" data-bs-toggle="popover" title="" data-bs-content="And here's some amazing content. It's very engaging. Right?"><img src={helpCircleOutline} alt="" /></button>
          </h5>
          <div className="line"><span className="fill" style={{ width: getContentCount("per", props.login.count) }}></span></div>
          <h6> {getContentCount("count", props.login.count)} / 10,000 remaining</h6>
        </div> */}
        <div className="input-details-wrap">
          <h3>Category Management</h3>
          <div className="input-form">
            <div className="mb-3">
              <label className="form-label">Categories <button id="cat-tool" type="button" className="btn tip" data-bs-placement="top" data-bs-toggle="popover" title="" data-bs-content="And here's some amazing content. It's very engaging. Right?"><img src={helpCircleOutlineLabels} alt="" /></button>
                <ReactTooltip anchorId="cat-tool" place="top" variant="info" >
                  <p>{"Add the categories and the subcategories as 1>2>3 and press enter after each option"}</p>
                </ReactTooltip>
              </label>

              <div className="row">
                <div className="col-md-11 col-11">
                  <input type="text" className="form-control" id="exampleInputEmail1" placeholder="Add a category"
                    name="main_category" value={props.main_category} onChange={props.handleChange} />
                  {props.error.main_category !== null ? <div className="validate"> <span> * {props.error.main_category} </span> </div> : ""}
                </div>
                <div className="col-md-1 col-1 btn-mrgn-auto">
                  <img src={plus} alt="" onClick={props.createCategory} />
                </div>
              </div>
            </div> 
            {props.content.categories.map((cat, index) => (<>
              <div className="row attributes mb-3">
                <div className="col-md-6">
                  <CategoryEdit {...props} index={index} data={cat} />
                </div>
                <div className="row col-md-2" id="attri-cat">
                  {!props.sub ?
                    <div className="button">
                      {cat.id != props.edit_id ?
                        <button className="add-attribute" onClick={() => props.addChildCategory(index)}><img src={plus} alt="" /></button> : null}
                    </div> : null}
                </div>
                <div className="row col-md-2" id="attri-cat">
                  <div className="button">
                    {cat.id == props.edit_id ?
                      <button className="add-attribute" onClick={props.handleClose}><img src={close} alt="" /></button> :
                      <button className="add-attribute" onClick={() => props.handleEdit(cat)}><img src={edit} alt="" /></button>}
                  </div>
                </div>
                <div className="row col-md-2" id="attri-cat">
                  <div className="button">
                    {cat.id == props.edit_id ?
                      <button className="add-attribute" onClick={() => props.handleUpdate(cat)}><img src={done} alt="" /></button> :
                      <button className="add-attribute" onClick={() => props.deleteCategory(cat.id)}><img src={remove} alt="" /></button>}
                  </div>
                </div>
              </div>
              {cat.children.map((child, childIndex) => (<>
                {child.id != null && child.id != 0 ?
                  <div className="row attributes mb-3">
                    <div className="col-md-1"></div>
                    <div className="col-md-6">
                      <CategoryEdit {...props} index={childIndex} data={child} />
                    </div>
                    <div className="row col-md-2" id="attri-cat">
                      <div className="button">
                        {child.id == props.edit_id ?
                          <button className="add-attribute" onClick={props.handleClose}><img src={close} alt="" /></button> :
                          <button className="add-attribute" onClick={() => props.handleEdit(child)}><img src={edit} alt="" /></button>}
                      </div>
                    </div>
                    <div className="row col-md-2" id="attri-cat">
                      <div className="button">
                        {child.id == props.edit_id ?
                          <button className="add-attribute" onClick={() => props.handleUpdate(child)}><img src={done} alt="" /></button> :
                          <button className="add-attribute" onClick={() => props.deleteCategory(child.id)}><img src={remove} alt="" /></button>}
                      </div>
                    </div>
                  </div> :
                  <div className="row attributes mb-3">
                    <div className="col-md-1"></div>
                    <div className="col-md-6">
                      <input type="text" className="form-control addval" id="exampleInputEmail1"
                        name="sub_category" value={props.sub_category} onChange={props.handleChange} />
                    </div>
                    <div className="row col-md-2" id="attri-cat">
                      <div className="button">
                        <button className="add-attribute" onClick={() => props.removeChildCat(index, childIndex)}><img src={close} alt="" /></button>
                      </div>
                    </div>
                    <div className="row col-md-2" id="attri-cat">
                      <div className="button">
                        <button className="add-attribute" onClick={() => props.createSubCategory(cat.id)}><img src={done} alt="" /></button>
                      </div>
                    </div>
                  </div>
                }</>
              ))}
            </>))}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};
