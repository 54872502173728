import { API_URL } from "../Constants"
import { startLoading, stopLoading, SnackBar, progressRunning } from "./common"
import store from "../store"

export function contactUs(data) {
    return function (dispatch) {
        dispatch(startLoading())
        dispatch(SnackBar(true,'Sending email...','info'))
        fetch(API_URL + "contact-us", {
            method: "POST",
            headers: {
                "Content-Type": "application/json;charset=utf-8",
                'Authorization': 'Bearer ' + store.getState().login.auth
            },
            body: JSON.stringify(data)
        }).then(response => response.json())
            .then((json) => {
                if(json.status) {
                    dispatch(SnackBar(true,'Email sent','success'))
                }
                else {
                    dispatch(SnackBar(true,'There was an issue sending an email','error'))
                }
                dispatch(stopLoading())
            })
            .catch((error) => {
                dispatch(SnackBar(true,'Operation timed out','error'))
            })
    }
}
export function storeHomeData(data) {
    return { type: 'STORE_HOME_DETAILS', data };
}

export function storeAboutData(data) {
    return { type: 'STORE_ABOUT_DETAILS', data };
}

export function getHomeData() {
    return function (dispatch) {
        dispatch(startLoading())
        sessionStorage.clear()
        dispatch(progressRunning(true))
        fetch(API_URL + "home-cms", {
            method: "GET",
            headers: {
                "Content-Type": "application/json;charset=utf-8"
            }
        }).then(response => response.json())
            .then((json) => {
                dispatch(stopLoading())
                dispatch(storeHomeData(json))
                dispatch(progressRunning(false))
            })
            .catch((error) => {
                dispatch(SnackBar(true,'Operation timed out','error'))
            })
    }
}

export function getAboutData() {
    return function (dispatch) {
        dispatch(startLoading())
        sessionStorage.clear()
        dispatch(progressRunning(true))
        fetch(API_URL + "about-cms", {
            method: "GET",
            headers: {
                "Content-Type": "application/json;charset=utf-8"
            }
        }).then(response => response.json())
            .then((json) => {
                dispatch(stopLoading())
                dispatch(storeAboutData(json))
                dispatch(progressRunning(false))
            })
            .catch((error) => {
                dispatch(SnackBar(true,'Operation timed out','error'))
            })
    }
}

export function savePackDetailsHome(data, pack_list) {
    return { type: 'STORE_HOME_SELECTED_PACK_DETAILS', data , pack_list };
}
export function updatedPackCount(data) {
    return { type: 'UPDATE_PACKAGE_COUNT', data };
}
