import { API_URL } from "../Constants"
import { startLoading, stopLoading, SnackBar } from "./common"
import store from "../store"

export function stopUpdate(){
    return{ type:'STOP_UPDATE_PROFILE' };
}

export function storeProfile(data){
    return{type:'STORE_USER',data};
}

export function getUsers() {
    return function (dispatch) {
        dispatch(startLoading())
        fetch(API_URL + "user", {
            method: "GET",
            headers: {
                'Content-Type': 'application/json;charset=utf-8',
                'Authorization': 'Bearer ' + store.getState().login.auth
            }
        }).then(response => response.json(), error => console.log('An error occurred.', error))
            .then((json) => {
                dispatch(storeProfile(json))
                dispatch(stopLoading())
            })
            .catch((error) => {
                // dispatch(SnackBar(true, error.response.data.errors.map((data) => (<p>{data}</p>)), 'error'))
            })
    }
}

export function saveAccountInfo(data) {
    return function (dispatch) {
        dispatch(startLoading())
        dispatch(SnackBar(true,'Profile updating....','info'))
        fetch(API_URL + "auth/update-profile", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json;charset=utf-8',
                'Authorization': 'Bearer ' + store.getState().login.auth
            },
            body: JSON.stringify(data)
        }).then(response => response.json(), error => console.log('An error occurred.', error))
            .then((json) => {
                if(json.status) {
                    dispatch(SnackBar(true,'Profile updated','success'))
                    dispatch(getUsers(json))
                }
                dispatch(stopLoading())
            })
            .catch((error) => {
                // dispatch(SnackBar(true, error.response.data.errors.map((data) => (<p>{data}</p>)), 'error'))
            })
    }
}

export function redirectLoginPage(){
    return{type:'REDIRECT_LOGIN_PAGE'};
}

export function passwordUpdate(data) {

    return function (dispatch) {
        dispatch(startLoading())
        dispatch(SnackBar(true,'Loading....','info'))
        fetch(API_URL + "auth/update-password", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json;charset=utf-8',
                'Authorization': 'Bearer ' + store.getState().login.auth
            },
            body: JSON.stringify(data)
        }).then(response => response.json(), error => console.log('An error occurred.', error))
            .then((json) => {
                if(json.status) {
                    dispatch(redirectLoginPage())
                } else {
                    dispatch(SnackBar(true,json.message,'error'))
                }
                dispatch(stopLoading())
            })
            .catch((error) => {
                // dispatch(SnackBar(true, error.response.data.errors.map((data) => (<p>{data}</p>)), 'error'))
            })
    }
}