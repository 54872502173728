import { API_URL } from "../Constants"
import { startLoading, stopLoading, SnackBar } from "./common"
import {getUsers} from "./login"
import store from "../store"

export function handleChange(name, value) {
    return{type:'HANDLE_REGISTER_CHANGE',name, value};
}

export function registered(data) {
    return{type:'REGISTRATION_SUCCESS', data};
}

export function register(data) {
    return function (dispatch) {
        dispatch(startLoading())
        dispatch(SnackBar(true, 'Creating account', 'success'))
        fetch(API_URL + "auth/register", {
            method: "POST",
            headers: {
                "Content-Type": "application/json;charset=utf-8",
                'Authorization': 'Bearer ' + store.getState().login.auth
            },
            body: JSON.stringify(data)
        }).then(response => response.json())
            .then((json) => {
                if(json.errors!==undefined) {
                    if(json.errors.name!==undefined) {
                        dispatch(SnackBar(true, json.errors.name[0], 'error'))
                    }else if(json.errors.email!==undefined) {
                        dispatch(SnackBar(true, json.errors.email[0], 'error'))
                    }
                    else if(json.errors.password !==undefined) {
                        dispatch(SnackBar(true, json.errors.password[0], 'error'))
                    }
                    else {
                        dispatch(SnackBar(true, "Registration failed", 'error'))
                    }
                }
                if(json.status) {
                    dispatch(registered(data.email))
                }
                dispatch(stopLoading())
            })
            .catch((error) => {
                // dispatch(SnackBar(true, error.response.data.errors.map((data) => (<p>{data}</p>)), 'error'))
            })
    }
}

export function restPageRedirection(bool) {
    return{type:'RESET_PAGE_REDIRECTION', bool};
}
export function updatePassword(data) {
    return function (dispatch) {
        dispatch(startLoading())
        dispatch(SnackBar(true, 'Updating password', 'success'))
        fetch(API_URL + "password/reset", {
            method: "POST",
            headers: {
                "Content-Type": "application/json;charset=utf-8"
            },
            body: JSON.stringify(data)
        }).then(response => response.json())
            .then((json) => {
                if(!json.status&&json.errors.email!==undefined) {
                    dispatch(SnackBar(true, json.errors.email[0], 'error'))
                } else if(!json.status&&json.errors.password!==undefined) {
                    dispatch(SnackBar(true, json.errors.password[0], 'error'))
                }
                if(json.status) {
                    dispatch(restPageRedirection(true))
                    dispatch(SnackBar(true, "Password Updated", 'success'))
                }
                dispatch(stopLoading())
            })
            .catch((error) => {
                dispatch(SnackBar(true, "Password Update Failed", 'error'))
            })
    }
}

export function storeUser(data) {
    return{type:'STORE_USER_DETAILS', data};
}

export function getUser() {
    return function (dispatch) {
        dispatch(startLoading())
        fetch(API_URL + "user", {
            method: "GET",
            headers: {
                'Content-Type': 'application/json;charset=utf-8',
                'Authorization': 'Bearer ' + store.getState().login.auth
            }
        }).then(response => response.json(), error => console.log('An error occurred.', error))
            .then((json) => {
                dispatch(storeUser(json))
                dispatch(stopLoading())
            })
            .catch((error) => {
                // dispatch(SnackBar(true, error.response.data.errors.map((data) => (<p>{data}</p>)), 'error'))
            })
    }
}

export function storeSocialDetails(data) {
    return{type:'STORE_SOCIAL_DETAILS', data};
}
export function socialLogin(data) {
    return function (dispatch) {
        dispatch(startLoading())
        dispatch(SnackBar(true,'Loading....','info'))
        fetch(API_URL + "auth/social-login", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json;charset=utf-8',
                'Authorization': 'Bearer ' + data.token
            },
            body: JSON.stringify(data)
        }).then(response => response.json(), error => console.log('An error occurred.', error))
            .then((json) => {
                dispatch(storeSocialDetails(json))
                dispatch(progressRunning(false))
                dispatch(stopLoading())
            })
            .catch((error) => {
                // dispatch(SnackBar(true, error.response.data.errors.map((data) => (<p>{data}</p>)), 'error'))
            })
    }
}

export function progressRunning(bool){
    return { type: 'PROGRESS_RUNNING', bool };
}

export function emailVerifyNextStep() {
    return { type: "EMAIL_VERIFY_NEXT_STEP",  }
}
export function storeAuthToken(data){
    return{type:'STORE_TOKEN_VERIFIED',data};
}
export function emailVerify(data) {
    return function (dispatch) {
        dispatch(startLoading())
        dispatch(SnackBar(true,'Loading....','info'))
        fetch(API_URL + "email/verify", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json;charset=utf-8',
                // 'Authorization': 'Bearer ' + store.getState().login.auth
            },
            body: JSON.stringify(data)
        }).then(response => response.json())
            .then((json) => {
                if(json!==undefined) {
                    dispatch(storeAuthToken(json))
                    dispatch(getUsers(json))
                }
                else {
                    dispatch(SnackBar(true, json.message, 'error'))
                }
                dispatch(stopLoading())
            })
            .catch((error) => {
                // dispatch(SnackBar(true, error.response.data.errors.map((data) => (<p>{data}</p>)), 'error'))
            })
    }
}
export function handleSaveToken(token){
    return{type:'HANDLE_SAVE_TOKEN',token };
}
export function slectPackage() { 
    return{type:'SELECT_PACKAGE' };
}
export function handleResetCredential(token, email) { 
    return{type:'HANDLE_RESET_CREDENTIAL',token, email };
}
export function updateProfile(data) {
    return function (dispatch) {
        dispatch(startLoading())
        dispatch(SnackBar(true,'Loading....','info'))
        fetch(API_URL + "auth/update-profile", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json;charset=utf-8',
                'Authorization': 'Bearer ' + store.getState().login.auth
            },
            body: JSON.stringify(data)
        }).then(response => response.json())
            .then((json) => {
                if(json.status!==undefined) {
                    dispatch(storeUser(json.user))
                }
                dispatch(stopLoading())
            })
            .catch((error) => {
                // dispatch(SnackBar(true, error.response.data.errors.map((data) => (<p>{data}</p>)), 'error'))
            })
    }
}
