import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import Header from "../assets/header"
import Footer from "../assets/footer"
import { Helmet } from "react-helmet"

export default function Terms(props) {

  return (
    <div>
      <Header />
      <Helmet>
        <meta charSet="utf-8" />
        <title>{props.title}</title>
        <meta name="description" content={props.meta_description} />
        <meta property="og:title" content={props.meta_title} />
        <meta property="og:type" content="article" />
        <meta property="og:url" content={props.url} />
      </Helmet>
      <div className='content-wrap'>
        <section className='content-rows-wrap gfix'>
            <div className='introwrap'>
              <Container>
                <Row>
                  <Col lg={8} md={12} className='h-order1'>
                    {props.common.terms!==null?
                    <div className='intro-text'>
                      <div
                        dangerouslySetInnerHTML={{ __html: props.common.terms }}
                      />
                    </div>:""}
                  </Col >              
                </Row>
              </Container>
            </div>
        </section>
      </div>
      {<Footer />}
    </div>
  );
};
