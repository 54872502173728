import React from 'react'
import { headerLogo } from "../assets//img/images"

export default function RecoverEmail(props) {

  return (
    <div>
      <div className='content-wrap login'>
        <div className='login-wrap'>
          <div className='loginbox'>
            <div className='loginwrap-inner'>
              <div className='login-logo'>
                <img
                  src={headerLogo}
                  className="d-inline-block img-fluid"
                  alt=""
                />
              </div>
              <h5>We've sent a password reset link to your email. Please check your inbox and follow the instructions to reset your password. If you don't see the email, be sure to check your spam or junk folder.</h5>
              <div className='loginbox-form'>
                <div className="sendtext">
                  <p>For further assistance, contact our support team. <br/><br/>Thank you!</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
