import React, { PureComponent, Fragment } from "react"
import { Navigate   } from 'react-router-dom'
import PropTypes from "prop-types"
import { compose } from "redux"
import { connect } from "react-redux"
import SigninView from "../pages/dashboardsignin"
import { productCount, getProductsList, handleContentWriter, updatedPackCount } from "../hooks/dashboard"
import { getPackUser } from "../hooks/login"
import { getPackages } from "../hooks/packages"
import "./style.css"

const mapStateToProps = (state) => ({
    dashboard: state.dashboard,
    login: state.login,
    packages: state.packages,
    home: state.home
})

const mapDispatchToProps = dispatch => ({
    productCount: () => dispatch(productCount()),
    getProductsList: (search) => dispatch(getProductsList(search)),
    getPackages: () => dispatch(getPackages()),
    getPackUser: (id, token)=>dispatch(getPackUser(id, token)),
    handleContentWriter: (id)=>dispatch(handleContentWriter(id)),
    updatedPackCount: (data) => dispatch(updatedPackCount(data))
})


class Dashboardsignin extends PureComponent {

    constructor(props) {
        super(props)
        this.timeout =  0;

        this.state = {
            content:false,
            user_id: null,
            search: ""
        }
        this.props.productCount()
        this.props.getProductsList("")
        this.props.getPackages()
    }
    componentDidUpdate =()=> {
        if(this.state.user_id==null&&this.props.login.user!==null) {
            this.props.getPackUser(this.props.login.user.id, this.props.login.auth)
            this.setState({user_id: this.props.login.user.id})
        }
        if(this.props.dashboard.content) {
            this.setState({content:true })
        }
        if(this.state.user_id==null&&this.props.login.user!==null&&this.props.home.data !== null && this.props.home.data.packages.length > 0&&(this.props.dashboard.credit_total==null||this.props.dashboard.product_total==null)) {
            var pack = this.props.home.data.packages.find(pack => { return pack.id === this.props.login.user.current_package })
            this.props.updatedPackCount({ "product": pack.product_count, "category": pack.cat_allowed })
        }
    }
    handleChange = (event) => {
        if(this.timeout) clearTimeout(this.timeout);
        this.setState({
           "search": event.target.value
        });   
        this.timeout = setTimeout(() => {
            this.props.getProductsList(this.state.search)
          }, 1000);
    }
    
    render() {
        return (
            <Fragment>
            {this.props.login.auth !== null ?
                <SigninView
                    {...this.props}
                    {...this.state}
                    handleChange={this.handleChange}
                /> :
                <Navigate to="/login" replace={true} />}
                {this.state.content ? <Navigate to="/content" replace={true} /> : null}
            </Fragment>)
    }
}

Dashboardsignin.propTypes = {
    classes: PropTypes.object.isRequired
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(Dashboardsignin)
