import React from "react"
import { Container, Row, Col, Button, Modal, Form } from 'react-bootstrap'
import PaymentForm from './stripePayment/CheckoutForm';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

const stripePromise = loadStripe('pk_live_QIrBnJeshOkvaMVNVSsPE9qY00PfR5Pk6u');

const getPriceValidation = (price) => {
  var val = true
  if (!parseFloat(price) > 0) {
    val = false
  }
  return val
}

function Checkout(props) {
  const options = {
    clientSecret: props.common.client_secret !== null ? props.common.client_secret.client_secret : null,
  }
  const handleClose = () => {
    if (props.login.user !== null && props.login.user.current_package !== null) {
      props.handleCheckout(false)
    }
  }
  const PayPlan=()=> {
    if(props.selected_pack.package_price!=0) {
      props.processPay(props.selected_pack.id)
    }
    if(props.selected_pack.package_price==0) {
      props.freeplan(props.selected_pack.id)
    }
  }

  return (
    <Modal show={props.checkout_model} onHide={handleClose}>
      <Modal.Header>
          <Modal.Title><h3>Select Plan</h3></Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container>
          <Row>
            <Col md={6}>
              <div className="pop-col1">
                <p> Unlock boundless possibilities for your ecommerce success </p>
                <div className="checkout-package-box-wrap">
                  {props.pack_list.map((pack) => (
                    pack.contact_us ? null :
                      <div className={"checkout-package-box" + (pack.id == props.selected_pack.id ? " active" : "")}>
                        <div className="checkout-package-box-content">
                          <div className="pb-header">
                            <Form>
                              <div key="inline-radio" className="">
                                <Form.Check
                                  inline
                                  checked={pack.id == props.selected_pack.id}
                                  label={pack.package_name}
                                  onClick={() => props.handleSelectedPack(pack)}
                                  name="group1"
                                  type="radio"
                                  id="inline-radio"
                                />
                              </div>
                            </Form>
                            <h5></h5>
                            <h6>${pack.package_price}</h6>
                          </div>
                          <div className="pb-pointwrap">
                            <h4>{pack.pac_description}</h4>
                            <div className="pb-points">
                              <ul>
                                <li>{pack.cat_allowed} categories</li>
                                <li>{pack.product_count} Products / month</li>
                                {pack.package_name !== "Start" ? <li>Risk free, 7 day money back gurantee</li> : null}
                              </ul>
                            </div>
                            <h4>{pack.pac_footer}</h4>
                          </div>
                        </div>
                      </div>
                  ))}
                </div>
                <div className="modify-billing-plan">
                  <h6> Billing Cycle </h6>
                  <div class="mb-check">
                    <div class="btn-group switcher" role="group" aria-label="Basic example">
                      <button onClick={props.handleMode} value="0" type="button" class={"btn btn-primary draft" + (props.bill_cycle == "0" ? " active" : "")}>Monthly</button>
                      <button onClick={props.handleMode} value="1" type="button" class={"btn btn-primary live" + (props.bill_cycle == "1" ? " active" : "")}>Yearly</button>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            <Col md={6}>

              <div className="pop-col2">

                {props.pay_step === "pack selected" ?
                  <a>
                    <Button variant="primary" className="btn-pay" onClick={PayPlan}>
                      {getPriceValidation(props.selected_pack.package_price) ? <>Pay ${props.selected_pack.package_price}</> : "Subscribe"}
                    </Button>
                  </a> : ""}
                {props.pay_step === "pay" ? <>
                  <h4>Payment Details</h4>

                  {props.common.client_secret !== null ?
                    <div className="strip-wrap">
                      <Elements stripe={stripePromise} options={options} >
                        <PaymentForm />
                      </Elements>
                    </div> : ""}
                  <p>Your personal data will be used to process your order, support your experience throughout this website, and for other purposes described in our privacy policy</p>
                  <p>Payment Terms: All payments are securely processed by Stripe. Visit Stripe’s Terms and Privacy policies</p></> : ""}
              </div>
            </Col>
          </Row>
        </Container>
      </Modal.Body>
    </Modal>
  );
}

export default Checkout;