import { API_URL } from "../Constants"
import store from "../store"

export function startLoading() {
    return { type: "LOADING_START" }
}

export function stopLoading() {
    return { type: "LOADING_STOP" }
}

export function changeStatus(data) { 
    return { type: "STORE_STATUS_REFRESH", data }
}

export function SnackBar(show, message, varient) { 
    return { type: 'SHOW_ALERT', show: show, message: message, varient: varient }
}

export function handleNextStep(data) {
    return { type: "HANDLE_NEXT_STEP", data }
}

export function storeFAQ(data){
    return{type:'STORE_FAQ',data};
}

export function getFaq() {
    return function (dispatch) {
        dispatch(startLoading())
        dispatch(SnackBar(true,'Loading....','info'))
        fetch(API_URL + "cms", {
            method: "GET",
            headers: {
                'Content-Type': 'application/json;charset=utf-8',
                // 'Authorization': 'Bearer ' + store.getState().login.auth
            }
        }).then(response => response.json(), error => console.log('An error occurred.', error))
            .then((json) => {
                dispatch(storeFAQ(json))
                dispatch(stopLoading())
            })
            .catch((error) => {
                // dispatch(SnackBar(true, error.response.data.errors.map((data) => (<p>{data}</p>)), 'error'))
            })
    }
}

export function storeModels(data) { return{type:'STORE_MODELS', data};}

export function getModel() {
    return function (dispatch) {
        dispatch(startLoading())
        dispatch(SnackBar(true,'Loading....','info'))
        fetch("https://api.openai.com/v1/models", {
            method: "GET",
            headers: {
                'Content-Type': 'application/json;charset=utf-8',
                'Authorization': 'Bearer ' + store.getState().login.auth
            }
        }).then(response => response.json(), error => console.log('An error occurred.', error))
            .then((json) => {
                dispatch(storeModels(json.data))
                dispatch(stopLoading())
            })
            .catch((error) => {
                // dispatch(SnackBar(true, error.response.data.errors.map((data) => (<p>{data}</p>)), 'error'))
            })
    }
}
export function savePackDetails(data, pack_list) {
    return { type: 'STORE_SELECTED_PACK_DETAILS', data , pack_list }
}

export function storePaymentDetails(data) { return{type:'STORE_PAYMENT_DETAILS', data};}
export function paymentIntern(id) {
    return function (dispatch) {
        dispatch(startLoading())
        fetch(API_URL + "payment/intent?pid="+id, {
            method: "GET",
            headers: {
                "Content-Type": "application/json;charset=utf-8",
                'Authorization': 'Bearer ' + store.getState().login.auth
            }
        }).then(response => response.json())
            .then((json) => {
                dispatch(stopLoading())
                dispatch(storePaymentDetails(json))
            })
            .catch((error) => {
                dispatch(SnackBar(true,'Operation timed out','error'))
            })
    }
}
export function clearHomeCheckout() {
    return { type: 'CLEAR_HOME_CHECKOUT'}
}

export function privacyCms() {
    return function (dispatch) {
        dispatch(startLoading())
        fetch(API_URL + "privacy-cms", {
            method: "GET",
            headers: {
                "Content-Type": "application/json;charset=utf-8",
                'Authorization': 'Bearer ' + store.getState().login.auth
            }
        }).then(response => response.json())
            .then((json) => {
                dispatch(stopLoading())
                dispatch(storePrivacy(json.privacy))
            })
            .catch((error) => {
                dispatch(SnackBar(true,'Operation timed out','error'))
            })
    }
}
export function storePrivacy(data) {
    return { type: 'STORE_PRIVACY', data}
}

export function termsCms() {
    return function (dispatch) {
        dispatch(startLoading())
        fetch(API_URL + "terms-cms", {
            method: "GET",
            headers: {
                "Content-Type": "application/json;charset=utf-8",
                'Authorization': 'Bearer ' + store.getState().login.auth
            }
        }).then(response => response.json())
            .then((json) => {
                dispatch(stopLoading())
                dispatch(storeTerms(json.privacy))
            })
            .catch((error) => {
                dispatch(SnackBar(true,'Operation timed out','error'))
            })
    }
}
export function storeTerms(data) {
    return { type: 'STORE_TERMS', data}
}
export function progressRunning(bool){
    return { type: 'PROGRESS_RUNNING', bool };
}

export function freeSubscibe(data){
    return { type: 'FREE_SUBSCRIBE_PLANE', data };
}
export function freeplan(id) {
    return function (dispatch) {
        dispatch(startLoading())
        dispatch(SnackBar(true,'Loading....','info'))
        fetch(API_URL + "free-subscribe", {
            method: "POST",
            headers: {
                "Content-Type": "application/json;charset=utf-8",
                'Authorization': 'Bearer ' + store.getState().login.auth
            },
            body: JSON.stringify({"current_package":id})
        }).then(response => response.json())
            .then((json) => {
                dispatch(stopLoading())
                dispatch(freeSubscibe(json))
            })
            .catch((error) => {
                dispatch(SnackBar(true,'Operation timed out','error'))
            })
    }
}