import React, { Fragment, PureComponent } from "react"
import { Navigate } from 'react-router-dom'
import { compose } from "redux"
import { connect } from "react-redux"
import Checkout from "./models/checkout"
import { savePackDetails, paymentIntern, storePaymentDetails, clearHomeCheckout, freeplan } from "../hooks/common"
import { getHomeData, updatedPackCount } from "../hooks/home"
import Alert from "./Alert"

const mapStateToProps = (state) => ({
  login: state.login,
  home: state.home,
  common: state.common,
  dashboard: state.dashboard
})

const mapDispatchToProps = dispatch => ({
  getHomeData: () => dispatch(getHomeData()),
  savePackDetails: (data, pack_list) => dispatch(savePackDetails(data, pack_list)),
  paymentIntern: (id) => dispatch(paymentIntern(id)),
  storePaymentDetails: (data) => dispatch(storePaymentDetails(data)),
  clearHomeCheckout: () => dispatch(clearHomeCheckout()),
  freeplan: (id) => dispatch(freeplan(id)),
  updatedPackCount: (data) => dispatch(updatedPackCount(data))
})

const getDirection = (state) => {
  var page = ""
  if (state.redirect && state.redirectPage == "details") {
    page = "/details"
  } else if ((state.redirect && !state.checkout_model) || state.home) {
    page = "/login"
  }
  else if (state.logged_in) {
    page = "/dashboard"
  }
  return page
}

const getPackage = (data, type) => {
  var dataArray = []
  for (let index = 0; index < data.length; index++) {
    if (data[index].billing_cycle == "1" && type === "1" && data[index].status == "1") {
      dataArray = [
        ...dataArray,
        data[index]
      ]
    } else if (data[index].billing_cycle != "1" && type != "1" && data[index].status == "1") {
      dataArray = [
        ...dataArray,
        data[index]
      ]
    }
  }
  return dataArray
}

class Header extends PureComponent {

  constructor(props) {
    super(props)

    this.state = {
      checkout_model: false,
      bill_cycle: "",
      pack_list: [],
      selected_pack: null,
      pay_step: "pack selected",
      redirect: false,
      home: false,
      redirectPage: ""
    }
    this.props.getHomeData()
  }
  componentDidMount = () => {
    if ((this.props.login.loggedIn || this.props.common.pack_pop) && this.props.login.user !== null && this.props.login.user.current_package == null && this.state.selected_pack == null && (this.props.login.pack !== null || (this.props.home.data !== null && this.props.home.data.packages.length > 0))) {
      this.packUpdate()
    }
  }

  componentDidUpdate = () => {
    if (!this.props.login.loggedIn && this.props.login.pack !== null && this.props.home.checkout !== "") {
      this.props.clearHomeCheckout()
      this.setState({
        home: true
      })
    }
    if ((this.props.login.loggedIn || this.props.common.pack_pop) && this.props.login.user !== null && this.props.login.user.current_package == null && this.state.selected_pack == null && (this.props.login.pack !== null || (this.props.home.data !== null && this.props.home.data.packages.length > 0))) {
      this.packUpdate()
    }

    if (this.props.common.client_secret !== null && this.state.pay_step === "pack selected") {
      this.setState({
        pay_step: "pay"
      })
    }
    if ((this.props.login.loggedIn || this.props.common.pack_pop) && this.props.login.user !== null && this.props.login.user.current_package != null && this.props.login.user.business_name == null) {
      this.setState({
        redirect: true,
        redirectPage: "details"
      })
    }

    if (this.props.login.loggedIn && this.props.login.user !== null && this.props.login.user.current_package != null && (this.props.dashboard.credit_total == null||this.props.dashboard.product_total == null) && (this.props.home.data !== null && this.props.home.data.packages.length > 0)) {
      var pack = this.props.home.data.packages.find(pack => { return pack.id === this.props.login.user.current_package })
      this.props.updatedPackCount({ "product": pack.product_count, "category": pack.cat_allowed })
    }
  }

  packUpdate = () => {
    if (this.props.login.pack !== null) {
      this.setState({
        bill_cycle: this.props.login.pack.billing_cycle,
        pack_list: getPackage(this.props.home.data.packages, this.props.login.pack.billing_cycle),
        selected_pack: this.props.login.pack,
        checkout_model: true,
        redirect: true
      })
    }
    else {
      this.setState({
        bill_cycle: getPackage(this.props.home.data.packages, this.props.home.data.packages[0].billing_cycle)[0].billing_cycle,
        pack_list: getPackage(this.props.home.data.packages, this.props.home.data.packages[0].billing_cycle),
        selected_pack: getPackage(this.props.home.data.packages, this.props.home.data.packages[0].billing_cycle)[0],
        checkout_model: true,
        redirect: true
      })
    }
  }


  handleCheckout = (bool) => {
    if (this.state.redirect) {
      this.setState({ checkout_model: bool })
    } else {
      this.setState({ checkout_model: bool })
      this.props.savePackDetails(null, [])
    }
  }
  handleMode = (e) => {
    this.setState({
      bill_cycle: e.target.value,
      pack_list: getPackage(this.props.home.data.packages, e.target.value)
    })
  }
  processPay = (id) => {
    this.props.paymentIntern(id)
  }
  handleSelectedPack = (pack) => {
    this.props.storePaymentDetails(null)
    this.setState({
      selected_pack: pack,
      pay_step: "pack selected"
    })
  }

  render() {
    return (<Fragment >
      {this.state.selected_pack !== null ?
        <Checkout
          {...this.props}
          {...this.state}
          handleCheckout={this.handleCheckout}
          handleMode={this.handleMode}
          handleSelectedPack={this.handleSelectedPack}
          processPay={this.processPay}
        /> : ""}
      {getDirection(this.state) != "" ? <Navigate to={getDirection(this.state)} replace={true} /> : null}
      <Alert />
    </Fragment>)
  }
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(Header)
