import React, { PureComponent, Fragment } from "react"
import { Navigate } from 'react-router-dom'
import PropTypes from "prop-types"
import { compose } from "redux"
import { connect } from "react-redux"
import Dashboard_PackagesView from "../pages/dashboard-packages"

import { getPackages, updatePackageStatus, deletePackage, editpackage, 
    stopUpdateDashboardPackage, handleCreate, handleUpdate, stopPackageStep} from "../hooks/packages"
import "./style.css"

const mapStateToProps = (state) => ({
    packages: state.packages,
    profile: state.login.user,
    login: state.login
})

const mapDispatchToProps = dispatch => ({
    getPackages: () => dispatch(getPackages()),
    stopPackageStep: () => dispatch(stopPackageStep()),
    stopUpdateDashboardPackage: () => dispatch(stopUpdateDashboardPackage()),
    updatePackageStatus: (data) => dispatch(updatePackageStatus(data)),
    deletePackage: (id) => dispatch(deletePackage(id)),
    editpackage: (id) => dispatch(editpackage(id)),
    handleCreate: (data) => dispatch(handleCreate(data)),
    handleUpdate: (data) => dispatch(handleUpdate(data)),
    
})
const getValidation = (state) => {
    
    var package_name = null
    var package_price = null
    var sku_allowed = null
    var cat_allowed = null
    var attribute = null
    var storage = null 
    var stripe_id = null
    var product_count = null

    var validate =true
    
    if (state.package_name == "") {
        package_name = "Required"
        validate = false
    }
    if (state.package_price === "") {
        package_price = "Required"
        validate = false
    }
    if (state.sku_allowed === "") {
        sku_allowed = "Required"
        validate = false
    }
    if (state.cat_allowed === "") {
        cat_allowed = "Required"
        validate = false
    }
    if (state.product_count === "") {
        product_count = "Required"
        validate = false
    }
    if (state.attribute === "") {
        attribute = "Required"
        validate = false
    }
    if (state.storage === "") {
        storage = "Required"
        validate = false
    }
    if (state.stripe_id == null||state.stripe_id === "") {
        stripe_id = "Required"
        validate = false
    }

    return {data:{ 
                package_name: package_name,
                package_price: package_price,
                sku_allowed: sku_allowed,
                cat_allowed: cat_allowed,
                attribute: attribute,
                storage: storage,
                stripe_id: stripe_id,
                product_count: product_count
            },validate:validate}
}

const getCreatUpdate = (state, props)=> {
    var data = {
                "user_id": props.profile.id,
                "id": state.package_id,
                "package_name": state.package_name,
                "status": state.status,
                "package_price": state.package_price,
                "sku_allowed": state.sku_allowed,
                "cat_allowed": state.cat_allowed,
                "attribute": state.attribute,
                "storage": state.storage,
                "stripe_id": state.stripe_id,
                "product_count": state.product_count,
                "pac_description": state.pac_description,
                "pac_footer": state.pac_footer,
                "billing_cycle": state.billing_cycle,
                "contact_us": state.contact_us,
                "sku": state.sku,
                "price_sku": state.price_sku,
                "category": state.category,
                "price_category": state.price_category,
                "storage_add": state.storage_add,
                "price_storage": state.price_storage,
                "manual": state.manual,
                "bulk": state.bulk,
                "name": state.dis_name,
                "dis_type": state.dis_type,
                "dis_status": state.dis_status,
                "calculation": state.calculation,
                "Discount": state.discount_amount,
                "customer_pays": state.customer_pays,
                "dis_edit_index": state.product_titles,
                "Output":{
                    "id": state.output_id,
                    "catelog_id": state.catelog_id,
                    "product_titles": state.product_titles,
                    "introduction": state.introduction,
                    "feature_bullets": state.feature_bullets,
                    "detailed_descriptions": state.detailed_descriptions,
                    "closing_statement": state.closing_statement,
                    "url_slug": state.url_slug,
                    "meta_description": state.meta_description,
                    "faq": state.faq,
                    "spec_table": state.spec_table,
                    "applications": state.applications,
                    "call_to_action": state.call_to_action,
                    "battle_card": state.battle_card,
                    "cus_review": state.cus_review,
                    "product_structure": state.product_structure,
                    "admin_owner": state.admin_owner,
                    "contributors": state.contributors,
                    "product_type": state.product_type,
                    "import": state.import,
                    "export": state.export
                }
            }
    return data
}

class Dashboard_packages extends PureComponent {

    constructor(props) {
        super(props)

        this.state = {
            activeVieKey: "view",
            activeKey: "0",
            stepl: false,
            step2: false,
            step3: false,
            error: {
                package_name: null,
                package_price: null,
                sku_allowed: null,
                cat_allowed: null,
                attribute: null,
                storage: null,
                stripe_id:null,
                product_count: null
            },
            package_id: 0,
            package_name: "",
            package_price: "",
            sku_allowed: "",
            cat_allowed: "",
            attribute: "",
            storage: "",
            stripe_id: "",
            pac_description: "",
            pac_footer: "",
            product_count: "",
            billing_cycle:"",
            status:0,
            contact_us: true,

            sku: 0,
            price_sku: "",
            category: 0,
            price_category: "",
            storage_add: 0,
            price_storage: "",

            dicount_id: 0,
            dis_name: "",
            dis_type: 0,
            dis_status: 0,
            calculation: "",
            discount_amount: "",
            customer_pays: "",
            disc_edit: false,

            manual: {
                product_name: false,
                category: false,
                attributes: false,
                feature_bullets: false,
                cate_function: false,
                measure: false,
                certificate: false,
                upc: false,
                reviews: false,
            },
            bulk: {
                product_name: false,
                category: false,
                attributes: false,
                feature_bullets: false,
                cate_function: false,
                measure: false,
                certificate: false,
                upc: false,
                reviews: false,
            },

            output_id:0,
            catelog_id:0,
            product_titles: "",
            introduction: "",
            feature_bullets: "",
            detailed_descriptions: "",
            closing_statement: "",
            url_slug: "",
            meta_description: "",
            faq: "",
            spec_table: "",
            applications: "",
            call_to_action: "",
            battle_card: "",
            cus_review: "",
            product_structure: "",
            admin_owner: "",
            contributors: "",
            product_type: "",
            import: "",
            export: ""
        }
        this.props.getPackages()
    }
    componentDidUpdate=()=> {
        if(this.props.packages.package_step) {
            this.clearData()
            this.props.stopPackageStep()
        }

        if(this.props.packages.editPackageUpdate) {
            this.props.stopUpdateDashboardPackage()
            this.setState({
                activeVieKey: "add",

                package_id: this.props.packages.editPackage.catelog.id,
                package_name: this.props.packages.editPackage.catelog.package_name,
                package_price: this.props.packages.editPackage.catelog.package_price,
                sku_allowed: this.props.packages.editPackage.catelog.sku_allowed,
                cat_allowed: this.props.packages.editPackage.catelog.cat_allowed,
                attribute: this.props.packages.editPackage.catelog.attribute,
                storage: this.props.packages.editPackage.catelog.storage,
                product_count: this.props.packages.editPackage.catelog.product_count,
                stripe_id: this.props.packages.editPackage.catelog.stripe_id,
                pac_description: this.props.packages.editPackage.catelog.pac_description,
                pac_footer: this.props.packages.editPackage.catelog.pac_footer,
                billing_cycle: this.props.packages.editPackage.catelog.billing_cycle,
                contact_us: this.props.packages.editPackage.catelog.contact_us,
                status: this.props.packages.editPackage.catelog.status,
                error: {
                    package_name: null,
                    package_price: null,
                    sku_allowed: null,
                    cat_allowed: null,
                    attribute: null,
                    storage: null,
                    stripe_id:null,
                    product_count: null
                },

                    
                sku: this.props.packages.editPackage.add_ons.sku,
                price_sku: this.props.packages.editPackage.add_ons.price_sku,
                category: this.props.packages.editPackage.add_ons.category,
                price_category: this.props.packages.editPackage.add_ons.price_category,
                storage_add: this.props.packages.editPackage.add_ons.storage_add,
                price_storage: this.props.packages.editPackage.add_ons.price_storage,

                dicount_id: this.props.packages.editPackage.discount.id,
                dis_name: this.props.packages.editPackage.discount.name,
                dis_type: this.props.packages.editPackage.discount.dis_type,
                dis_status: this.props.packages.editPackage.discount.dis_status,
                calculation: this.props.packages.editPackage.discount.calculation,
                discount_amount: this.props.packages.editPackage.discount.Discount,
                customer_pays: this.props.packages.editPackage.discount.customer_pays,

                manual: this.props.packages.editPackage.manual,
                bulk: this.props.packages.editPackage.bulk,
                output_id: this.props.packages.editPackage.output != null ? this.props.packages.editPackage.output.id : null,
                catelog_id: this.props.packages.editPackage.catelog.id,
                product_titles: this.props.packages.editPackage.output != null ? this.props.packages.editPackage.output.product_titles : null,
                introduction: this.props.packages.editPackage.output != null ? this.props.packages.editPackage.output.introduction : null,
                feature_bullets: this.props.packages.editPackage.output != null ? this.props.packages.editPackage.output.feature_bullets : null,
                detailed_descriptions: this.props.packages.editPackage.output != null ? this.props.packages.editPackage.output.detailed_descriptions : null,
                closing_statement: this.props.packages.editPackage.output != null ? this.props.packages.editPackage.output.closing_statement : null,
                url_slug: this.props.packages.editPackage.output != null ? this.props.packages.editPackage.output.url_slug : null,
                meta_description: this.props.packages.editPackage.output != null ? this.props.packages.editPackage.output.meta_description : null,
                faq: this.props.packages.editPackage.output != null ? this.props.packages.editPackage.output.faq : null,
                spec_table: this.props.packages.editPackage.output != null ? this.props.packages.editPackage.output.spec_table : null,
                applications: this.props.packages.editPackage.output != null ? this.props.packages.editPackage.output.applications : null,
                call_to_action: this.props.packages.editPackage.output != null ? this.props.packages.editPackage.output.call_to_action : null,
                battle_card: this.props.packages.editPackage.output != null ? this.props.packages.editPackage.output.battle_card : null,
                cus_review: this.props.packages.editPackage.output != null ? this.props.packages.editPackage.output.cus_review : null,
                product_structure: this.props.packages.editPackage.output != null ? this.props.packages.editPackage.output.product_structure : null,
                admin_owner: this.props.packages.editPackage.output != null ? this.props.packages.editPackage.output.admin_owner : null,
                contributors: this.props.packages.editPackage.output != null ? this.props.packages.editPackage.output.contributors : null,
                product_type: this.props.packages.editPackage.output != null ? this.props.packages.editPackage.output.product_type : null,
                import: this.props.packages.editPackage.output != null ? this.props.packages.editPackage.output.import : null,
                export: this.props.packages.editPackage.output != null ? this.props.packages.editPackage.output.export : null
            })
        }
    }
    
    updatePackageStatus = (event, data, index) => {
        this.props.updatePackageStatus({ "id": data.id, "status": event.target.value, index })
    }
    handleChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value,
            error: {
                ...this.state.error,
                [event.target.name]: null
            }
        })
    }

    handleUpdate = () => {
        if (this.state.package_id === 0) {
            this.props.handleCreate(getCreatUpdate(this.state, this.props))
        } else {
            this.props.handleUpdate(getCreatUpdate(this.state, this.props))
        }
        this.setState({ step3: true })
    }

    handleDiskChange = (event, index) => {
        this.setState({
            discount: Object.values({
                ...this.state.discount,
                [index]: {
                    ...this.state.discount[index],
                    [event.target.name]: event.target.checked
                }
            })
        })
    }
    handleEditDiscount = (event) => {
        if(event.target.name=="edit") {
            this.setState({disc_edit:true})
        } else {
            this.setState({disc_edit:false})
        }
    }
    handleAddDiscount = () => {
        if (this.state.dis_name !== "" && this.state.calculation !== "" && this.state.discount_amount !== "" && this.state.customer_pays !== "") {
            this.setState({
                discount: [
                    ...this.state.discount,
                    {
                        transaction_id: 0,
                        dis_name: this.state.dis_name,
                        dis_type: this.state.dis_type,
                        status: this.state.status,
                        calculation: this.state.calculation,
                        discount_amount: this.state.discount_amount,
                        customer_pays: this.state.customer_pays
                    }
                ],
                transaction_id: 0,
                dis_name: "",
                dis_type: 0,
                status: 0,
                calculation: "",
                discount_amount: "",
                customer_pays: "",
            })
        }
    }


    handleNext = () => {
        if (this.state.activeKey === "0") {
            if(getValidation(this.state).validate) {
                this.setState({
                    dis_edit_index: null,
                    activeKey: "1",
                    stepl: true
                })
            } else {
                this.setState({
                    error: getValidation(this.state).data
                })
            }
        }
        else if (this.state.activeKey === "1") {
            this.setState({
                activeKey: "2",
                step2: true
            })
        }
    }

    handleBack = () => {
        this.setState({
            activeKey: this.state.activeKey === "2" ? "1" : "0"
        })
    }

    handleCheckBox = (e, type) => {
        if (type == "manual") {
            this.setState({
                ...this.state,
                manual: {
                    ...this.state.manual,
                    [e.target.name]: e.target.checked?1:0
                }
            })
        }
        else {
            this.setState({
                ...this.state,
                bulk: {
                    ...this.state.bulk,
                    [e.target.name]: e.target.checked?1:0
                }
            })
        }
    }
    handleViewKey = () => {
        this.setState({
            activeVieKey: this.state.activeVieKey === "add" ? "view" : "add"
        })
        if(this.state.activeVieKey === "add") {
            this.clearData()
        }
    }
    handleContactUs = (event) => {
        this.setState({
            contact_us: event.target.checked
        })
    }
    clearData=()=> {
        this.setState({
            activeVieKey: "view",
            activeKey: "0",
            stepl: false,
            step2: false,
            step3: false,

            package_id: 0,
            package_name: "",
            package_price: "",
            sku_allowed: "",
            cat_allowed: "",
            attribute: "",
            storage: "",
            stripe_id:"",
            pac_description:"",
            pac_footer:"",
            product_count:"",
            billing_cycle:"",
            contact_us:false,
            status: 0,
            error: {
                package_name: null,
                package_price: null,
                sku_allowed: null,
                cat_allowed: null,
                attribute: null,
                storage: null,
                stripe_id:null,
                product_count: null
            },

            sku: 0,
            price_sku: "",
            category: 0,
            price_category: "",
            storage_add: 0,
            price_storage: "",

            dicount_id: 0,
            dis_name: "",
            dis_type: 0,
            dis_status: 0,
            calculation: "",
            discount_amount: "",
            customer_pays: "",
            disc_edit: false,

            manual: {
                product_name: false,
                category: false,
                attributes: false,
                feature_bullets: false,
                cate_function: false,
                measure: false,
                certificate: false,
                upc: false,
                reviews: false,
            },
            bulk: {
                product_name: false,
                category: false,
                attributes: false,
                feature_bullets: false,
                cate_function: false,
                measure: false,
                certificate: false,
                upc: false,
                reviews: false,
            },

            catelog_id:0,
            output_id:0,
            product_titles: "",
            introduction: "",
            feature_bullets: "",
            detailed_descriptions: "",
            closing_statement: "",
            url_slug: "",
            meta_description: "",
            faq: "",
            spec_table: "",
            applications: "",
            call_to_action: "",
            battle_card: "",
            cus_review: "",
            product_structure: "",
            admin_owner: "",
            contributors: "",
            product_type: "",
            import: "",
            export: ""
        })
    }

    handleStatusUpdate= ()=> {
        this.props.handleStatusUpdate()
    }


    render() {
        console.log(this.state,"package")
        return (
            <Fragment>
                {this.props.login.auth !== null ?
                    <Dashboard_PackagesView
                        {...this.props}
                        {...this.state}
                        updatePackageStatus={this.updatePackageStatus}
                        handleChange={this.handleChange}
                        handleEditDiscount={this.handleEditDiscount}
                        handleDiskChange={this.handleDiskChange}
                        handleAddDiscount={this.handleAddDiscount}
                        handleNext={this.handleNext}
                        handleBack={this.handleBack}
                        handleUpdate={this.handleUpdate}
                        handleCheckBox={this.handleCheckBox}
                        handleViewKey={this.handleViewKey}
                        handleContactUs={this.handleContactUs}
                    /> :
                    <Navigate to="/login" replace={true} />}
            </Fragment>)
    }
}

Dashboard_packages.propTypes = {
    classes: PropTypes.object.isRequired
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(Dashboard_packages)
