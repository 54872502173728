import React from "react"
import { BrowserRouter, Route, Routes } from "react-router-dom"
import Login from './locals/loginContainer'
import Register from './locals/registerContainer'
import Details from './locals/detailsContainer'
import EmailVerify from './locals/emailVerifyContainer'
import Verified from './locals/verifiedContainer'
import Home from './locals/homeContainer'
import Thanx from './locals/thankContainer'
import Recoverpassword from './locals/recoverpasswordContainer'
import Forgotpassword from './locals/forgotpasswordContainer'
import Default from './locals/defaultContainer'
import About from './locals/about'
import Faq from './locals/faqContainer'
import Dashboardprofile from './locals/dashboard-profileContainer'
import Dashboardsubscription from './locals/dashboard-profile-subscriptionContainer'
import Content from './locals/contentContainer'
import CatContainer from './locals/catContainer'
import Dashboardsignin from './locals/dashboard-signinContainer'
import Dashboardreports from './locals/dashboard-reportsContainer'
import Promptengineeringstep1 from './locals/dashboard-prompt-engineering-step1Container'
import Promptengineeringstep2 from './locals/dashboard-prompt-engineering-step2Container'
import Dashboardsubscriptions from './locals/dashboard-subscriptionsContainer'
import Dashboardpackages from './locals/dashboard-packagesContainer'
import Dashboardsettings from './locals/dashboard-settingsContainer'
import Privacy from './locals/privacyContainer'
import Terms from './locals/termsContainer'
import RecoverEmail from "./pages/recoverEmail"
import OneTimePassword from "./locals/otpContainer"
import ContactUs from "./locals/contactUsContainer"
import Boiler from './locals/boilerContainer'

export default () => (
  <BrowserRouter basename="/">
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/login" element={<Login />} />
      <Route path="/register" element={<Register />} />
      <Route path="/details" element={<Details/>} />
      <Route path="/verify-email" element={<EmailVerify />} />
      <Route path="/verified" element={<Verified />} />
      <Route path="/recoverpassword" element={<Recoverpassword />} />
      <Route path="/recover-email" element={<RecoverEmail />} />
      <Route path="/password/reset" element={<Forgotpassword />} />
      <Route path="/otp-verify" element={<OneTimePassword />} />
      <Route path="/content" element={<Content />} />
      <Route path="/categories" element={<CatContainer />} />
      <Route path="/thank-you" element={<Thanx />} />
      <Route path="/faq" element={<Faq />} />
      <Route path="/privacy-policy" element={<Privacy />} />
      <Route path="/contact-us" element={<ContactUs />} />
      <Route path="/terms-of-service" element={<Terms />} />
      <Route path="/dashboard/prompt/step1" element={<Promptengineeringstep1 />} />
      <Route path="/dashboard/prompt/step2" element={<Promptengineeringstep2 />} />
      <Route path="/dashboard/packages" element={<Dashboardpackages />} />
      <Route path="/dashboardprofile" element={<Dashboardprofile />} />
      <Route path="/dashboard/subscription" element={<Dashboardsubscriptions />} />
      <Route path="/dashboard/reports" element={<Dashboardreports />} />
      <Route path="/dashboard/settings" element={<Dashboardsettings />} />
      <Route path="/dashboard" element={<Dashboardsignin />} />
      <Route path="/dashboard/profile/subscription" element={<Dashboardsubscription />} />
      <Route path="/default" element={<Default />} />
      <Route path="/about-us" element={<About />} />
    </Routes>
  </BrowserRouter>
)
