import { API_URL } from "../Constants"
import { startLoading, stopLoading, SnackBar } from "./common"
import store from "../store"

export function stopProfileSubscriptionBillUpdate() {
    return { type: 'STOP_PROFILE_SUBSCRIPTION_Bill_UPDATE',  };
}
export function prompt_engneering(type) {
    return function (dispatch) {
        dispatch(startLoading())
        dispatch(SnackBar(true, 'Loading....', 'info'))
        fetch(API_URL + "prompt/engineering?type=" + type, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json;charset=utf-8',
                'Authorization': 'Bearer ' + store.getState().login.auth
            }
        }).then(response => response.json())
            .then((json) => {
                // dispatch(storePromptEngneer(json))
                dispatch(stopLoading())
            })
            .catch((error) => {
                dispatch(SnackBar(true, "Connection error", 'error'))
            })
    }
}
// profile Subscription part

export function getBillInfo() {
    return function (dispatch) {
        dispatch(startLoading())
        dispatch(SnackBar(true, 'Loading....', 'info'))
        fetch(API_URL + "profile/billInfo/get", {
            method: "GET",
            headers: {
                'Content-Type': 'application/json;charset=utf-8',
                'Authorization': 'Bearer ' + store.getState().login.auth
            }
        }).then(response => response.json())
            .then((json) => {
                // dispatch(storePromptEngneer(json))
                dispatch(stopLoading())
            })
            .catch((error) => {
                dispatch(SnackBar(true, "Connection error", 'error'))
            })
    }
}
export function updateBillInfo(data) {
    return function (dispatch) {
        dispatch(startLoading())
        fetch(API_URL + "profile/billInfo/update", {
            method: "POST",
            headers: {
                "Content-Type": "application/json;charset=utf-8",
                'Authorization': 'Bearer ' + store.getState().login.auth
            },
            body: JSON.stringify(data)
        }).then(response => response.json())
            .then(() => {
                dispatch(prompt_engneering(data.type))
                dispatch(stopLoading())
            })
            .catch((error) => {
                dispatch(SnackBar(true, "Connection error dashboard", 'error'))
            })
    }
}

export function storePaymentHistory(data) {
    return { type: 'STORE_PAYMENT_HISTORY', data };
}
export function getPaymentHistory() {
    return function (dispatch) {
        dispatch(startLoading())
        dispatch(SnackBar(true, 'Loading....', 'info'))
        fetch(API_URL + "payment-history", {
            method: "GET",
            headers: {
                'Content-Type': 'application/json;charset=utf-8',
                'Authorization': 'Bearer ' + store.getState().login.auth
            }
        }).then(response => response.json())
            .then((json) => {
                dispatch(storePaymentHistory(json.history))
                dispatch(stopLoading())
            })
            .catch((error) => {
                dispatch(SnackBar(true, "Connection error", 'error'))
            })
    }
}

export function storeSubscriptionMyPlane(data) {
    return { type: 'STORE_SUBSCRIPTION_MY_PLANE', data };
}

export function subscriptionMyPlane() {
    return function (dispatch) {
        dispatch(startLoading())
        dispatch(SnackBar(true, 'Loading....', 'info'))
        fetch(API_URL + "my-subscription", {
            method: "GET",
            headers: {
                'Content-Type': 'application/json;charset=utf-8',
                'Authorization': 'Bearer ' + store.getState().login.auth
            }
        }).then(response => response.json())
            .then((json) => {
                dispatch(storeSubscriptionMyPlane(json))
                dispatch(stopLoading())
            })
            .catch((error) => {
                dispatch(SnackBar(true, "Connection error", 'error'))
            })
    }
}