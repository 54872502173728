import { combineReducers } from 'redux';
import common from './common'
import login from './login'
import content from './content'
import register from './register'
import dashboard from './dashboard'
import packages from './packages'
import settings from './settings'
import subscription from './subscription'
import prompt from './prompt'
import profileSubcription from './profileSubcription'
import home from './home'

export default combineReducers({
    common,
    home,
    login,
    content,
    register,
    dashboard,
    packages,
    settings,
    subscription,
    prompt,
    profileSubcription
});
