import React, { PureComponent, Fragment } from "react"
import PropTypes from "prop-types"
import { compose } from "redux"
import { connect } from "react-redux"
import ContactView from "../pages/contactUs"
import { contactUs, getHomeData } from "../hooks/home"
import "./style.css"

const mapStateToProps = (state) => ({
    home: state.home
})

const mapDispatchToProps = dispatch => ({
    getHomeData: () => dispatch(getHomeData()),
    contactUs: (data) => dispatch(contactUs(data))
})

const getValidation = (state) => {
    var name = null
    var business = null
    var email = null
    var msg = null
    var validate =true

    if (state.name == "") {
        name = "Required"
        validate = false
    }
    if (state.business == "") {
        business = "Required"
        validate = false
    }
    if (state.email == "") {
        email = "Required"
        validate = false
    }
    else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(state.email)) {
        email = "invalid email address"
        validate = false
    }
    if (state.msg == "") {
        msg = "Required"
        validate = false
    }
    return {data:{ email: email, name: name ,business:business,msg:msg},validate:validate}
}

class contact extends PureComponent {

    constructor(props) {
        super(props)

        this.state = {
            name: "",
            business: "",
            email: "",
            msg: "",
            error: {
                name: null,
                business: null,
                email: null,
                msg: null
            },
            title:"Contact eProdx - Get in Touch for AI-Powered Content Solutions",
            meta_title: "Contact eProdx - Get in Touch for AI-Powered Content Solutions",
            meta_description: "Get in touch with eProdx for AI-powered content solutions. Reach out to us for inquiries, partnerships, or more information about our services.",
            url: "https://eprodx.ai/contact-us"
        }
        this.props.getHomeData()
    }  
    
    contactButonClick = ()=> {
        if(getValidation(this.state).validate) {
            this.props.contactUs(this.state)
            this.setState({
                name: "", business: "", email: "", msg: ""
            })
        } else {
            this.setState({
                error: getValidation(this.state).data
            })
        }
    }

    handleContactDetails =(e)=> {
        this.setState({
            [e.target.name]:e.target.value,
            error: {
                ...this.state.error,
                [e.target.name]: null
            }
        })
    }

    render() {
        return (<Fragment>
            <ContactView
                {...this.props}
                {...this.state}   
                contactButonClick={this.contactButonClick}
                handleContactDetails={this.handleContactDetails}
            />
        </Fragment>)
    }
}

contact.propTypes = {
    classes: PropTypes.object.isRequired
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(contact)
