import React, { PureComponent, Fragment } from "react"
import { compose } from "redux"
import { connect } from "react-redux"
import VerifiedView from "../pages/email-verified"
import { emailVerify, emailVerifyNextStep, handleSaveToken, slectPackage } from "../hooks/register"
import { getHomeData } from "../hooks/home"
import { Navigate } from 'react-router-dom'

const mapStateToProps = (state) => ({
    register: state.register,
    login: state.login,
    common: state.common,
    home: state.home
})

const mapDispatchToProps = dispatch => ({
    emailVerifyNextStep: () => dispatch(emailVerifyNextStep()),
    emailVerify: (data) => dispatch(emailVerify(data)),
    handleSaveToken: (token) => dispatch(handleSaveToken(token)),
    slectPackage: () => dispatch(slectPackage()),
    getHomeData: () => dispatch(getHomeData())
})

class verifiedContainer extends PureComponent {

    constructor(props) {
        super(props)

        this.state = {
            verify: true
        }
        this.props.getHomeData()
    }
    componentDidUpdate = () => {
        if (this.props.register.token !== null && this.state.verify !== false) {
            this.setState({ verify: false }, () => this.props.emailVerify({ "token": this.props.register.token }))
        }
    }
    handleNextStep = () => {
        if (this.props.common.tookPackage) {
            this.props.emailVerifyNextStep()
        }
        else {
            this.props.slectPackage()
        }
    }
    handleSaveToken = (token) => {
        this.props.handleSaveToken(token)
    }

    render() {

        return (<Fragment>
            <VerifiedView
                {...this.props}
                {...this.state}
                handleNextStep={this.handleNextStep}
                handleSaveToken={this.handleSaveToken}
                storeToken={this.props.register.token == null}
            />
        </Fragment>)
    }
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(verifiedContainer)
