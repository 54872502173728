import React from 'react'
import { Container, Row, Col, Button, Dropdown, Table, Form, ProgressBar } from 'react-bootstrap'
import { sort, sortactive, calender, infoIcon } from "../assets/img/images"
import optionsbtn from "../assets/img/more_options.png"
import Header from "../assets/header-dashboard"
import Footer from "../assets/footer"
import Moment from 'moment'

const getCount = (used, total) => {
  var av_per = 0
  var used = parseInt(used)
  var total = parseInt(total)
  av_per = (used / total) * 100
  return av_per
}

export default function Home(props) {
  return (
    <div>
      <Header page="dashboard" />
      <div className='content-wrap signin'>
        <div className='signin-topwrap' id='top'>
          <Container>
            <Row className="justify-content-between">
              <Col md={4}>
                <div className='workspace'>
                  <h5>Dashboard</h5>
                  <h3>My Workspace</h3>
                  <div className='dropwrap'>
                    <Form.Select aria-label="">
                      {props.login.domain.map((dom) => (
                        <option> {dom.domain}</option>
                      ))}
                    </Form.Select>
                  </div>
                  {props.login.user !== null && props.packages.package_list.length > 0 ? <h4>My Plan: {props.packages.package_list.find(obj => { return obj.id == props.login.user.current_package }).package_name}</h4> : null}
                  <Button href="/content" className='btn-getcredit'>Create Content</Button>

                </div>
              </Col>
              <Col md={8}>
                <div className='signbox-inner-wrap'>
                  <div className='signbox-wrap credit'>
                    <h2>Categories</h2>
                    {props.dashboard.credits !== "" && props.dashboard.credits !== null ?
                      <div className='sign-text'>
                        <h4>{props.dashboard.credit_used} / {props.dashboard.credit_total} <span>Categories</span></h4>
                      </div> : ""}
                    <div className='stepline-wrap'>
                      <ProgressBar now={getCount(props.dashboard.credit_used, props.dashboard.credit_total)} />
                    </div>
                    {props.dashboard.credit_used < props.dashboard.credit_total ? <Button href="/categories" className='btn-getcredit-line'>Add Category</Button> :
                      <Button className='btn-getcredit-line'>Upgrade</Button>}
                  </div>
                  <div className='signbox-wrap storage'>
                    <h2>Products</h2>
                    {props.dashboard.product !== "" && props.dashboard.product !== null ?
                      <div className='sign-text'>
                        <h4>{props.dashboard.product_used} / {props.dashboard.product_total} <span>Products</span></h4>
                      </div> : ""}
                    <div className='stepline-wrap'>
                      <ProgressBar now={getCount(props.dashboard.product_used, props.dashboard.product_total)} />
                    </div>
                    {props.dashboard.product_used < props.dashboard.product_total ? <Button href="/content" className='btn-getcredit-line'>Add Product</Button> :
                      <Button className='btn-getcredit-line'>Upgrade</Button>}
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div className='projectlist-wrap'>
          <Container>
            <Row>
              <Col md={8} >
                <div className='project-title'>
                  <h6>My Product List</h6>
                  <div class="mobile-note">
                    <img src={infoIcon} />
                    <p>For a better expereience use the desktop version to manage more than 10 SKUs</p>

                  </div>
                </div>
              </Col>
              <Col md={4} >
                <div className='project-title'>
                  <Form>
                    <Form.Group className="mb-3">
                      <Form.Control onChange={props.handleChange} name="search" type="text" placeholder="Search for products" className="p-search"  value={props.search}/>
                    </Form.Group>
                  </Form>
                </div>

              </Col>
            </Row>
          </Container>
        </div>
        <div className='project-tbl-wrap'>
          <div className='tblinner'>
            <Table hover>
              <thead>
                <tr>
                  <th>Category Name</th>
                  <th>File Name</th>
                  <th>Creator</th>
                  <th><div className='sortingwrap'>
                    <h6>Last Modified </h6>
                    <Dropdown>
                      <Dropdown.Toggle id="dropdown-basic" className='sorting'>
                        <img src={sort} class="normal" />
                        <img src={sortactive} class="active" />
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item href="#/action-1">Today</Dropdown.Item>
                        <Dropdown.Item href="#/action-2">Last 7 Days</Dropdown.Item>
                        <Dropdown.Item href="#/action-3">Last 30 Days</Dropdown.Item>
                        <Dropdown.Item href="#/action-4">This year (2023)</Dropdown.Item>
                        <Dropdown.Item href="#/action-5">Last year (2022)</Dropdown.Item>
                        <Dropdown.Item href="#/action-6">Custom date range <img src={calender} /></Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                {props.dashboard.products.map((product) => (
                  <tr className="project-details" onClick={() => props.handleContentWriter(product.id)}>
                    <td>{product.category}</td>
                    <td>{product.name}</td>
                    <td>{product.user}</td>
                    <td>

                      {Moment(product.date).format('MMMM DD. YYYY')}
                      
                        <Button className='optionsbtn'>
                          <img
                            src={optionsbtn}
                            className="optionbtn"
                            alt="" 
                          />
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>

        </div>
        <div className='project-list-mobile'>

          {props.dashboard.products.map((product) => (
            <div className='pl-mobile-box'>
              <Button className='options'></Button>
              <div className='pl-mobile-box-row'>
                <h4>Category Name</h4>
                <h5>{product.category}</h5>
              </div>
              <div className='pl-mobile-box-row'>
                <h4>File Name</h4>
                <h5>{product.name}</h5>
              </div>
              <div className='pl-mobile-box-row'>
                <h4>Creator</h4>
                <h5>{product.user}</h5>
              </div>
              <div className='pl-mobile-box-row'>
                <h4>Last Modified
                  <Dropdown>
                    <Dropdown.Toggle id="dropdown-basic" className='sorting'>
                      <img src={sort} class="normal" />
                      <img src={sortactive} class="active" />
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item href="#/action-1">Today</Dropdown.Item>
                      <Dropdown.Item href="#/action-2">Last 7 Days</Dropdown.Item>
                      <Dropdown.Item href="#/action-3">Last 30 Days</Dropdown.Item>
                      <Dropdown.Item href="#/action-4">This year (2023)</Dropdown.Item>
                      <Dropdown.Item href="#/action-5">Last year (2022)</Dropdown.Item>
                      <Dropdown.Item href="#/action-6">Custom date range <img src={calender} /></Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </h4>
                <h5>{Moment(product.date).format('MMMM DD. YYYY')}</h5>
              </div>
            </div>
          ))}
        </div>

        <div className='loadmore-wrap'>
          {props.dashboard.products.length > 3 ?
            <h6>Load more</h6> : null}
          <a className='scroll-top' href='#top'></a>
        </div>
      </div>
      <Footer />
    </div>
  );
};
