import React, { Fragment } from 'react'
import { Container, Row, Col, Button, Accordion, Tab, Tabs, Breadcrumb, Table, Form, FloatingLabel } from 'react-bootstrap'
import Header from "../assets/header-admin"
import Footer from "../assets/footer"
import Moment from 'moment'

export default function DashboardSettingsView(props) {
  return (
    <div>
      <Header />
      <div className='content-wrap- dash'>
        <Container>
          <Row>
            <Col md={12}>
              <div className='dash-content reports'>
                <div className='breadscrumb-wrap'>
                  <Breadcrumb>
                    <Breadcrumb.Item>Dashboard</Breadcrumb.Item>
                    <Breadcrumb.Item active>Settings</Breadcrumb.Item>
                  </Breadcrumb>
                </div>
                <h2>Back Office</h2>
                <div className='quick-info-bar'>
                  <span className='mailcheck'>{props.profile.email}</span>
                  <span>ID: {props.profile.id}</span>
                  <span>Registration Date: {Moment(props.profile.created_at).format('MMMM DD. YYYY')} </span>
                </div>
                <div className='reports-wrap settings'>
                  <div className='pk-accordion-wrap'>
                    <Accordion>
                      <Accordion.Item eventKey="0">
                        <Accordion.Header>User Management</Accordion.Header>
                        <Accordion.Body>
                          <div className='settings'>
                            <Tabs
                              activeKey={props.activeKey}
                              onClick={props.handleActiveKey}
                              className="mb-3"
                            >
                              <Tab eventKey="allusers" title="All Users">
                                <div className='setting-field-wrap tbl usermanage'>
                                  <Row>
                                    <Col md={12}>
                                      <Table hover>
                                        <thead>
                                          <tr>
                                            <th>Select</th>
                                            {/* <th>Username</th> */}
                                            <th>Name</th>
                                            <th>Email</th>
                                            <th>Role</th>
                                            <th>Last Login</th>
                                            <th>Registered</th>
                                            <th>Edit</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {props.settings.users.map((user) => (
                                            <tr>
                                              <td>
                                                <Form>
                                                  <div key="checkbox" className="mb-3">
                                                    <Form.Check
                                                      type="checkbox"
                                                      id="default-checkbox"
                                                    />
                                                  </div>
                                                </Form>
                                              </td>
                                              {/* <td>
                                              <div className='user-image'>
                                                <img src={userunknown} />
                                                <h6>{user.username}</h6>
                                              </div>
                                            </td> */}
                                              <td>{user.first_name !== null ? user.first_name : ""} {user.last_name !== null ? user.last_name : ""} </td>
                                              <td>{user.email} </td>
                                              <td>{user.is_admin == "1" ? "Admin" : "Subscriber"}</td>
                                              <td>{Moment(user.updated_at).format('MM/DD/YYYY')}</td>
                                              <td>{Moment(user.created_at).format('MM/DD/YYYY')}</td>
                                              <td> <Button className='btn-edit' onClick={()=>props.handleEditUser(user.id)}></Button></td>
                                            </tr>
                                          ))}
                                        </tbody>
                                      </Table>
                                    </Col>
                                  </Row>
                                </div>
                              </Tab>
                              <Tab eventKey="addnew" title={props.id != 0 ? "Update" : "Add New"}>
                                <p className='note set'>All values are per month unless stated otherwise.</p>
                                <div className='setting-field-wrap setting'>
                                  <Row>
                                    <Col md={6}>
                                      <FloatingLabel  controlId="floatingPassword" label="Username">
                                        <Form.Control type="text" placeholder="" name="name" value={props.name} onChange={props.handleChange}/>
                                      </FloatingLabel>
                                    </Col>
                                    <Col md={6}>
                                      <FloatingLabel className={props.error.email !== null ? "error-field" : null} controlId="floatingPassword" label="Email">
                                        <Form.Control type="email" placeholder="" name="email" value={props.email} onChange={props.handleChange} />
                                        {props.error.email !== null ? <div className="validate"> <span> * {props.error.email} </span> </div> : ""}
                                      </FloatingLabel>
                                    </Col>
                                    <Col md={6}>
                                      <FloatingLabel className={props.error.first_name !== null ? "error-field" : null} controlId="floatingPassword" label="First Name">
                                        <Form.Control type="text" placeholder="" name="first_name" value={props.first_name} onChange={props.handleChange} />
                                        {props.error.first_name !== null ? <div className="validate"> <span> * {props.error.first_name} </span> </div> : ""}
                                      </FloatingLabel>
                                    </Col>
                                    <Col md={6}>
                                      <FloatingLabel controlId="floatingPassword" label="Last Name">
                                        <Form.Control type="text" placeholder="" name="last_name" value={props.last_name} onChange={props.handleChange} />
                                      </FloatingLabel>
                                    </Col>
                                    <Col md={6}>
                                      <FloatingLabel className={props.error.password !== null ? "error-field" : null} controlId="floatingPassword" label="Password">
                                        <Form.Control type="password" placeholder="" name="password" value={props.password} onChange={props.handleChange} />
                                        {props.error.password !== null ? <div className="validate"> <span> * {props.error.password} </span> </div> : ""}
                                      </FloatingLabel>
                                    </Col>
                                    {/* <Col md={6}>
                                      <Button variant="light" className='btn-generate'>Generate Password</Button>
                                    </Col> */}
                                    <Col md={6}>
                                      <FloatingLabel controlId="floatingSelect" label="Role">
                                        <Form.Select aria-label="Floating label select" name="role" value={props.role} onChange={props.handleChange}>
                                          <option value="0">Subscriber</option>
                                          <option value="1">Admin</option>
                                        </Form.Select>
                                      </FloatingLabel>
                                    </Col>
                                    <Col md={12}>
                                      <div className='notify'>
                                        <Form>
                                            <div key="checkbox" className="mb-3">
                                              <Form.Check
                                                checked={props.notification==1}
                                                onChange={props.handleChange}
                                                type="checkbox"
                                                name="notification"
                                                id="default-checkbox"
                                                label={`Send User Notification - Sends the new user an email about their account.`}
                                              />
                                            </div>
                                          <button type="button" class="btn-proeng btn btn-primary mt-4" onClick={props.addNewUser}>{props.id != 0 ? "Update" : "Add New"} User</button>
                                        </Form>
                                      </div>
                                    </Col>
                                  </Row>
                                </div>
                              </Tab>
                            </Tabs>
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="1">
                        <Accordion.Header>API Settings</Accordion.Header>
                        <Accordion.Body>
                          <div className='api-key-settings tbl usermanage'>
                            <Row>
                              <Col md={6}>
                                <FloatingLabel controlId="floatingPassword" label="Bearer token">
                                  <Form.Control type="text" placeholder="" name="bearer_token" value={props.bearer_token} onChange={props.handleChange} />
                                </FloatingLabel>
                              </Col>
                              
                              <Col md={6}>
                                <FloatingLabel controlId="floatingPassword" label="Base URL">
                                  <Form.Control type="text" placeholder="" name="base_url" value={props.base_url} onChange={props.handleChange} />
                                </FloatingLabel>
                              </Col>
                            </Row>
                          </div>
                          <Col md={12}>
                            <div className='notify'>
                              <Form>
                                <button type="button" class="btn-proeng btn btn-primary mt-4" onClick={props.updateAPIKey}>Update API Key</button>
                              </Form>
                            </div>
                          </Col>
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="2">
                        <Accordion.Header>Email</Accordion.Header>
                        <Accordion.Body>
                          <div className='setting-tabs'>
                            <p><a href="">Click Here </a>to manage email settings at Stripe.</p>
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="3">
                        <Accordion.Header>SMTP Settings</Accordion.Header>
                        <Accordion.Body>
                        <div className='api-key-settings tbl usermanage'>
                            <Row>
                              <Col md={6}>
                                <FloatingLabel controlId="floatingPassword" label="Server Address">
                                  <Form.Control type="text" placeholder="" />
                                </FloatingLabel>
                              </Col>
                              
                              <Col md={6}>
                                <FloatingLabel controlId="floatingPassword" label="Port">
                                  <Form.Control type="text" placeholder=""  />
                                </FloatingLabel>
                              </Col>
                            </Row>
                            <Row>
                              <Col md={6}>
                                <FloatingLabel controlId="floatingPassword" label="Username">
                                  <Form.Control type="text" placeholder="" />
                                </FloatingLabel>
                              </Col>
                              
                              <Col md={6}>
                                <FloatingLabel controlId="floatingPassword" label="Password">
                                  <Form.Control type="password" placeholder=""  />
                                </FloatingLabel>
                              </Col>
                            </Row>
                          </div>
                          <Col md={12}>
                            <div className='notify'>
                              <Form>
                                <button type="button" class="btn-proeng btn btn-primary mt-4">Update</button>
                              </Form>
                            </div>
                          </Col>
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="4">
                        <Accordion.Header>STRIPE Settings</Accordion.Header>
                        <Accordion.Body>
                        <div className='api-key-settings tbl usermanage'>
                            <Row>
                              <Col md={6}>
                                <FloatingLabel controlId="floatingPassword" label="Public Key">
                                  <Form.Control type="text" placeholder="" />
                                </FloatingLabel>
                              </Col>
                              
                              <Col md={6}>
                                <FloatingLabel controlId="floatingPassword" label="Secret Key">
                                  <Form.Control type="password" placeholder=""  />
                                </FloatingLabel>
                              </Col>
                            </Row>
                          </div>
                          <Col md={12}>
                            <div className='notify'>
                              <Form>
                                <button type="button" class="btn-proeng btn btn-primary mt-4">Update</button>
                              </Form>
                            </div>
                          </Col>
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      {<Footer />}
    </div>
  );
};
