import React, { PureComponent, Fragment } from "react"
import PropTypes from "prop-types"
import { compose } from "redux"
import { connect } from "react-redux"
import BoilerView from "../pages/boiler"
import "./style.css"

const mapStateToProps = (state) => ({
    
})

const mapDispatchToProps = dispatch => ({
    
})

class boiler extends PureComponent {

    constructor(props) {
        super(props)

        this.state = {
           
        }
    }  

    render() {
        return (<Fragment>
            <BoilerView
                {...this.props}
                {...this.state}       
            />
          
        </Fragment>)
    }
}

boiler.propTypes = {
    classes: PropTypes.object.isRequired
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(boiler)
