import React, { Fragment } from 'react'
import { Container, Row, Col, Button, Accordion } from 'react-bootstrap'
import faqimage from "../assets/img/faq-image.png"
import faqimagemobile from "../assets/img/faq-image-mobile.png"
import Header from "../assets/header"
import Footer from "../assets/footer"
import { Helmet } from "react-helmet"

export default function FaqView(props) {
  return (
    <div>
      <Header />
      <Helmet>
        <meta charSet="utf-8" />
        <title>{props.meta_title}</title>
        <meta name="description" content={props.meta_description} />
        <meta property="og:title" content={props.meta_title} />
        <meta property="og:type" content="article" />
        <meta property="og:url" content={props.url} />
      </Helmet>
      <div className='content-wrap gray'>
        <div className='faq-header'>
          <Container>
            <Row>
              <Col lg={6} md={12}>
                <div className='f-header col1'>
                  <h1>Find the answers to all of our most <br />frequently asked questions.</h1>
                  <p>Stuck on something? We’re here to help with all your questions and answers in one place</p>
                </div>
              </Col>
              <Col lg={6} md={12}>
                <div className='f-header col2'>
                  <div className='fh-head-info'>
                    <Row>
                      <Col md={3}>
                        <img
                          src={faqimage}
                          className="d-inline-block align-top dk"
                          alt=""
                        />
                        <img
                          src={faqimagemobile}
                          className="d-inline-block align-top mobile"
                          alt=""
                        />
                      </Col>
                      <Col md={9}>
                        <div className='headblock'>
                          <h2>Simplify All Your Content Generation Needs</h2>
                          <p>Get started with eProdx today for FREE</p>
                          <Button className='btn-getstart' href="/register">Get Started</Button>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div className='faqbox-wrap'>
          <div className='faqsection1'>
            <Container>
              <Row className='align-items-center mb-4'>
                <Col md={7}>
                  <h4>Frequently Asked Questions</h4>
                </Col>
                <Col md={5}>
                  <p className='updatetime'>Last Updated:  {props.modified_date}</p>
                </Col>
              </Row>
            </Container>
          </div>
          <div className='faqsection2'>
            <Container>
              <Row>
                <Col lg={3} md={4}>
                  <div className='faq-left-column'>
                    <Accordion activeKey={props.active_key} flush>
                      {props.faq.length > 0 ? props.faq.map((faq, index) => (
                        <Accordion.Item eventKey={index} onClick={() => props.handleFaq(faq, index)}>
                          <Accordion.Header>{faq.title}</Accordion.Header>
                          <Accordion.Body>
                            <Button className='btn-faq act'>Overview</Button>
                          </Accordion.Body>
                        </Accordion.Item>
                      )) : ""}
                    </Accordion>
                  </div>
                </Col>
                <Col lg={9} md={8}>
                  <div className='faq-right-column'>
                    <h4>{props.title}</h4>
                    <h5>Overview</h5>
                    <div className='mainfaq'>
                      <Accordion defaultActiveKey={props.key + "over" + 0}>
                        {props.overview !== null && props.overview.length > 0 ? props.overview.map((overview, index) => (
                          <Accordion.Item eventKey={props.key + "over" + index}>
                            <Accordion.Header> {overview.question} </Accordion.Header>
                            <Accordion.Body>
                              <p>{overview.answer}</p>
                            </Accordion.Body>
                          </Accordion.Item>
                        )) : ""}
                      </Accordion>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </div>
      {<Footer />}
    </div>
  );
};
