import React, { PureComponent } from "react"
import { compose } from "redux"
import { connect } from "react-redux"
import { Nav, Navbar, NavDropdown, Container, Button } from 'react-bootstrap'
import { headerLogo, infoIcon } from "./img/images"
import { logout } from "../hooks/login"
import Page from "./page"

const mapStateToProps = (state) => ({
  login: state.login
})

const mapDispatchToProps = dispatch => ({
  logout: () => dispatch(logout())
})

class Header extends PureComponent {

  constructor(props) {
    super(props)

    this.state = {

    }
  }


  render() {
    return (<Navbar expand="lg" id="main-navbar" className="navbar">
      <Container>
        <div className="navwrap" >
          <Navbar.Brand href="/">
            <img
              src={headerLogo}
              className="d-inline-block align-top"
              alt=""
            />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="">
              <Nav.Link href="/">Home</Nav.Link>
              <Nav.Link href="/about-us">About</Nav.Link>
              <Nav.Link href="/faq" className={(this.props.page == "faq" ? "page-name" : null)}>FAQ</Nav.Link>
              <Nav.Link href="https://eprodx.ai/blog/">Blog</Nav.Link>
              {/* <Nav.Link href="">Features</Nav.Link>
              <Nav.Link href="">Pricing</Nav.Link>
              <Nav.Link href="">Resources</Nav.Link>
              <Nav.Link href="/boiler">About</Nav.Link>  */}
            </Nav>
          </Navbar.Collapse>
          {!this.props.login.loggedIn ?
            <div class="top-btn">
              <Button variant="" className="btn-sign in" href="/login">Sign On</Button>{' '}
              <Button variant="" className="btn-sign up" href="/register">Sign Up</Button>{' '}
            </div> :
            <div class="profile-submenu">
              <NavDropdown title={this.props.login.user !== undefined && this.props.login.user !== null ? this.props.login.user.name : null} id="basic-nav-dropdown">
                <NavDropdown.Item href="/dashboard">My projects</NavDropdown.Item>
                <NavDropdown.Item className="ps" href="/dashboardprofile">Profile Settings</NavDropdown.Item>
                <NavDropdown.Item className="si" href="/dashboard/profile/subscription">Subscription Information</NavDropdown.Item>
                <NavDropdown.Item className="um">User Management</NavDropdown.Item>
                <NavDropdown.Item onClick={this.props.logout}>Sign Off</NavDropdown.Item>
              </NavDropdown>
            </div>}
        </div>
      </Container>
      <Page />
      <div class="mobile-note">
        <img src={infoIcon} />
        <p>For a better expereience use the desktop version to manage more than 10 SKUs</p>
      </div>
    </Navbar>)
  }
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(Header)
