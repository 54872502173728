import React, { PureComponent, Fragment } from "react"
import { Navigate } from 'react-router-dom'
import { compose } from "redux"
import { connect } from "react-redux"
import OneTimePassword from "../pages/otp"
import { requestOTP, verifyOtp } from "../hooks/login"
import { SnackBar } from "../hooks/common"

const mapStateToProps = (state) => ({
    login: state.login,
})

const mapDispatchToProps = dispatch => ({
    SnackBar: (show, message, varient) => dispatch(SnackBar(show, message, varient)),
    requestOTP: (email) => dispatch(requestOTP(email)),
    verifyOtp: (data, backUp) => dispatch(verifyOtp(data, backUp))
})

const get_direction = (register) => {
    if (register.email == undefined || register.email == null) {
        return true
    }

    return false
}

const getValidate = (state) => {
    var otp = null
    var validate = true

    if (state.otp == "") {
        otp = "Add the OTP"
        validate = false
    } else if (state.otp.length != 6) {
        otp = "OTP need to be in 6 digit"
        validate = false
    }
    return { data: { otp: otp }, validate: validate }
}


class otpContainer extends PureComponent {

    constructor(props) {
        super(props)

        this.state = {
            otp: "",
            email: "",
            error: {
                otp: null
            }
        }
    }

    componentDidMount = () => {
        if (this.state.email == "" && this.props.login.otp_mail != null) {
            this.setState({ email: this.props.login.otp_mail })
            this.props.requestOTP(this.props.login.otp_mail)
        }
    }

    componentDidUpdate = () => {
        if (this.props.login.loggedIn && this.props.login.user_pack == 1) {
            this.setState({ logged_in: true })
        }
    }

    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value,
            error: {
                ...this.state.error,
                [e.target.name]: null
            }
        })
    }
    handleSubmit = () => {
        if (getValidate(this.state).validate) {
            this.props.verifyOtp({
                "email": this.props.login.otp_mail,
                "otp": parseInt(this.state.otp)
            }, this.props.login.otp_back)
        } else {
            this.setState({
                error: getValidate(this.state).data
            })
        }
    }
    sendOtp = () => {
        this.props.requestOTP(this.props.login.otp_mail)
    }

    render() {
        return (<Fragment>
            <OneTimePassword
                {...this.props}
                {...this.state}
                handleChange={this.handleChange}
                handleSubmit={this.handleSubmit}
                sendOtp={this.sendOtp}
            />

            {this.state.logged_in ? <Navigate to="/dashboard" replace={true} /> : null}
        </Fragment>)
    }
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(otpContainer)
