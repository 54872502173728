import React, { PureComponent, Fragment } from "react"
import PropTypes from "prop-types"
import { compose } from "redux"
import { connect } from "react-redux"
import ThankYou from "../pages/thanx"
import { getUsersById } from "../hooks/login"
import "./style.css"


const mapStateToProps = (state) => ({
    auth: state.login.auth
})

const mapDispatchToProps = dispatch => ({
    getUsersById: (auth)=>dispatch(getUsersById(auth))
})

class Home extends PureComponent {

    constructor(props) {
        super(props)

        this.state = {
            log_update:true
        }
    }
    componentDidMount=()=>{
        if(this.state.log_update&&this.props.auth!==null) {
            this.props.getUsersById(this.props.auth)
            this.setState({
                log_update: false
            })
        }
    }

    render() {
        return (<Fragment>
            <ThankYou
                {...this.props}
                {...this.state}
            />
        </Fragment>)
    }
}

Home.propTypes = {
    classes: PropTypes.object.isRequired
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(Home)
