import React, { Fragment } from 'react'
import { Container, Row, Col, Button, Form, Table, Accordion } from 'react-bootstrap'
import inroimage from "../assets/img/introimg1.png"
import engageimage from "../assets/img/engageimage.png"
import Header from "../assets/header"
import Footer from "../assets/footer"

export default function Home(props) {

  return (
    <div>
      <Header />
      <div className='content-wrap'>
        <section className='introwrap'>
          <Container>
            <Row>
              <Col md={6} className='h-order1'>
                <div className='intro-text'>
                  <h1>Transform Your Product Descriptions with AI-Powered Writing</h1>
                  <p>Our AI-powered writing solution revolutionizes the way you generate product descriptions. Say goodbye to tedious manual writing and limited automated options. With our advanced technology, creating captivating content becomes effortless, saving you time and resources.</p>
                  <Button href="#" className='btn-get-start'>Get Started</Button>
                </div>
              </Col >
              <Col md={6} className='h-order2'>
                <div className='inro-image'>
                  <img
                    src={inroimage}
                    className="d-inline-block img-fluid"
                    alt=""
                  />
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        <section className='content-rows-wrap'>
          <Container>
            <Row>
              <Col md={12}>
                <div className='content-rows'>
                  <Col md={12}>
                    <div className='content-rows-image'>
                      <img
                        src={engageimage}
                        className="d-inline-block img-fluid"
                        alt=""
                      />
                    </div>
                    <div className='content-rows-inner'>
                      <h3>Elevate Your Brand With Engaging Content</h3>
                      <p>Our AI Content Writer revolutionizes the way you create content. Say goodbye to generic product descriptions and hello to compelling, high-quality content that captures your brand's essence. Our advanced algorithms generate tailored, engaging narratives that resonate with your audience, driving increased conversions and customer loyalty.
                      </p>
                      <p>Our AI Content Writer revolutionizes the way you create content. Say goodbye to generic product descriptions and hello to compelling, high-quality content that captures your brand's essence. Our advanced algorithms generate tailored, engaging narratives that resonate with your audience, driving increased conversions and customer loyalty.</p>
                    </div>
                  </Col>
                </div>
                <div className='content-rows'>
                  <Col md={12}>
                    <div className='content-rows-image'>
                      <img
                        src={engageimage}
                        className="d-inline-block img-fluid"
                        alt=""
                      />
                    </div>
                    <div className='content-rows-inner'>
                      <h3>Elevate Your Brand With Engaging Content</h3>
                      <p>Our AI Content Writer revolutionizes the way you create content. Say goodbye to generic product descriptions and hello to compelling, high-quality content that captures your brand's essence. Our advanced algorithms generate tailored, engaging narratives that resonate with your audience, driving increased conversions and customer loyalty.
                      </p>
                      <p>Our AI Content Writer revolutionizes the way you create content. Say goodbye to generic product descriptions and hello to compelling, high-quality content that captures your brand's essence. Our advanced algorithms generate tailored, engaging narratives that resonate with your audience, driving increased conversions and customer loyalty.</p>
                    </div>
                  </Col>
                </div>
                <div className='content-rows'>
                  <Col md={12}>
                    <div className='content-rows-image'>
                      <img
                        src={engageimage}
                        className="d-inline-block img-fluid"
                        alt=""
                      />
                    </div>
                    <div className='content-rows-inner'>
                      <h3>Elevate Your Brand With Engaging Content</h3>
                      <p>Our AI Content Writer revolutionizes the way you create content. Say goodbye to generic product descriptions and hello to compelling, high-quality content that captures your brand's essence. Our advanced algorithms generate tailored, engaging narratives that resonate with your audience, driving increased conversions and customer loyalty.
                      </p>
                      <p>Our AI Content Writer revolutionizes the way you create content. Say goodbye to generic product descriptions and hello to compelling, high-quality content that captures your brand's essence. Our advanced algorithms generate tailored, engaging narratives that resonate with your audience, driving increased conversions and customer loyalty.</p>
                    </div>
                  </Col>
                </div>
                <div className='content-rows'>
                  <Col md={12}>
                    <div className='content-rows-image'>
                      <img
                        src={engageimage}
                        className="d-inline-block img-fluid"
                        alt=""
                      />
                    </div>
                    <div className='content-rows-inner'>
                      <h3>Elevate Your Brand With Engaging Content</h3>
                      <p>Our AI Content Writer revolutionizes the way you create content. Say goodbye to generic product descriptions and hello to compelling, high-quality content that captures your brand's essence. Our advanced algorithms generate tailored, engaging narratives that resonate with your audience, driving increased conversions and customer loyalty.
                      </p>
                      <p>Our AI Content Writer revolutionizes the way you create content. Say goodbye to generic product descriptions and hello to compelling, high-quality content that captures your brand's essence. Our advanced algorithms generate tailored, engaging narratives that resonate with your audience, driving increased conversions and customer loyalty.</p>
                    </div>
                  </Col>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        <section className='peopleask'>
          <Container>
            <Row>
              <Col md={12}>
                <div className='faq-col1'>
                  <h5>People Also Ask</h5>
                </div>
              </Col>
              <Col md={6}>
                <div className='faq-col2 default'>
                  <Accordion defaultActiveKey="0">
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>Q. Is there a free trial available?</Accordion.Header>
                      <Accordion.Body>
                        Yes, you can try us out for 15 days, and we’ll provide you with a personalized onboarding to get you up and running.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                      <Accordion.Header>Q. Can I change my plan later?</Accordion.Header>
                      <Accordion.Body>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                        eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                        minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                        aliquip ex ea commodo consequat. Duis aute irure dolor in
                        reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                        pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                        culpa qui officia deserunt mollit anim id est laborum.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2">
                      <Accordion.Header>Q. How does billing work?</Accordion.Header>
                      <Accordion.Body>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                        eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                        minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                        aliquip ex ea commodo consequat. Duis aute irure dolor in
                        reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                        pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                        culpa qui officia deserunt mollit anim id est laborum.
                      </Accordion.Body>
                    </Accordion.Item>                    
                  </Accordion>
                </div>
              </Col>
              <Col md={6}>
                <div className='faq-col2 default'> 
                 <Accordion defaultActiveKey="0">                 
                    <Accordion.Item eventKey="4">
                      <Accordion.Header>Q. How does billing work?</Accordion.Header>
                      <Accordion.Body>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                        eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                        minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                        aliquip ex ea commodo consequat. Duis aute irure dolor in
                        reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                        pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                        culpa qui officia deserunt mollit anim id est laborum.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="5">
                      <Accordion.Header>Q. How does billing work?</Accordion.Header>
                      <Accordion.Body>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                        eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                        minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                        aliquip ex ea commodo consequat. Duis aute irure dolor in
                        reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                        pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                        culpa qui officia deserunt mollit anim id est laborum.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="6">
                      <Accordion.Header>Q. How does billing work?</Accordion.Header>
                      <Accordion.Body>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                        eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                        minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                        aliquip ex ea commodo consequat. Duis aute irure dolor in
                        reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                        pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                        culpa qui officia deserunt mollit anim id est laborum.
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </div>
              </Col>
            </Row>
          </Container>
        </section>

        <section className='hm-contact-wrap exgap'>
          <Container>
            <Row>
              <Col md={6}>
                <div className='contact-col1'>
                  <h3>Contact US</h3>
                  <h4>We 'd love to hear more from you! Tell us what you have in mind and we’ll get back to you</h4>
                </div>
              </Col>
              <Col md={6}>
                <div className='contact-col2'>
                  <Form>
                    <Form.Group className="mb-3" controlId="">
                      <Form.Control type="text" placeholder="Your Name" />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="">
                      <Form.Control type="text" placeholder="Business Name" />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="">
                      <Form.Control type="email" placeholder="Email" />
                    </Form.Group>
                    <Form.Group className="mb-4" controlId="">
                      <Form.Control as="textarea" rows={2} placeholder="Message" />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="">
                      <Button type="submit" className='btn-showall'>Send</Button>
                    </Form.Group>
                  </Form>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </div>
      {<Footer />}
    </div>
  );
};
