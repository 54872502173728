import React, { PureComponent, Fragment } from "react"
import { Navigate } from 'react-router-dom'
import PropTypes from "prop-types"
import { compose } from "redux"
import { connect } from "react-redux"
import ProfileView from "../pages/dashboardprofile"
import countries from '../assets/common/countries.json'
import { getUsers, stopUpdate, saveAccountInfo, passwordUpdate } from "../hooks/profile"
import "./style.css"

const mapStateToProps = (state) => ({
    profile: state.login.user,
    updated: state.login.update_profile,
    login: state.login
})

const mapDispatchToProps = dispatch => ({
    getUsers: () => dispatch(getUsers()),
    stopUpdate: () => dispatch(stopUpdate()),
    saveAccountInfo: (data) => dispatch(saveAccountInfo(data)),
    passwordUpdate: (data) => dispatch(passwordUpdate(data))
})

const validatePassword = (data) => {
    var current_password = null
    var new_password = null
    var validate =true
    if (data.current_password === data.new_password) {
        new_password = "New password and current password can not be same"
        validate = false
    }
    if(data.current_password == "") {
        current_password = "Required"
        validate = false
    }
    if(data.new_password == "") {
        new_password = "Required"
        validate = false
    }
    return {data:{ current_password: current_password, new_password: new_password },validate:validate}
}

const getValidate = (data) => {
    console.log(state,"adasdasdsadad")
    var full_name = null
    var email = null
    var state = null 
    var validate =true
    
    if (data.full_name == "") {
        full_name = "Required"
        validate = false
    }
    if (data.email == "") {
        email = "Required"
        validate = false
    }
    else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(data.email)) {
        email = "invalid email address"
        validate = false
    }
    if(data.country !== "" && data.state == "") {
        state = "Required"
        validate = false
    }
    return {data:{ email: email, full_name: full_name, state:state },validate:validate}
}

class Dashboardprofile extends PureComponent {

    constructor(props) {
        super(props)

        this.state = {
            save: false,
            pass_save: false,
            full_name: "",
            company: "",
            email: "",
            phone: "",
            state: "",
            country: "",
            current_password: "",
            new_password: "",
            state_coll: [],
            two_factor_status:0,
            error: {
                full_name: null,
                email: null,
                state: null
            },
            error_pass: {
                current_password: null,
                new_password: null
            }
        }
        this.props.getUsers()
    }

    componentDidUpdate() {
        if (this.props.profile !== null && this.props.updated) {
            this.props.stopUpdate()
            this.setState({
                save: false,
                pass_save: false,
                full_name: this.props.profile.name,
                company: this.props.profile.business_name,
                email: this.props.profile.email,
                phone: this.props.profile.phone,
                state: this.props.profile.province,
                country: this.props.profile.country,
                two_factor_status:this.props.profile.two_factor_status
            })
        }
        if (this.state.country !== "" && this.state.state_coll.length == 0) {
            var country = countries.find(obj => { return obj.code3 === this.state.country })
            this.setState({
                state_coll: country != null ? country.states : ""
            })
        }
    }

    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value,
            error: {
                ...this.state.error,
                [e.target.name]: null
            }
        })
        // , () => this.setState({ save: getValidate(this.state).validate })
    }

    cancelAccountInfo = () => {
        this.setState({
            // save: false,
            full_name: this.props.profile.name,
            company: this.props.profile.company,
            email: this.props.profile.email,
            phone: this.props.profile.phone,
            state: "",
            country: "",
            state_coll: [],
            two_factor_status:0
        })
    }
    saveAccountInfo = () => {
        if(getValidate(this.state).validate) {
            this.props.saveAccountInfo({
                name: this.state.full_name,
                business_name: this.state.company,
                email: this.state.email,
                phone: this.state.phone,
                province: this.state.state,
                country: this.state.country,
                two_factor_status: this.state.two_factor_status
            })
        }else {
            this.setState({
                error: getValidate(this.state).data
            })
        }
    }

    handleCountry = (event) => {
        var country = countries.find(obj => { return obj.code3 === event.target.value })
        this.setState({
            state: "",
            country: event.target.value,
            state_coll: country.states,
            // save: false
        })
    }

    handlePasswordChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value,
            error_pass: {
                ...this.state.error_pass,
                [e.target.name]: null
            }
        })
    }
    savePaswword = () => {
        if (validatePassword(this.state).validate) {
            this.props.passwordUpdate({
                current_password: this.state.current_password,
                new_password: this.state.new_password
            })
        } else {
            this.setState({
                error_pass: validatePassword(this.state).data
            })
        }
    }
    handleTwoFactor =(e)=> {
        this.setState({
            two_factor_status:e.target.checked?1:0
        },()=>this.saveAccountInfo())
    }

    clearData= (event)=> {
        this.setState({
            [event.target.name]: ""
        })
    }


    render() {
        return (
            <Fragment>
                {this.props.login.auth != null?
                <ProfileView
                    {...this.props}
                    {...this.state}
                    handleChange={this.handleChange}
                    cancelAccountInfo={this.cancelAccountInfo}
                    saveAccountInfo={this.saveAccountInfo}
                    handleCountry={this.handleCountry}
                    handlePasswordChange={this.handlePasswordChange}
                    savePaswword={this.savePaswword}
                    handleTwoFactor={this.handleTwoFactor}
                    clearData={this.clearData}
                />:""}
                {this.props.login.auth == null ? <Navigate to="/login" replace={true} /> : null}
            </Fragment>)
    }
}

Dashboardprofile.propTypes = {
    classes: PropTypes.object.isRequired
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(Dashboardprofile)
