export default function reducer(state = {
    package_list: [],
    package: null,
    editPackage: null,
    editPackageUpdate: false,
    package_step: false
}, action) {
    switch (action.type) {
        case "STORE_PACKEGES": {
            return { ...state, package_list: action.data, package_step: true }
        }
        case "STORE_EDIT_PACKEGES": {
            return { ...state, editPackage: action.data, editPackageUpdate: true }
        }
        case "STOP_UPDATE_DASHBOARD_PACKAGE": {
            return { ...state, editPackageUpdate: false }
        }
        case "STOP_PACKAGE_STEP": {
            return { ...state, package_step: false }
        }
        case "PACKAGE_STATUS_UPDATE": {
            return {
                ...state,
                package_list: Object.values({
                    ...state.package_list,
                    [action.data.index]: {
                        ...state.package_list[action.data.index],
                        "status": action.data.status
                    }
                })
            }
        }
        default:
            break;
    }
    return state;
}