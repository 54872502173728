import { API_URL } from "../Constants"
import { startLoading, stopLoading, SnackBar } from "./common"
import store from "../store"

export function storePromptEngneer(data) {
    return { type: "STORE_PROMPT_ENGNEERING", data };
}
export function prompt_engneering(type) {
    return function (dispatch) {
        dispatch(startLoading())
        dispatch(SnackBar(true, 'Loading....', 'info'))
        fetch(API_URL + "api-settings?type=" + type, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json;charset=utf-8',
                'Authorization': 'Bearer ' + store.getState().login.auth
            }
        }).then(response => response.json())
            .then((json) => {
                dispatch(storePromptEngneer(json))
                dispatch(stopLoading())
            })
            .catch((error) => {
                dispatch(SnackBar(true, "Connection error", 'error'))
            })
    }
}
export function storeModeles(data) {
    return { type: 'STORE_MODELS', data };
}
export function getModels() {
    return function (dispatch) {
        dispatch(startLoading())
        fetch("https://api.openai.com/v1/models", {
            method: "GET",
            headers: {
                'Content-Type': 'application/json;charset=utf-8',
                'Authorization': 'Bearer ' + "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"
            }
        }).then(response => response.json())
            .then((json) => {
                dispatch(storeModeles(json.data))
                dispatch(stopLoading())
            })
            .catch((error) => {
                dispatch(SnackBar(true, "Connection error dashboard", 'error'))
            })
    }
}
export function changeVariable(name, value) {
    return { type: 'CHANGE_VARIABLE_PROMPT_ENGNEERING', name, value };
}
export function handleSave(data) {
    return function (dispatch) {
        dispatch(startLoading())
        fetch(API_URL + "generate", {
            method: "POST",
            headers: {
                "Content-Type": "application/json;charset=utf-8",
                'Authorization': 'Bearer ' + store.getState().login.auth
            },
            body: JSON.stringify(data.input)
        }).then(response => response.json())
            .then(() => {
                dispatch(storeModeles(data.mode))
                dispatch(stopLoading())
            })
            .catch((error) => {
                dispatch(SnackBar(true, "Connection error dashboard", 'error'))
            })
    }
}

export function handleUpdatePrompt(data) {
    return function (dispatch) {
        dispatch(startLoading())
        fetch(API_URL + "update-api-settings", {
            method: "POST",
            headers: {
                "Content-Type": "application/json;charset=utf-8",
                'Authorization': 'Bearer ' + store.getState().login.auth
            },
            body: JSON.stringify(data)
        }).then(response => response.json())
            .then(() => {
                dispatch(prompt_engneering(data.type))
                dispatch(stopLoading())
            })
            .catch((error) => {
                dispatch(SnackBar(true, "Connection error dashboard", 'error'))
            })
    }
}

export function promptPage(page=1) {
    return { type: 'PROMPT_PAGE', page };
}
export function stopUpdatePrompt() {
    return { type: 'STOP_UPDATE_PROMPT' };
}
