import { useState } from 'react'
import { API_URL } from "../Constants"
import { docIcon, pdfIcon, xlsxIcon, csvIcon,iconClose } from "../assets/img/images"
import TitleTag from "./contentOut/title"
import Description from "./contentOut/description"
import FeatureBullet from "./contentOut/featureBullet"
import CloseStatement from "./contentOut/closeStatement"


export default function ContentOutput(props) {
  const [exp, setExp] = useState(false)
  function handlExport(type) {
     const URL = API_URL + type + "/" + props.id
     props.goTo(URL)
  }
  function openExport() {
     setExp(!exp)
  }

  return (
    <div className="output-detail-wrap" ref={props.bottomRef}>
    <div className="container">
       <div className="row">
          <div className="col-md-12">
             <div className="output-title-wrap">
                <h3>Output details</h3>
                <div className="drop-wrap">
                   <div onClick={openExport} className={"export-data-wrap" + (exp ? " open" : "")}>
                      <h6>Export data</h6>
                      <div className="drop-inner">
                         <button onClick={() => handlExport("generate-docx")}> <img src={docIcon} alt="" />Export as Docx</button>
                         <button onClick={() => handlExport("generate-pdf")}> <img src={pdfIcon} alt="" />Export as PDF</button>
                         <button onClick={() => handlExport("generate-excel")}> <img src={xlsxIcon} alt="" />Export as Excel</button>
                         <button onClick={() => handlExport("generate-csv")}> <img src={csvIcon} alt="" />Export as CSV</button>
                      </div>
                   </div>
                   <button onClick={props.clearData} className="close-btn"><img src={iconClose} alt="" />Close</button>
                </div>
             </div>
          </div>
       </div>
       <div className="row">
          <div className="col-md-12">
             <div className="outputrows">
                <div className="row">
                   <div className="col-md-4">
                      <div className="out-put-title">
                         <h3>Product ID</h3>
                      </div>
                   </div>
                   <div className="col-md-8">
                      <div className="out-put-description">
                         <h4>{props.content.searchValue.sku}</h4>
                      </div>
                   </div>
                </div>
             </div>
             {props.data.title !== undefined && props.data.title !== "" ?
                <div className="outputrows">
                   <div className="row">
                      <div className="col-md-4">
                         <div className="out-put-title">
                            <h3>Product Title</h3>
                         </div>
                      </div>
                      <div className="col-md-8">
                         {props.data.title.map((title, index) => (
                            <TitleTag title={title} regenerate={() => props.regenerate("title", index)} editOutput={(value) => props.editOutput("title", index, value)} />
                         ))}
                      </div>
                   </div>
                </div> : null}
             {props.data.description !== undefined && props.data.description !== ""  ?
                <div className="outputrows">
                   <div className="row">
                      <div className="col-md-4">
                         <div className="out-put-title">
                            <h3>Product Description</h3>
                         </div>
                      </div>
                      <div className="col-md-8">
                         {props.data.description.map((description, index) => (
                            <Description description={description} regenerate={() => props.regenerate("description", index)} editOutput={(value) => props.editOutput("description", index, value)} />
                         ))}
                      </div>
                   </div>
                </div>
                : null}
             {props.data.feature_bullet !== undefined && props.data.feature_bullet !== ""  ?
                <div className="outputrows">
                   <div className="row">
                      <div className="col-md-4">
                         <div className="out-put-title">
                            <h3>Feature Bullets</h3>
                         </div>
                      </div>
                      <div className="col-md-8">
                         <div className="checklist">

                            {props.data.feature_bullet.map((bullet, index) => (
                               <FeatureBullet bullet={bullet} regenerate={() => props.regenerate("feature_bullet", index)} editOutput={(value) => props.editOutput("feature_bullet", index, value)} />
                            ))}

                         </div>
                      </div>
                   </div>
                </div>
                : null}
             {props.data.close_statement !== undefined && props.data.close_statement !== ""  ?
                <div className="outputrows">
                   <div className="row">
                      <div className="col-md-4">
                         <div className="out-put-title">
                            <h3>Closing statement</h3>
                         </div>
                      </div>
                      <div className="col-md-8">
                         {props.data.close_statement.map((statement, index) => (
                            <CloseStatement statement={statement} regenerate={() => props.regenerate("close_statement", index)} editOutput={(value) => props.editOutput("close_statement", index, value)} />
                         ))}
                      </div>
                   </div>
                </div> : null}
          </div>
       </div>
    </div>
 </div>
  );
};
