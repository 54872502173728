export default function reducer(state = {
    users: [],
    api: {
        bearer_token: "520|ElTfAhIdeqNFnjO430Y7elN7mVWi42LtEz5lYHKD",
        base_url: "https://api.eprodx.ai/api/"
    },
    api_update: false,
    edit_user_details: null,
    edite_user_update: false,
    localUseState: false
}, action) {
    switch (action.type) {
        case "STORE_DASHBOARD_USERS": {
            return { ...state, users: action.data, localUseState: true }
        }
        case "API_SETTINGS_UPDATE": {
            return { ...state, api_update: action.bool }
        }
        case "SETTINGS_API_UPDATE": {
            return { ...state, api: action.data, api_update: true }
        }
        case "STORE_EDIT_USER_DETAILS": {
            return { ...state, edit_user_details: action.data, edite_user_update: true }
        }
        case "STOP_USER_UPDATE_LOCAL": {
            return { ...state, edite_user_update: false }
        }
        case "STOP_UPDATE_LOCAL_STATUS": {
            return { ...state, localUseState: false }
        }
        default:
            break;
    }
    return state;
}