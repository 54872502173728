import React, { useRef, useEffect } from 'react'
import { Container, Row, Col, Button, Form, FloatingLabel } from 'react-bootstrap'
import Header from "../assets/header"
import Footer from "../assets/footer"
import { Helmet } from "react-helmet"

export default function ContactUs(props) {
  const textAreaRef = useRef(null);

  const resizeTextArea = () => {
    textAreaRef.current.style.height = "auto";
    textAreaRef.current.style.height = textAreaRef.current.scrollHeight + "px";
  };
  useEffect(resizeTextArea, [props.msg]);

  return (
    <div>
      <Header />
      <Helmet>
        <meta charSet="utf-8" />
        <title>{props.title}</title>
        <meta name="description" content={props.meta_description} />
        <meta property="og:title" content={props.meta_title} />
        <meta property="og:type" content="article" />
        <meta property="og:url" content={props.url} />
      </Helmet>
      <div className='content-wrap'>
        <section className='hm-contact-wrap exgap'>
          <Container>
            <Row>
              <Col md={6}>
                <div className='contact-col1'>
                  <h3>Contact US</h3>
                  {props.home.data !== null ?
                    <h4>{props.home.data.contact_us[0]}</h4> : ""}
                </div>
              </Col>
              <Col md={6}>
                <div className='contact-col2'>
                  <Form>
                    <Form.Group className="mb-3" controlId="">
                      <FloatingLabel
                        controlId="floatingTextarea"
                        label="Your Name"
                        className={"mb-3 " + (props.error.name !== null ? "error-field" : null)}
                      >
                        <Form.Control type="text" placeholder="" name="name" value={props.name} onChange={props.handleContactDetails} />
                        {props.error.name !== null ? <div className="validate"> <span> * {props.error.name} </span> </div> : ""}
                      </FloatingLabel>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="">
                      <FloatingLabel
                        controlId="floatingTextarea"
                        label="Business Name"
                        className={"mb-3 " + (props.error.business !== null ? "error-field" : null)}
                      >
                        <Form.Control type="text" placeholder="" name="business" value={props.business} onChange={props.handleContactDetails} />
                        {props.error.business !== null ? <div className="validate"> <span> * {props.error.business} </span> </div> : ""}
                      </FloatingLabel>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="">
                      <FloatingLabel
                        controlId="floatingTextarea"
                        label="Email"
                        className={"mb-3 " + (props.error.email !== null ? "error-field" : null)}
                      >
                        <Form.Control type="email" placeholder="" name="email" value={props.email} onChange={props.handleContactDetails} />
                        {props.error.email !== null ? <div className="validate"> <span> * {props.error.email} </span> </div> : ""}
                      </FloatingLabel>
                    </Form.Group>
                    <Form.Group className="mb-4" controlId="">
                      <FloatingLabel
                        controlId="floatingTextarea"
                        label="Message"
                        className={"mb-3 " + (props.error.msg !== null ? "error-field" : null)}
                      >
                        <Form.Control ref={textAreaRef} className='text-area' as="textarea" rows={2} placeholder="" name="msg" value={props.msg} onChange={props.handleContactDetails} />
                        {props.msg.length > 0 ? <p className='text-area-count'>{props.msg.length}</p> : null}
                        {props.error.msg !== null ? <div className="validate"> <span> * {props.error.msg} </span> </div> : ""}
                      </FloatingLabel>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="">
                      <Button className='btn-showall' onClick={props.contactButonClick}>Send</Button>
                    </Form.Group>
                  </Form>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </div>
      {<Footer />}
    </div>

  );
};
