import React, { useEffect } from 'react'
import { Button, Form, FloatingLabel } from 'react-bootstrap'
import { headerLogo } from "../assets//img/images"
//import GoogleLogin from 'react-google-login'
import { PublicClientApplication } from '@azure/msal-browser'
import { Client } from '@microsoft/microsoft-graph-client'
import { config } from "../config"
import ReCAPTCHA from "react-google-recaptcha"
import { gapi } from "gapi-script"

const pca = new PublicClientApplication({
  auth: {
    clientId: "7521b28a-938a-409d-ab5c-912345b523b1",
    authority: "https://login.microsoftonline.com/common",
    redirectUri: "https://eprodx.ai/register"
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: true
  }
})


export default function Home(props) {
  const clientId = "1013186389260-cf272chdrb5uhsi6a3p3e6ei9f6dkddh.apps.googleusercontent.com"

  useEffect(() => {
    function start() {
      gapi.client.init({
        clientId: clientId,
        scope: 'email',
      });
    }
    gapi.load('client:auth2', start);
  }, []);

  
  const successGoogle = (response) => {
    let { accessToken } = response;
    var profile = response.getBasicProfile()
    props.socialLogin({
      "first_name": profile.getGivenName(),
      "last_name": profile.getFamilyName(),
      "email": profile.getEmail(),
      "login_type": "google",
      "login_id": profile.getId(),
      "token": accessToken
    })
  }
  const failGoogle = (response) => {
    console.log(response, "failGoogle");
  }

  const handleLogin = async () => {
    try {
      await pca.initialize()
      const loginResponse = await pca.loginPopup({
        scopes: config.scopes
      });
      // Retrieve access token
      const accessTokenResponse = await pca.acquireTokenSilent({
        account: loginResponse.account,
        scopes: config.scopes
      });

      // Create Graph client
      const graphClient = Client.init({
        authProvider: (done) => {
          done(null, accessTokenResponse.accessToken);
        }
      });

      // Call Microsoft Graph API to get user profile
      const userProfile = await graphClient.api('/me').get();
      props.socialLogin({
        "first_name": userProfile.givenName,
        "last_name": userProfile.surname,
        "email": userProfile.mail,
        "login_type": "microsoft",
        "login_id": userProfile.id
      })
    } catch (error) { console.log(error); }
  }

  return (
    <div>
      <div className='content-wrap login register'>
        <div className="home-redirect">
          <a className='b-link' href='/'>X</a>
        </div>
        <div className='login-wrap'>
          <div className='loginbox'>
            <div className='loginwrap-inner'>
              <div className='login-logo'>
                <img
                  src={headerLogo}
                  className="d-inline-block img-fluid"
                  alt=""
                />
              </div>
              <h5>Register for a new eProdx Account</h5>
              <div className='loginbox-form'>
                <Form>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="First name"
                    className="mb-3"  >
                    <Form.Control type="text" placeholder="" value={props.first_name} name="first_name" onChange={props.handleChange} />
                    {props.error.first_name !== null ? <div className="validate"> <span> * {props.error.first_name}</span></div> : ""}
                    </FloatingLabel>
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Last name"
                    className="mb-3"  >
                    <Form.Control type="text" placeholder="" value={props.last_name} name="last_name" onChange={props.handleChange} />
                    {props.error.last_name !== null ? <div className="validate"> <span> * {props.error.last_name} </span> </div> : ""}
                    </FloatingLabel>
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Enter email"
                    className="mb-3"  >
                    <Form.Control type="email" placeholder="" value={props.email} name="email" onChange={props.handleChange} />
                    {props.error.email !== null ? <div className="validate"> <span> * {props.error.email} </span> </div> : ""}
                    </FloatingLabel>
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formBasicPassword">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Password"
                    className="mb-3"  >
                    <Form.Control type={props.password_show ? "text" : "password"} placeholder="" value={props.password} name="password" onChange={props.handleChange} />
                    {props.error.password !== null ? <div className="validate"> <span> * {props.error.password} </span> </div> : ""}
                    </FloatingLabel>
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formBasicPassword">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Retype Password"
                    className="mb-3"  >
                    <Form.Control type={props.password_show ? "text" : "password"} placeholder="" value={props.retyp_assword} name="retyp_assword" onChange={props.handleChange} />
                    {props.error.retyp_assword !== null ? <div className="validate"> <span> * {props.error.retyp_assword} </span> </div> : ""}
                    </FloatingLabel>
                  </Form.Group>
                  <Form.Group className="mb-3 check-reg" controlId="formBasicCheckbox">
                    <Form.Check type="checkbox" label="Show password" checked={props.password_show} onClick={props.handleShowPassword} />
                  </Form.Group>
                  <div className="form-check">
                    <ReCAPTCHA
                      sitekey="6LdWdt4pAAAAAPUgoPFgAbb27KpDwA0rz0aVFIbR"
                      // sitekey="6LcbXxQpAAAAAPjoKH67lSvQCjf4JP0Oepf-eW4J"
                      onChange={props.handleRecaptcha}
                    />
                    {props.capture ? <div className="validate"> <span> * Complete the Captcha before proceeding</span> </div> : ""}
                  </div>
                  <Button className='btn-sign-in' onClick={props.handleRegister}>Sign On</Button>
                </Form>
                <div className='btn-wrap'>
                  <a className='b-link' href='/recoverpassword'>Forgot password?</a>
                  <a className='b-link' href='/login'>Sign In</a>
                </div>
              </div>
            </div>
            <div className='login-footer reg'>
              {/* <GoogleLogin
                clientId={clientId}
                buttonText="Continue with Google"
                onSuccess={successGoogle}
                onFailure={failGoogle}
                cookiePolicy={'single_host_origin'}
              /> */}
              <a className='social-sign-in microsft' onClick={handleLogin}>Sign in with Microsoft</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
