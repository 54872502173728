import React, { PureComponent, Fragment } from "react"
import { Navigate } from 'react-router-dom'
import PropTypes from "prop-types"
import { compose } from "redux"
import { connect } from "react-redux"
import HomeView from "../pages/home"
import { contactUs, getHomeData, savePackDetailsHome } from "../hooks/home"
import HomeLoader from "../assets/common/homeLoader"
import "./style.css"

const mapStateToProps = (state) => ({
    home: state.home,
    login: state.login,
    common: state.common
})


const mapDispatchToProps = dispatch => ({
    getHomeData: () => dispatch(getHomeData()),
    contactUs: (data) => dispatch(contactUs(data)),
    savePackDetailsHome: (data, pack_list) => dispatch(savePackDetailsHome(data, pack_list))
})

const getPackage = (data, type, output) => {
    var dataArray = []
    for (let index = 0; index < data.length; index++) {
        if (data[index].billing_cycle == "1" && type === "year" && data[index].status == "1") {
            dataArray = [
                ...dataArray,
                data[index]
            ]
        } else if (data[index].billing_cycle != "1" && type === "month" && data[index].status == "1") {
            dataArray = [
                ...dataArray,
                data[index]
            ]
        }
    }
    return dataArray
}

const getValidation = (state) => {
    var name = null
    var business = null
    var email = null
    var msg = null
    var validate = true

    if (state.name == "") {
        name = "Required"
        validate = false
    }
    if (state.business == "") {
        business = "Required"
        validate = false
    }
    if (state.email == "") {
        email = "Required"
        validate = false
    }
    else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(state.email)) {
        email = "invalid email address"
        validate = false
    }
    if (state.msg == "") {
        msg = "Required"
        validate = false
    }
    return { data: { email: email, name: name, business: business, msg: msg }, validate: validate }
}

class Home extends PureComponent {

    constructor(props) {
        super(props)

        this.state = {
            plan: 0,
            bill: 0,
            contact_us: {
                name: "",
                business: "",
                email: "",
                msg: ""
            },
            package: [],
            packageUpdate: true,
            pack_show: "month",
            compare_plans: false,
            packDetails: [],
            output: [],
            error: {
                name: null,
                business: null,
                email: null,
                msg: null
            },
            redirect: false,
            faq: 0,
            title: "eProdx - AI-Powered eCommerce Product Descriptions",
            meta_title: "eProdx - AI-Powered eCommerce Product Descriptions",
            meta_description: "Power your eCommerce product descriptions with AI at eProdx. Craft scalable, personalized content effortlessly and unlock significant time and cost savings.",
            url: "https://eprodx.ai/"
        }
        this.props.savePackDetailsHome(null, [])
        this.props.getHomeData()
    }
    componentDidUpdate = () => {
        if (this.props.home.data !== null && this.state.packageUpdate) {
            this.setState({
                package: this.props.home.data.packages,
                packageUpdate: false,
                packDetails: getPackage(this.props.home.data.packages, this.state.pack_show, this.props.home.data.packages)
            })
        }
    }
    handleCheckout = (pack, pack_list) => {
        if (pack.package_price == 0) {
            
            //if (this.props.login.loggedIn && this.props.login.user !== null && this.props.login.user.current_package == null) {                
                this.setState({ redirect: true })
            //}
        } else {
            
            this.props.savePackDetailsHome(pack, pack_list)
        }
    }
    planSelect = (target) => {
        this.setState({ plan: target })
    }
    handleBillingPlan = (target) => {
        this.setState({ bill: target })
    }
    contactButonClick = () => {

        if (getValidation(this.state.contact_us).validate) {
            this.props.contactUs(this.state.contact_us)
            this.setState({
                contact_us: { name: "", business: "", email: "", msg: "" }
            })
        } else {
            this.setState({
                error: getValidation(this.state.contact_us).data
            })
        }
    }
    handleContactDetails = (e) => {
        this.setState({
            contact_us: {
                ...this.state.contact_us,
                [e.target.name]: e.target.value
            },
            error: {
                ...this.state.error,
                [e.target.name]: null
            }
        })
    }
    handleMode = (value) => {
        this.setState({
            pack_show: value,
            packDetails: getPackage(this.props.home.data.packages, value)
        })
    }
    handleComparePlane = () => {
        var check = "month"
        if (this.state.pack_show == "month") {
            check = "year"
        }
        this.setState({
            pack_show: check,
            packDetails: getPackage(this.props.home.data.packages, check)
        })
    }
    handleFAQ = (index) => {
        this.setState({ faq: index })
    }

    render() {
        console.log(this.state.package,"SSSSSSSSSSSSSS")
        return (<Fragment>
            {this.props.common.spinner ? <HomeLoader /> : null}
                <HomeView
                    {...this.props}
                    {...this.state}
                    handleCheckout={this.handleCheckout}
                    planSelect={this.planSelect}
                    contactButonClick={this.contactButonClick}
                    handleContactDetails={this.handleContactDetails}
                    handleMode={this.handleMode}
                    handleComparePlane={this.handleComparePlane}
                    handleFAQ={this.handleFAQ}
                />
            {this.state.redirect ? <Navigate to="/register" replace={true} /> : null}

        </Fragment>)
    }
}

Home.propTypes = {
    classes: PropTypes.object.isRequired
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(Home)
