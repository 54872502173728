import React, { PureComponent, Fragment } from "react"
import PropTypes from "prop-types"
import { compose } from "redux"
import { connect } from "react-redux"
import Privacy from "../pages/privacy"
import { privacyCms } from "../hooks/common"
import "./style.css"

const mapStateToProps = (state) => ({
    common: state.common
})

const mapDispatchToProps = dispatch => ({
    privacyCms: () => dispatch(privacyCms()),
})

class privacy extends PureComponent {

    constructor(props) {
        super(props)

        this.state = {
            title:"Privacy Policy - eProdx AI-Powered Content Creation",
            meta_title: "Privacy Policy - eProdx AI-Powered Content Creation",
            meta_description: "Review the privacy policy of eProdx, your trusted source for AI-powered content creation. Learn how we protect your privacy and personal information.",
            url: "https://eprodx.ai/privacy-policy"
        }
        this.props.privacyCms()
    }  

    render() {
        return (<Fragment>
            <Privacy
                {...this.props}
                {...this.state}       
            />
          
        </Fragment>)
    }
}

privacy.propTypes = {
    classes: PropTypes.object.isRequired
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(privacy)
