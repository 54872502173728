import React, {useState } from 'react'
import { done, refresh, edit } from "../../assets/img/images"

const View = function (props) {
   const [check, setCheck] = useState(false)
   const [editable, setEdit] = useState(false)
   const [value, setValue] = useState(null)
   // editOutput
   function handleEdit() {
      setEdit(true)
   }
   function handleDone() {
      setEdit(false)
      setValue(null)
      if (value !== null && value !== "") {
         props.editOutput(value)
      }
   }
   return (
      <div className={"checklist-row" + (check ? " act" : "")}>
         <div className="checkwrap">
            <div className="form-check">
               <input checked={check} onClick={() => setCheck(!check)} className="form-check-input" type="checkbox" value="" id="" />

               {editable ?
                  <textarea rows="2" className="form-control" id="title-input" placeholder="Title"
                     value={value === null ? props.title : value} onChange={(e) => setValue(e.target.value)} /> : <label className="form-check-label" ><h5>{value === null ? props.title : value}</h5></label>}

            </div>
         </div>
         <div className="contorl-box">
            <div className="combin-button">
               {editable ?
                  <button onClick={handleDone} className="btn-done"><img src={done} alt="" /></button>
                  : <button onClick={handleEdit} className="btn-edit"><img src={edit} alt="" /></button>}
              {!editable ?
               <button onClick={() => props.regenerate()} className="btn-refresh"><img src={refresh} alt="" /></button>:null}
            </div>
         </div>
      </div>

   );
};


export default View;
