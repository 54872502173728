import React, { PureComponent } from "react"
import { compose } from "redux"
import { connect } from "react-redux"
import { Nav, Navbar, NavDropdown, Container } from 'react-bootstrap'
import { logout } from "../hooks/login"
import { headerLogo, infoIcon } from "./img/images"
import Page from "./page"
import {isMobile} from 'react-device-detect';


const mapStateToProps = (state) => ({
  login: state.login
})

const mapDispatchToProps = dispatch => ({
  logout: () => dispatch(logout())
})

class Header extends PureComponent {

  constructor(props) {
    super(props)

    this.state = {

    }
  }


  render() {

    return (<Navbar expand="lg" className="">
      <Container>
        <div className="navwrap">
          <Navbar.Brand href="/">
            <img
              src={headerLogo}
              className="d-inline-block align-top"
              alt=""
            />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="">
              <Nav.Link href="/"> Home</Nav.Link>
              <Nav.Link href="/dashboard" className={(this.props.page == "dashboard" ? "page-name" : null)}> Dashboard</Nav.Link>
              <Nav.Link href="/about-us" className={(this.props.page == "boiler" ? "page-name" : null)}>About</Nav.Link>
              <Nav.Link href="/faq" className={(this.props.page == "faq" ? "page-name" : null)}>FAQ</Nav.Link>
              <Nav.Link href="https://eprodx.ai/blog/">Blog</Nav.Link>
              {/* <Nav.Link >Upgrade</Nav.Link> */}
            </Nav>
          </Navbar.Collapse>
          <div class="profile-submenu">
            <NavDropdown 
              title={this.props.login.user !== undefined && this.props.login.user !== null ?
                  <>{this.props.login.user.first_name} {this.props.login.user.last_name}</> : null} id="basic-nav-dropdown">
                    {this.props.login.user !== undefined && this.props.login.user !== null && this.props.login.user.is_admin === 1 ?
                  <NavDropdown.Item className="um" href="/dashboard/subscription">Super Admin</NavDropdown.Item> : null}
              
              <NavDropdown.Item className="ps" href="/dashboardprofile">Profile Settings</NavDropdown.Item>
              <NavDropdown.Item className="si" href="/dashboard/profile/subscription">Subscription Information</NavDropdown.Item>
              <NavDropdown.Item className="um">User Management</NavDropdown.Item>
              <NavDropdown.Item onClick={this.props.logout}> Sign Off </NavDropdown.Item>
            </NavDropdown>
          </div>
        </div>
      </Container>
      <Page />
      <div class="mobile-note">
        <img src={infoIcon} />
        <p>For a better expereience use the desktop version to manage more than 10 SKUs</p>
      </div>
    </Navbar>)
  }
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(Header)
