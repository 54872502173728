import React from 'react'
import { Container, Row, Col, Button, Tab, Tabs, Breadcrumb, Table, Form, FloatingLabel } from 'react-bootstrap'
import Header from "../assets/header-admin"
import Footer from "../assets/footer"
import Moment from 'moment'
import { Chart } from "react-google-charts"

export default function Home(props) {

  return (
    <div>
      <Header />
      <div className='content-wrap- dash'>
        <Container>
          <Row>
            <Col md={12}>
              <div className='dash-content reports'>
                <div className='breadscrumb-wrap'>
                  <Breadcrumb>
                    <Breadcrumb.Item>Dashboard</Breadcrumb.Item>
                    <Breadcrumb.Item active>Subscriptions</Breadcrumb.Item>
                  </Breadcrumb>
                </div>
                <h2>Back Office</h2>
                <div className='quick-info-bar'>
                  <span className='mailcheck'>{props.profile.email}</span>
                  <span>ID: {props.profile.id}</span>
                  <span>Registration Date: {Moment(props.profile.created_at).format('MMMM DD. YYYY')} </span>
                </div>



                <div className='reports-wrap subscriptions'>
                  <div className='report-result'>

                    <div className='report-inner'>
                      <Tabs
                        activeKey={props.activeKey}
                        onClick={props.hadelEventKey}
                        id="uncontrolled-tab-example"
                        className="mb-3"
                      >
                        <Tab eventKey="customers" title="Customers">
                          <Row>
                            {props.subscription.customers !== null ? props.subscription.customers.charts.map((chart) => (
                              <Col md={4}>
                                <Chart
                                  chartType="PieChart"
                                  data={chart.data}
                                  options={
                                    { title: chart.title }
                                  }
                                  width={"100%"}
                                  height={"400px"}
                                />
                              </Col>
                            )) : ""}
                          </Row>
                          <Row className='mt-5'>
                            {props.subscription.customers !== null ?
                              <Col md={12}>
                                <div className='subscriptions-tbl-wrap'>
                                  <div className='duration-wrap sub'>
                                    <FloatingLabel controlId="floatingSelect" label="Sort By">
                                      <Form.Select aria-label="Floating label select example" onChange={props.handleSort}>
                                        <option value="name">Name</option>
                                        <option value="date">Date</option>
                                      </Form.Select>
                                    </FloatingLabel>
                                  </div>
                                  <div className='tbl-subscriptions'>
                                    <Table hover>
                                      <thead>
                                        <tr>
                                          <th>Id</th>
                                          <th>First name</th>
                                          <th>Email</th>
                                          <th>Last name</th>
                                          <th>Date</th>
                                          <th>Number</th>
                                          <th>Edit</th>
                                          <th>Delete</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {props.subscription.customers.transactions.map((trans) => (
                                          <tr>
                                            <td>{trans.id}</td>
                                            <td>{trans.first_name}</td>
                                            <td>{trans.email}</td>
                                            <td>{trans.last_name}</td>
                                            <td>{Moment(trans.created_at).format('MMMM DD. YYYY')}</td>
                                            <td>{trans.phone}</td>
                                            <td><Button className='btn-edit'></Button></td>
                                            <td><Button className='btn-delete' onClick={() => props.deleteCustomerTransection(trans.transaction)}></Button></td>
                                          </tr>
                                        ))}
                                      </tbody>
                                    </Table>
                                  </div>
                                </div>
                              </Col> : ""}
                          </Row>
                        </Tab>
                        <Tab eventKey="orders" title="Orders">
                          {props.subscription.orders !== null ?
                          <Row className='mb-4'>
                            {props.subscription.orders.charts.map((chart) => (
                              <Col md={4}>
                                <Chart
                                  chartType="PieChart"
                                  data={chart.data}
                                  options={
                                    { title: chart.title }
                                  }
                                  width={"100%"}
                                  height={"400px"}
                                />
                              </Col>
                            )) }
                          </Row>:""}
                          <Row>
                            <Col md={12}>
                              {props.subscription.orders !== null ?
                                <div className='subscriptions-tbl-wrap'>
                                  <div className='duration-wrap sub'>
                                    <FloatingLabel controlId="floatingSelect" label="Sort By">
                                      <Form.Select aria-label="Floating label select example" onChange={props.handleSort}>
                                        <option value="Transaction ID">Transaction ID</option>
                                        <option value="Date">Date</option>
                                        <option value="Account Name">Account Name</option>
                                      </Form.Select>
                                    </FloatingLabel>
                                  </div>

                                  <div className='tbl-subscriptions'>
                                    <Table hover>
                                      <thead>
                                        <tr>
                                          <th>Transaction ID</th>
                                          <th>Date</th>
                                          <th>Account Name</th>
                                          <th>Package</th>
                                          <th>Licenses</th>
                                          <th>Order Value</th>
                                          <th>Edit</th>
                                          <th>Delete</th>
                                        </tr>
                                      </thead>
                                      <tbody>

                                        {props.subscription.orders.transactions.map((trans) => (
                                          <tr>
                                            <td>{trans.transaction_id}</td>
                                            <td>{trans.date}</td>
                                            <td>{trans.account_name}</td>
                                            <td>{trans.Package}</td>
                                            <td>{trans.licenses}</td>
                                            <td>{trans.order_value}</td>
                                            <td><Button className='btn-edit'></Button></td>
                                            <td><Button className='btn-delete' onClick={() => props.deleteOrderTransection(trans.transaction)}></Button></td>
                                          </tr>
                                        ))}
                                      </tbody>
                                    </Table>
                                  </div>
                                </div> : ""}

                            </Col>
                          </Row>

                        </Tab>
                      </Tabs>
                    </div>
                  </div>
                </div>


              </div>
            </Col>
          </Row>
        </Container>
      </div>
      {<Footer />}
    </div>
  );
};
