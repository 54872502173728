import React from 'react'
import { Button, Form } from 'react-bootstrap'
import mailLogo from "../assets/img/f-logo.png"

export default function Verify(props) {

  return (
    <div>
      <div className='content-wrap login verify'>
        <div className='login-wrap'>
          <div className='loginbox'>
            <div className='loginwrap-inner'>
              <div className='login-logo'>
                <img
                  src={mailLogo}
                  className="d-inline-block img-fluid"
                  alt=""
                />
              </div>
              <h5>Verify email address</h5>
              <div className='loginbox-form'>
                <div className="sendtext">
                  <p>We sent an email to {props.register.email !== null ? props.register.email : "-----"} Click the link inside to get started.</p>
                </div>
                <h4>Didn't receive email ?</h4>
                
                <Button className='btn-sign-in' onClick={props.resendEmail}>Resend email</Button>
              </div>
              <div className='btn-wrap'>
                <a className='b-link' href='/login'>Sign In</a>
                <a className='b-link' href='/register'>Sign Up</a>
              </div>
            </div>
          </div>

        </div>

      </div>

    </div>
  );
};
