import React from 'react'
import { Container, Row, Col, Button, Form, CloseButton } from 'react-bootstrap'
import { useSearchParams } from 'react-router-dom'
import Header from "../assets/header"
import { Navigate } from 'react-router-dom'

const get_direction = (register, token, email) => {
  
  var page = ""
  if (token == null || email == null) {
    page = "/"
  } else if (register.nextStep) {
    page = "/login"
  }

  return page
}

export default function Home(props) {
  const [searchParams] = useSearchParams();
  var token = searchParams.get("token");
  var email = searchParams.get("email");

  if (props.storeToken && token !== null && email !== null) {
    props.handleSaveToken(token, email)
  }


  return (
    <div>
      <Header />
      <div className='content-wrap gray'>
        <div className='forgotpw-wrap'>
          <Container>
            <Row>
              <Col md={12}>
                <div className='forgot-box-wrap'>
                  <h1>Forgot Password</h1>
                  <div className='title-line'>
                    <h2>Password</h2>
                  </div>
                  <div className='forgot-box'>
                    <div className='forgot-box-head'>
                      <h3>Forgot Password</h3>
                      <Button className='req-password' onClick={props.updatePassword}>Request New Password</Button>
                    </div>
                    <div className='forgot-text'>
                      <Form>
                        <Form.Group className="mb-3 mailfix" controlId="exampleForm.ControlInput1">
                          <Form.Control
                            type="password"
                            placeholder="Enter a new password"
                            name="password"
                            value={props.password}
                            onChange={props.handleChange} />
                          <CloseButton />
                        </Form.Group>
                        {props.error.password !== null ? <div className="validate"> <span> * {props.error.password} </span> </div> : ""}
                      </Form>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
      {get_direction(props.register, token, email) != "" ?
        <Navigate to={get_direction(props.register, token, email)} replace={true} /> : null}
    </div>
  );
};
