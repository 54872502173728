import React from 'react'
import { headerLogo } from "../assets//img/images"
import { Button, Form, FloatingLabel } from 'react-bootstrap'
import Page from "../assets/page"

export default function Verified(props) {

  return (
    <div>
      <div className='content-wrap login verified'>
        <div className='login-wrap'>
          <div className='loginbox'>
            <div className='loginwrap-inner'>
              <div className='login-logo'>
                <img
                  src={headerLogo}
                  className="d-inline-block img-fluid"
                  alt=""
                />
              </div>
              <h5>Login Authentication</h5>
              <div className='loginbox-form'>
                <div className="sendtext">
                  <p>We have sent a text message with <b>OTP</b> code to your  {props.login.otp_mail}</p>
                </div>
                <Form>
                  <Form.Group className="mb-5" controlId="formBasicEmail">
                    <FloatingLabel
                      controlId="floatingInput"
                      label="Enter your code below"
                      className="mb-3"  >
                      <Form.Control maxlength="6" type="text" placeholder="OTP" value={props.otp} name="otp" onChange={props.handleChange} />
                      {props.error.otp !== null ? <div className="validate"> <span> * {props.error.otp} </span> </div> : ""}
                    </FloatingLabel>
                  </Form.Group>
                  <Button className='btn-sign-in' onClick={props.handleSubmit}>Submit</Button>
                  <Button className="btn-showall btn btn-primary" id="otp-button" onClick={props.sendOtp}>Resend OTP</Button>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Page />
    </div>
  );
};
