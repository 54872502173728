
export const loadAuthState = () => {
    try{
        const serializedState = sessionStorage.getItem('besAuth');
        
        if(serializedState === null ){
            console.log('session not found');
            return undefined;
        }

        return JSON.parse(serializedState);
    }catch(error){
        console.log('session not found');
        return undefined;
    }
}



export const saveAuthState = (state) =>{
    try{
        const serializedState = JSON.stringify(state);
        sessionStorage.setItem('besAuth', serializedState);
    }catch(error){
        console.log('session could not be saved!');
    }

}
