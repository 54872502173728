import { API_URL } from "../Constants"
import { startLoading, stopLoading, SnackBar } from "./common"
import store from "../store"
import { productCount } from "./dashboard"

export function storeContent(data){
    return{type:'STORE_CONTENT_OUTPUT',data};
}

export function contentGenerate(data) {
    return function (dispatch) {
        dispatch(startLoading())
        dispatch(progressRunning(true))
        fetch(API_URL + "generate", {
            method: "POST",
            headers: {
                "Content-Type": "application/json;charset=utf-8",
                'Authorization': 'Bearer ' + store.getState().login.auth
            },
            body: JSON.stringify(data)
        }).then(response => response.json())
            .then((json) => {
                if(json.errors!==undefined) {
                    dispatch(SnackBar(true,'Operation timed out','error'))
                }
                if(json.results!==undefined) {
                    dispatch(storeContent(json))
                    dispatch(scrollDown(true))
                    dispatch(productCount())
                }
                dispatch(stopLoading())
                dispatch(progressRunning(false))
                dispatch(generatedCount())
                dispatch(contentHistory())
            })
            .catch((error) => {
                dispatch(progressRunning(false))
                dispatch(SnackBar(true,'Operation timed out','error'))
            })
    }
}

export function storeRegenerateContent(data){
    return { type: 'STORE_REGENERATE_OUTPUT', title: data.type, index: data.index, value:data.results[data.type][0] };
}
export function progressRunning(bool){
    return { type: 'PROGRESS_RUNNING', bool };
}
export function regenerate(data) {
    return function (dispatch) {
        dispatch(startLoading())
        dispatch(progressRunning(true))
        fetch(API_URL + "regenerate", {
            method: "POST",
            headers: {
                "Content-Type": "application/json;charset=utf-8",
                'Authorization': 'Bearer ' + store.getState().login.auth
            },
            body: JSON.stringify(data)
        }).then(response => response.json())
            .then((json) => {
                if(json.errors!==undefined) {
                    dispatch(SnackBar(true,'Operation timed out','error'))
                }
                if(json.results!==undefined) { dispatch(storeRegenerateContent(json)) }
                dispatch(stopLoading())
                dispatch(progressRunning(false))
                dispatch(generatedCount())
                dispatch(contentHistory())
            })
            .catch((error) => {
                dispatch(SnackBar(true,'Operation timed out','error'))
                dispatch(progressRunning(false))
            })
    }
}

export function storeEditContent(data){
    return{type:'STORE_EDITED_OUTPUT',data};
}

export function editOutput(data) {
    return function (dispatch) {
        dispatch(startLoading())
        dispatch(progressRunning(true))
        fetch(API_URL + "edit-results", {
            method: "POST",
            headers: {
                "Content-Type": "application/json;charset=utf-8",
                'Authorization': 'Bearer ' + store.getState().login.auth
            },
            body: JSON.stringify(data)
        }).then(response => response.json())
            .then((json) => {
                if(json.errors!==undefined) {
                    dispatch(SnackBar(true,'Operation timed out','error'))
                }
                if(json.results!==undefined) { dispatch(storeEditContent(json.results)) }
                dispatch(stopLoading())
                dispatch(progressRunning(false))
                dispatch(generatedCount())
                dispatch(contentHistory())
            })
            .catch((error) => {
                dispatch(SnackBar(true,'Operation timed out','error'))
                dispatch(progressRunning(false))
            })
    }
}

export function storeGeneratedCount(data){
    return{type:'STORE_GENERATED_COUNT',data};
}
export function generatedCount() {
    return function (dispatch) {
        dispatch(startLoading())
        fetch(API_URL + "count", {
            method: "GET",
            headers: {
                "Content-Type": "application/json;charset=utf-8",
                'Authorization': 'Bearer ' + store.getState().login.auth
            }
        }).then(response => response.json())
            .then((json) => {
                dispatch(stopLoading())
                if(json.status) {
                    dispatch(storeGeneratedCount(parseInt(json.count)))
                }
            })
            .catch((error) => {
                dispatch(progressRunning(false))
            })
    }
}
export function scrollDown(bool) { return { type: 'HANDLE_SCROLL_DOWN', bool } }


export function storeContentHistory(data) { return { type: 'STORE_CONTENT_HISTORY', data } }

export function contentHistory() {
    return function (dispatch) {
        dispatch(startLoading())
        dispatch(progressRunning(true))
        fetch(API_URL + "content-history", {
            method: "GET",
            headers: {
                "Content-Type": "application/json;charset=utf-8",
                'Authorization': 'Bearer ' + store.getState().login.auth
            }
        }).then(response => response.json())
            .then((json) => {
                if(json.status) {
                    dispatch(storeContentHistory(json.history))
                    dispatch(progressRunning(false))
                    dispatch(stopLoading())
                }
            })
            .catch((error) => {
                dispatch(progressRunning(false))
                dispatch(SnackBar(true,'Operation timed out','error'))
            })
    }
}

export function showHistoryContent(data) {
    return function (dispatch) {
        dispatch(startLoading())
        dispatch(progressRunning(true))
        fetch(API_URL + "get-content-history?id=" + data.id, {
            method: "GET",
            headers: {
                "Content-Type": "application/json;charset=utf-8",
                'Authorization': 'Bearer ' + store.getState().login.auth
            }
        }).then(response => response.json())
            .then((json) => {
                if(json.status) {
                    dispatch(storeContent(json))
                    dispatch(progressRunning(false))
                    dispatch(scrollDown(true))
                    dispatch(generatedCount())
                    dispatch(contentHistory())
                    dispatch(stopLoading())
                }
            })
            .catch((error) => {
                dispatch(progressRunning(false))
                dispatch(SnackBar(true,'Operation timed out','error'))
            })
    }
}

export function storeCategories(data) { return { type: 'STORE_CATEGORIES', data } }

export function getCategories() {
    return function (dispatch) {
        dispatch(startLoading())
        dispatch(progressRunning(true))
        fetch(API_URL + "all-categories" , {
            method: "GET",
            headers: {
                "Content-Type": "application/json;charset=utf-8",
                'Authorization': 'Bearer ' + store.getState().login.auth
            }
        }).then(response => response.json())
            .then((json) => {
                if(json.status) {
                    dispatch(storeCategories(json.category))
                    dispatch(progressRunning(false))
                }
            })
            .catch((error) => {
                dispatch(progressRunning(false))
                dispatch(SnackBar(true,'Operation timed out','error'))
            })
    }
}
export function createCategory(data) {
    return function (dispatch) {
        dispatch(startLoading())
        dispatch(progressRunning(true))
        fetch(API_URL + "create-categories", {
            method: "POST",
            headers: {
                "Content-Type": "application/json;charset=utf-8",
                'Authorization': 'Bearer ' + store.getState().login.auth
            },
            body: JSON.stringify(data)
        }).then(response => response.json())
            .then((json) => {
                if(json.status) {
                    dispatch(getCategories())
                    dispatch(productCount())
                }
                dispatch(progressRunning(false))
            })
            .catch((error) => {
                dispatch(SnackBar(true,'Operation timed out','error'))
                dispatch(progressRunning(false))
            })
    }
}
export function updateCategory(data) {
    return function (dispatch) {
        dispatch(startLoading())
        dispatch(progressRunning(true))
        fetch(API_URL + "update-categories", {
            method: "POST",
            headers: {
                "Content-Type": "application/json;charset=utf-8",
                'Authorization': 'Bearer ' + store.getState().login.auth
            },
            body: JSON.stringify(data)
        }).then(response => response.json())
            .then((json) => {
                if(json.status) {
                    dispatch(getCategories())
                    dispatch(productCount())
                }
                dispatch(progressRunning(false))
            })
            .catch((error) => {
                dispatch(SnackBar(true,'Operation timed out','error'))
                dispatch(progressRunning(false))
            })
    }
}
export function deleteCategory(id) {
    return function (dispatch) {
        dispatch(startLoading())
        dispatch(progressRunning(true))
        fetch(API_URL + "delete-categories", {
            method: "POST",
            headers: {
                "Content-Type": "application/json;charset=utf-8",
                'Authorization': 'Bearer ' + store.getState().login.auth
            },
            body: JSON.stringify({"id":id,"status" : 0})
        }).then(response => response.json())
            .then((json) => {
                if(json.status) {
                    dispatch(getCategories())
                    dispatch(productCount())
                }
                dispatch(progressRunning(false))
            })
            .catch((error) => {
                dispatch(SnackBar(true,'Operation timed out','error'))
                dispatch(progressRunning(false))
            })
    }
}
export function addChildCategory(index) { return { type: 'ADD_CHILD_CATEGORY', index } }

export function removeChildCat(index, childIndex) { return { type: 'REMOVE_CHILD_CATEGORY', index, childIndex } }

