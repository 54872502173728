export default function reducer(state = {
    prompt_engneering_local:  null,
    model: [],
    page: 1,
    promptUpdate: false
}, action) {
    switch (action.type) {
        case "STORE_PROMPT_ENGNEERING": {
            return { ...state, prompt_engneering_local: action.data,promptUpdate: true}
        }
        case "STORE_MODELS": {
            return { ...state, model: action.data}
        }
        case "CHANGE_VARIABLE_PROMPT_ENGNEERING":{
            return { ...state, prompt_engneering_local: {
                ...state.prompt_engneering_local,
                input:Object.values({
                    ...state.prompt_engneering_local.input,
                    [0]:{
                        ...state.prompt_engneering_local.input[0],
                        [action.name]: action.value
                    }
                })
            }}
        }
        case "PROMPT_PAGE": {
            return { ...state, page: action.page}
        }
        case "STOP_UPDATE_PROMPT": {
            return { ...state, promptUpdate: false}
        }
        default:
            break;
    }
    return state;
}