import React, { Fragment } from 'react'
import { Container, Row, Col, Button, Tab, Tabs, Breadcrumb, Nav, Form, FloatingLabel, CloseButton } from 'react-bootstrap'
import sidebanner from "../assets/img/ad-banner.png"
import countries from '../assets/common/countries.json'
import Header from "../assets/header-dashboard"
import Moment from 'moment'


export default function Home(props) {

  return (
    <div>
      <Header />
      <div className='content-wrap- dash'>
        <Container>
          <Row>
            <Col md={3}>
              <div className='dash-sidebar'>
                <Nav defaultActiveKey="" className="flex-column">
                  <Nav.Link className='sidelink active ps'>Profile Settings</Nav.Link>
                  <Nav.Link href="/dashboard/profile/subscription" className='sidelink si'>Subscription Info</Nav.Link>
                  <Nav.Link className='sidelink um'>User Management</Nav.Link>
                </Nav>
                <div className='sidebanner'>
                  <img
                    src={sidebanner}
                    className="d-inline-block img-fluid"
                  />
                </div>
              </div>

            </Col>
            <Col md={9}>
              <div className='dash-content'>
                <div className='breadscrumb-wrap'>
                  <Breadcrumb>
                    <Breadcrumb.Item href="/">Dashboard</Breadcrumb.Item>
                    <Breadcrumb.Item active>Profile Settings</Breadcrumb.Item>
                  </Breadcrumb>
                </div>
                <h2>Profile Settings</h2>
                <div className='quick-info-bar'>
                  <span className='mailcheck'>{props.profile.email}</span>
                  <span>ID: {props.profile.id}</span>
                  <span>Registration Date: {Moment(props.profile.created_at).format('MMMM DD. YYYY')} </span>
                </div>
                <div className='dashtabs'>
                  <Tabs
                    defaultActiveKey="accinfo"
                    id="justify-tab-example"
                    className="mb-5"

                  >
                    <Tab eventKey="accinfo" title="Account Info">
                      <div className='tab-content-wrap'>
                        <div className='tab-content-header'>
                          <h3>Account Info</h3>
                          <div className='tab-header-btnwrap'>
                            <Button variant="light " onClick={props.cancelAccountInfo} className='btncancel'>Cancel</Button>
                            <Button variant="primary" onClick={props.saveAccountInfo}>Save Changes</Button>
                          </div>
                        </div>
                        <div className='tab-contents-inner'>
                          <Form>
                            <Row className="mb-5">
                              <Form.Group as={Col} controlId="formGridEmail">
                                <FloatingLabel className={props.error.full_name !== null ? "error-field" : null}  label="Full Name">
                                  <Form.Control type="text" name="full_name" value={props.full_name} onChange={props.handleChange} placeholder="" />
                                  {props.error.full_name !== null ? <div className="validate"> <span> * {props.error.full_name} </span> </div> : ""}
                                </FloatingLabel>
                              </Form.Group>

                              <Form.Group as={Col} controlId="formGridPassword">
                                <FloatingLabel label="Company">
                                  <Form.Control type="text" name="company" value={props.company} onChange={props.handleChange} placeholder="" />
                                </FloatingLabel>
                              </Form.Group>
                            </Row>
                            <Row className="mb-5">
                              <Form.Group as={Col} controlId="formGridEmail">
                                <FloatingLabel className={props.error.email !== null ? "error-field" : null}  label="Email">
                                  <Form.Control type="texemailt" name="email" value={props.email} onChange={props.handleChange} placeholder="" />
                                  {props.error.email !== null ? <div className="validate"> <span> * {props.error.email} </span> </div> : ""}
                                </FloatingLabel>
                              </Form.Group>
                              <Form.Group as={Col} controlId="formGridPassword">
                                <FloatingLabel controlId="floatingPassword" label="Phone">
                                  <Form.Control type="text" name="phone" value={props.phone} onChange={props.handleChange} placeholder="" />
                                </FloatingLabel>
                              </Form.Group>
                            </Row>
                            <Row className="mb-5">
                              <Form.Group as={Col} >
                                <FloatingLabel controlId="floatingSelect" label="Country">
                                  <Form.Select aria-label="Floating label select" value={props.country} onChange={props.handleCountry}>
                                    {props.country === null||props.country === "" ? <option>Select country</option> : null}
                                    {countries.length > 0 ? countries.map((country) => (
                                      <option value={country.code3}>{country.name}</option>
                                    )) : ""}
                                  </Form.Select>
                                </FloatingLabel>
                              </Form.Group>
                              <Form.Group as={Col} controlId="formGridEmail">
                                <FloatingLabel controlId="floatingSelect" label="State / Province">
                                  <Form.Select disabled={props.country === null||props.country === ""} aria-label="Floating label select" value={props.state} name="state" onChange={props.handleChange}>
                                    {props.state === null||props.state === "" ? <option>Select State/ Province</option> : null}
                                    {props.state_coll.length > 0 ? props.state_coll.map((state) => (
                                      <option value={state.code}>{state.name}</option>
                                    )) : ""}
                                  </Form.Select>
                                  {props.error.state !== null ? <div className="validate"> <span> * {props.error.state} </span> </div> : ""}
                                </FloatingLabel>
                              </Form.Group>
                            </Row>
                          </Form>
                        </div>
                      </div>
                    </Tab>
                    <Tab eventKey="password" title="Password">
                      <div className='tab-content-wrap'>
                        <div className='tab-content-header changepw'>
                          <h3>Change Password</h3>
                          
                          <div className='tab-header-btnwrap'>
                            <Button variant="light" onClick={props.cancelPaswword} className='btncancel'>Cancel</Button>
                            <Button variant="primary" onClick={props.savePaswword}>Save Changes</Button>
                          </div>
                        </div>
                        <div className='tab-contents-inner '>
                          <Form>
                            <Row className="mb-5">
                              <Col md={12}>
                                <Form.Group as={Col} className='ps-wrap mb-5'>
                                  <FloatingLabel className={props.error_pass.current_password !== null ? "error-field" : null} label="Current Password">
                                    <Form.Control type="password" name="current_password" value={props.current_password} onChange={props.handlePasswordChange} placeholder="" />
                                    {props.error_pass.current_password !== null ? <div className="validate"> <span> * {props.error_pass.current_password} </span> </div> : ""}
                                  </FloatingLabel>
                                  <CloseButton name="current_password" onClick={props.clearData}/>
                                </Form.Group>
                              </Col>
                              <Col md={12}>
                                <Form.Group as={Col} className='ps-wrap'>
                                  <FloatingLabel className={props.error_pass.new_password !== null ? "error-field" : null} label="New Password">
                                    <Form.Control type="password" name="new_password" value={props.new_password} onChange={props.handlePasswordChange} placeholder="" />
                                    {props.error_pass.new_password !== null ? <div className="validate"> <span> * {props.error_pass.new_password} </span> </div> : ""}
                                  </FloatingLabel>
                                  <CloseButton name="new_password" onClick={props.clearData}/>
                                </Form.Group>
                              </Col>
                            </Row>
                          </Form>
                        </div>
                      </div>
                    </Tab>
                    <Tab eventKey="security" title="Security">
                        <div className='tab-content-wrap'>
                            <div className='tab-content-header'>
                              <h3>Security</h3>                             
                            </div>
                            <div className='tab-contents-inner secure'>
                              <Form>
                                <Row className="mb-3">
                                  <Col md={12}>
                                    <Form.Group as={Col}>
                                      <Form.Check // prettier-ignore
                                        type="switch"
                                        id="custom-switch"
                                        checked={props.two_factor_status==1}
                                        label="Enable 2 factor authentication"
                                        onChange={props.handleTwoFactor}
                                      />    
                                      <div className='security-info'>
                                        <p>Anytime a login is made, we will require a verification code <br/>For more information on 2 factor authentication, refer to our <a className='helpcenter'>Help Center</a> </p>
                                      </div>                                
                                    </Form.Group>
                                  </Col>
                                
                                </Row>
                              </Form>
                            </div>
                          </div>
                      
                    </Tab>

                  </Tabs>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};
