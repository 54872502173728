import { applyMiddleware, createStore } from 'redux';
import logger from 'redux-logger'
import {thunk} from 'redux-thunk';
import promise from "redux-promise";
import reducer from "./Reducers"
import { loadAuthState, saveAuthState } from './Reducers/AuthSession';
import throttle from 'lodash/throttle';




const middleware = applyMiddleware(promise, thunk, logger)

const store = createStore(reducer, loadAuthState(), middleware);

store.subscribe(throttle(() => {
    saveAuthState({
        login: store.getState().login
    });
}, 1000));

export default store;