export default function reducer(state = {
    data: null,
    checkout: "",
    about:null
}, action) {
    switch (action.type) {
        case "STORE_HOME_DETAILS": {
            return { ...state, data: action.data}
        }
        case "STORE_ABOUT_DETAILS": {
            return { ...state, about: action.data}
        }
        case "STORE_HOME_SELECTED_PACK_DETAILS":{
            return{...state,
                checkout: "home"
            }
        }
        case "CLEAR_HOME_CHECKOUT": {
            return{...state,
                checkout: ""
            }
        }
        default:
            break;
    }
    return state;
}
