import React, { Fragment } from 'react'
import { Container, Row, Col, Button, Accordion, Tab, Tabs, Breadcrumb, Table, Form, FloatingLabel } from 'react-bootstrap'
import Header from "../assets/header-admin"
import Footer from "../assets/footer"
import Moment from 'moment'


export default function Home(props) {

  return (
    <div>
      <Header />
      <div className='content-wrap- dash'>
        <Container>
          <Row>
            <Col md={12}>
              <div className='dash-content reports'>
                <div className='breadscrumb-wrap'>
                  <Breadcrumb>
                    <Breadcrumb.Item>Dashboard</Breadcrumb.Item>
                    <Breadcrumb.Item active>Packages</Breadcrumb.Item>
                  </Breadcrumb>
                </div>
                <h2>Back Office</h2>
                <div className='quick-info-bar'>
                  <span className='mailcheck'>{props.profile.email}</span>
                  <span>ID: {props.profile.id}</span>
                  <span>Registration Date: {Moment(props.profile.created_at).format('MMMM DD. YYYY')} </span>
                </div>
                <div className='reports-wrap packages'>
                  <div className='report-result'>

                    <div className='report-inner'>
                      <Tabs
                        activeKey={props.activeVieKey}
                        id="uncontrolled-tab-example"
                        className="mb-3"
                        onClick={props.handleViewKey}
                      >
                        <Tab eventKey="add" title={props.package_id !== 0 ? "Update" : "Add"}>
                          <Row>
                            <Col md={12}>
                              <div className='package-wrapper'>
                                <div className='pkinfo'>
                                  <p>Create a new product package.</p>
                                </div>
                                <div className='pro-eng-header'>
                                  <div className='pro-eng-steps'>
                                    <div className={'pro-eng-stepwrap ' + (props.activeKey === "0" ? 'active' : '') + (props.activeKey !== "0" && props.stepl ? 'completed' : '')}>
                                      <h3>1</h3>
                                      <h4>General Settings</h4>
                                    </div>
                                    <div className={'pro-eng-stepwrap ' + (props.activeKey === "1" ? 'active' : '') + (props.activeKey !== "1" && props.step2 ? 'completed' : '')}>
                                      <h3>2</h3>
                                      <h4>Data Inputs</h4>
                                    </div>
                                    <div className={'pro-eng-stepwrap ' + (props.activeKey === "2" ? 'active' : '') + (props.activeKey !== "2" && props.step3 ? 'completed' : '')}>
                                      <h3>3</h3>
                                      <h4>Content Output Elements</h4>
                                    </div>
                                  </div>
                                  {props.package_id !== 0?
                                   <div className='package-id'>
                                    {props.package_id !== null ? <span><h4>ID</h4> <h6>{props.package_id  }</h6></span> : null}
                                    {props.package_name !== null ? <span><h4>Name</h4> <h5>{props.package_name}</h5></span> : null}
                                  </div>:"" }
                                </div>

                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col md={12}>
                              <div className='pk-accordion-wrap'>
                                <Accordion defaultActiveKey="0" activeKey={props.activeKey}>
                                  <Accordion.Item eventKey="0">
                                    <Accordion.Header className={(parseInt(props.activeKey) > 0 ? 'font-completed' : '')} >1.General Settings</Accordion.Header>
                                    <Accordion.Body>
                                      <div className='settings general'>
                                        <p>All values are per month unless stated otherwise.</p>
                                        <h3>Catalog <span>(all fields are required)</span></h3>
                                        <div className='setting-field-wrap'>
                                          <Row>
                                            <Col md={4}>
                                              <FloatingLabel controlId="floatingPassword" label="Package ID">
                                                <Form.Control type="text" disabled placeholder="" name="id" value={props.id} />
                                              </FloatingLabel>
                                            </Col>
                                            <Col md={4}>
                                              <FloatingLabel className={props.error.package_name !== null ? "error-field" : null} controlId="floatingPassword" label="Package Name *">
                                                <Form.Control type="text" placeholder="" name="package_name" value={props.package_name} onChange={props.handleChange} />
                                                {props.error.package_name !== null ? <div className="validate"> <span> * {props.error.package_name} </span> </div> : ""}
                                              </FloatingLabel>
                                            </Col>
                                            <Col md={4}>
                                              <FloatingLabel className={props.error.package_price !== null ? "error-field" : null} controlId="floatingPassword" label="Package Price *">
                                                <Form.Control type="text" placeholder="" name="package_price" value={props.package_price} onChange={props.handleChange} />
                                              </FloatingLabel>
                                              {props.error.package_price !== null ? <div className="validate"> <span> * {props.error.package_price} </span> </div> : ""}
                                            </Col>
                                            <Col md={4}>
                                              <FloatingLabel className={props.error.sku_allowed !== null ? "error-field" : null} controlId="floatingPassword" label="SKUs Allowed *">
                                                <Form.Control type="text" placeholder="" name="sku_allowed" value={props.sku_allowed} onChange={props.handleChange} />
                                              </FloatingLabel>
                                              {props.error.sku_allowed !== null ? <div className="validate"> <span> * {props.error.sku_allowed} </span> </div> : ""}
                                            </Col>
                                            <Col md={4}>
                                              <FloatingLabel className={props.error.cat_allowed !== null ? "error-field" : null} controlId="floatingPassword" label="Categories Allowed *">
                                                <Form.Control type="text" placeholder="" name="cat_allowed" value={props.cat_allowed} onChange={props.handleChange} />
                                              </FloatingLabel>
                                              {props.error.cat_allowed !== null ? <div className="validate"> <span> * {props.error.cat_allowed} </span> </div> : ""}
                                            </Col>
                                            <Col md={4}>
                                              <FloatingLabel className={props.error.attribute !== null ? "error-field" : null} controlId="floatingPassword" label="Attribute / SKU *">
                                                <Form.Control type="text" placeholder="" name="attribute" value={props.attribute} onChange={props.handleChange} />
                                              </FloatingLabel>
                                              {props.error.attribute !== null ? <div className="validate"> <span> * {props.error.attribute} </span> </div> : ""}
                                            </Col>
                                            <Col md={4}>
                                              <FloatingLabel className={props.error.storage !== null ? "error-field" : null} controlId="floatingPassword" label="Storage in Gb *">
                                                <Form.Control type="text" placeholder="" name="storage" value={props.storage} onChange={props.handleChange} />
                                              </FloatingLabel>
                                              {props.error.storage !== null ? <div className="validate"> <span> * {props.error.storage} </span> </div> : ""}
                                            </Col>
                                            <Col md={4}>
                                              <FloatingLabel className={props.error.stripe_id !== null ? "error-field" : null} controlId="floatingPassword" label="Stripe Id *">
                                                <Form.Control type="text" placeholder="" name="stripe_id" value={props.stripe_id} onChange={props.handleChange} />
                                              </FloatingLabel>
                                              {props.error.stripe_id !== null ? <div className="validate"> <span> * {props.error.stripe_id} </span> </div> : ""}
                                            </Col>
                                            <Col md={4}>
                                              <FloatingLabel controlId="floatingPassword" label="Billing Cycle">
                                                <Form.Select aria-label="Floating label select" className='form-select' name="billing_cycle" value={props.billing_cycle} onChange={props.handleChange}>
                                                  <option value="0">Month</option>
                                                  <option value="1">Year</option>
                                                </Form.Select>
                                              </FloatingLabel>
                                            </Col>
                                            <Col md={4}>
                                              <FloatingLabel className={props.error.product_count !== null ? "error-field" : null} controlId="floatingPassword" label="Product Count *">
                                                <Form.Control type="text" placeholder="" name="product_count" value={props.product_count} onChange={props.handleChange} />
                                              </FloatingLabel>
                                              {props.error.product_count !== null ? <div className="validate"> <span> * {props.error.product_count} </span> </div> : ""}
                                            </Col>
                                            <Col md={8}>
                                              <FloatingLabel className='pack-description' controlId="floatingPassword" label="Package Description">
                                                <Form.Control type="text" placeholder="" name="pac_description" value={props.pac_description} onChange={props.handleChange} />
                                              </FloatingLabel>
                                            </Col>
                                            <Col md={8}>
                                              <FloatingLabel  className='pack-description' controlId="floatingPassword" label="Package Footer">
                                                <Form.Control type="text" placeholder="" name="pac_footer" value={props.pac_footer} onChange={props.handleChange} />
                                              </FloatingLabel>
                                            </Col>
                                            <Col md={4}>
                                              {console.log(props.contact_us,"props.contact_us")}
                                              <Form.Check
                                                type="checkbox"
                                                checked={props.contact_us}
                                                name="contact_us"
                                                onClick={props.handleContactUs}
                                                label="Contact us"
                                              />
                                            </Col>
                                          </Row>
                                        </div>
                                      </div>
                                      <div className='settings general'>
                                        <h3>Add-ons</h3>
                                        <div className='setting-field-wrap'>
                                          <Row>
                                            <Col md={4}>
                                              <FloatingLabel controlId="floatingPassword" label="Additional SKUs Allowed?">
                                                <Form.Select aria-label="Floating label select" className='form-select' name="sku" value={props.sku} onChange={props.handleChange}>
                                                  <option value="0">No</option>
                                                  <option value="1">Yes</option>
                                                </Form.Select>
                                              </FloatingLabel>
                                            </Col>
                                            <Col md={4}>
                                              <FloatingLabel controlId="floatingPassword" label="Price Per Additional SKU">
                                                <Form.Control type="text" placeholder="" name="price_sku" value={props.price_sku} onChange={props.handleChange}/>
                                              </FloatingLabel>
                                            </Col>
                                            <Col md={4}>
                                              <FloatingLabel controlId="floatingPassword" label="Additional Categories Allowed">
                                                <Form.Select aria-label="Floating label select" className='form-select' name="category" value={props.category} onChange={props.handleChange}>
                                                  <option value="0">No</option>
                                                  <option value="1">Yes</option>
                                                </Form.Select>
                                              </FloatingLabel>
                                            </Col>
                                            <Col md={4}>
                                              <FloatingLabel controlId="floatingPassword" label="Price Per Additional SKU">
                                                <Form.Control type="text" placeholder="" name="price_category" value={props.price_category} onChange={props.handleChange}/>
                                              </FloatingLabel>
                                            </Col>
                                            <Col md={4}>
                                              <FloatingLabel controlId="floatingPassword" label="Additional Storage Allowed">
                                                <Form.Select aria-label="Floating label select" className='form-select' name="storage" value={props.storage} onChange={props.handleChange}>
                                                  <option value="0">No</option>
                                                  <option value="1">Yes</option>
                                                </Form.Select>
                                              </FloatingLabel>
                                            </Col>
                                            <Col md={4}>
                                              <FloatingLabel controlId="floatingPassword" label="Price Per Additional Gb / Month">
                                                <Form.Control type="text" placeholder="" name="price_storage" value={props.price_storage} onChange={props.handleChange}/>
                                              </FloatingLabel>
                                            </Col>

                                          </Row>
                                        </div>
                                      </div>
                                      <div className='settings general'>
                                        <h3>Discounts</h3>
                                        <div className='setting-field-wrap tbl'>
                                          <Row>
                                            <Col md={12}>
                                              <Table hover>
                                                <thead>
                                                  <tr>
                                                    <th>Id</th>
                                                    <th>Name</th>
                                                    <th>Discount Type</th>
                                                    <th>Status</th>
                                                    <th>Tool for Calculation</th>
                                                    <th>Discount Given</th>
                                                    <th>Customer Pays</th>
                                                    <th>Edit</th>
                                                  </tr>
                                                </thead>
                                                <tbody>{props.disc_edit?
                                                      <tr>
                                                        <td><Form.Control type="text" placeholder="" name="transaction_id" value={props.dicount_id} /></td>
                                                        <td><Form.Control type="text" placeholder="" name="dis_name" value={props.dis_name} onChange={props.handleChange} /></td>
                                                        <td>
                                                          <Form.Select aria-label="Floating label select" className='form-select' name="dis_type" value={props.dis_type} onChange={props.handleChange}>
                                                            <option value="0">No</option>
                                                            <option value="1">Yes</option>
                                                          </Form.Select>
                                                        </td>
                                                        <td>
                                                          <Form.Select aria-label="Floating label select" className='form-select' name="dis_status" value={props.dis_status} onChange={props.handleChange}>
                                                            <option value="0">deactive</option>
                                                            <option value="1">active</option>
                                                          </Form.Select>
                                                        </td>
                                                        <td> <Form.Control type="text" placeholder="" name="calculation" value={props.calculation} onChange={props.handleChange} /> </td>
                                                        <td><Form.Control type="text" placeholder="" name="discount_amount" value={props.discount_amount} onChange={props.handleChange} /></td>
                                                        <td><Form.Control type="text" placeholder="" name="customer_pays" value={props.customer_pays} onChange={props.handleChange} /></td>
                                                        <td>
                                                          <Button className='btn-save' name="save" onClick={props.handleEditDiscount}></Button>
                                                        </td>
                                                      </tr> :
                                                      <tr>
                                                        <td>{props.dicount_id}</td>
                                                        <td>{props.dis_name}</td>
                                                        <td>{props.dis_type == "1" ? "Month" : "Term"}</td>
                                                        <td>{props.dis_status == "1" ? "active" : "deactive"}</td>
                                                        <td>{props.calculation}</td>
                                                        <td>{props.discount_amount}</td>
                                                        <td>{props.customer_pays}</td>
                                                        <td> <Button className='btn-edit' name="edit" onClick={props.handleEditDiscount} /></td>
                                                      </tr>}
                                                </tbody>
                                              </Table>

                                            </Col>

                                          </Row>
                                        </div>
                                        <Button variant="primary" className='btn-next' onClick={props.handleNext}>Next</Button>
                                      </div>
                                    </Accordion.Body>
                                  </Accordion.Item>
                                  <Accordion.Item eventKey="1">
                                    <Accordion.Header className={(parseInt(props.activeKey) > 1 ? 'font-completed' : '')}>2.Data Inputs</Accordion.Header>
                                    <Accordion.Body>
                                      <div className='settings datainput'>
                                        <Row>
                                          <Col md={6}>
                                            <div className='di-col1'>
                                              <h5>Manual</h5>
                                              <div className='di-tickwrap'>
                                                <Form>
                                                    <div key={`default-check`} className="mb-3">
                                                      <Form.Check // prettier-ignore
                                                        type="checkbox"
                                                        label="Product"
                                                        checked={props.manual.product_name==1}
                                                        name="product_name"
                                                        onClick={(e)=>props.handleCheckBox(e,'manual')}
                                                      />
                                                      <Form.Check
                                                        type="checkbox"
                                                        checked={props.manual.category==1}
                                                        name="category"
                                                        onClick={(e)=>props.handleCheckBox(e,'manual')}
                                                        label="Category"
                                                      />
                                                      <Form.Check
                                                        type="checkbox"
                                                        checked={props.manual.attributes==1}
                                                        name="attributes"
                                                        onClick={(e)=>props.handleCheckBox(e,'manual')}
                                                        label="Attributes"
                                                      />
                                                      <Form.Check
                                                        type="checkbox"
                                                        checked={props.manual.feature_bullets==1}
                                                        name="feature_bullets"
                                                        onClick={(e)=>props.handleCheckBox(e,'manual')}
                                                        label="Feature Bullets"
                                                      />
                                                      <Form.Check
                                                        type="checkbox"
                                                        checked={props.manual.cate_function==1}
                                                        name="cate_function"
                                                        onClick={(e)=>props.handleCheckBox(e,'manual')}
                                                        label="CateFunctions / Applicationsgory"
                                                      />
                                                      <Form.Check
                                                        type="checkbox"
                                                        checked={props.manual.measure==1}
                                                        name="measure"
                                                        onClick={(e)=>props.handleCheckBox(e,'manual')}
                                                        label="Units of Measure"
                                                      />
                                                      <Form.Check
                                                        type="checkbox"
                                                        checked={props.manual.certificate==1}
                                                        name="certificate"
                                                        onClick={(e)=>props.handleCheckBox(e,'manual')}
                                                        label="Certificates / Licenses / Standards"
                                                      />
                                                      <Form.Check
                                                        type="checkbox"
                                                        checked={props.manual.upc==1}
                                                        name="upc"
                                                        onClick={(e)=>props.handleCheckBox(e,'manual')}
                                                        label="Universal Part Numbers (UPC/MPN/GTIN/ASIN)"
                                                      />
                                                      <Form.Check
                                                        type="checkbox"
                                                        checked={props.manual.reviews==1}
                                                        name="reviews"
                                                        onClick={(e)=>props.handleCheckBox(e,'manual')}
                                                        label="Customer Reviews"
                                                      />
                                                    </div>
                                                </Form>

                                              </div>

                                            </div>
                                          </Col>
                                          <Col md={6}>
                                            <div className='di-col2'>
                                              <h5>Bulk</h5>
                                              <div className='di-tickwrap'>
                                                <Form>
                                                    <div key={`default-check`} className="mb-3">
                                                      <Form.Check // prettier-ignore
                                                        type="checkbox"
                                                        checked={props.bulk.product_name==1}
                                                        name="product_name"
                                                        onClick={(e)=>props.handleCheckBox(e,'bulk')}
                                                        label="Product"
                                                      />
                                                      <Form.Check
                                                        type="checkbox"
                                                        checked={props.bulk.category==1}
                                                        name="category"
                                                        onClick={(e)=>props.handleCheckBox(e,'bulk')}
                                                        label="Category"
                                                      />
                                                      <Form.Check
                                                        type="checkbox"
                                                        checked={props.bulk.attributes==1}
                                                        name="attributes"
                                                        onClick={(e)=>props.handleCheckBox(e,'bulk')}
                                                        label="Attributes"
                                                      />
                                                      <Form.Check
                                                        type="checkbox"
                                                        checked={props.bulk.feature_bullets==1}
                                                        name="feature_bullets"
                                                        onClick={(e)=>props.handleCheckBox(e,'bulk')}
                                                        label="Feature Bullets"
                                                      />
                                                      <Form.Check
                                                        type="checkbox"
                                                        checked={props.bulk.cate_function==1}
                                                        name="cate_function"
                                                        onClick={(e)=>props.handleCheckBox(e,'bulk')}
                                                        label="CateFunctions / Applicationsgory"
                                                      />
                                                      <Form.Check
                                                        type="checkbox"
                                                        checked={props.bulk.measure==1}
                                                        name="measure"
                                                        onClick={(e)=>props.handleCheckBox(e,'bulk')}
                                                        label="Units of Measure"
                                                      />
                                                      <Form.Check
                                                        type="checkbox"
                                                        checked={props.bulk.certificate==1}
                                                        name="certificate"
                                                        onClick={(e)=>props.handleCheckBox(e,'bulk')}
                                                        label="Certificates / Licenses / Standards"
                                                      />
                                                      <Form.Check
                                                        type="checkbox"
                                                        checked={props.bulk.upc==1}
                                                        name="upc"
                                                        onClick={(e)=>props.handleCheckBox(e,'bulk')}
                                                        label="Universal Part Numbers (UPC/MPN/GTIN/ASIN)"
                                                      />
                                                      <Form.Check
                                                        type="checkbox"
                                                        checked={props.bulk.reviews==1}
                                                        name="reviews"
                                                        onClick={(e)=>props.handleCheckBox(e,'bulk')}
                                                        label="Customer Reviews"
                                                      />
                                                    </div>
                                                </Form>

                                              </div>

                                            </div>
                                          </Col>

                                        </Row>

                                        <Button variant="light" className='btn-back' onClick={props.handleBack}>Back</Button>
                                        <Button variant="primary" className='btn-next'onClick={props.handleNext}>Next</Button>

                                      </div>
                                    </Accordion.Body>
                                  </Accordion.Item>
                                  <Accordion.Item eventKey="2">
                                    <Accordion.Header>3. Content Output Elements</Accordion.Header>
                                    <Accordion.Body>
                                      <div className='settings general'>
                                        <p>Enter the maximum output variations for each attribute (per SKU)</p>
                                        <div className='setting-field-wrap'>
                                          <Row>
                                            <Col md={4}>
                                              <FloatingLabel controlId="floatingPassword" label="Product Titles">
                                                <Form.Control type="text" placeholder="" name="product_titles" value={props.product_titles} onChange={props.handleChange}/>
                                              </FloatingLabel>
                                            </Col>
                                            <Col md={4}>
                                              <FloatingLabel controlId="floatingPassword" label="Introduction">
                                                <Form.Control type="text" placeholder="" name="introduction" value={props.introduction} onChange={props.handleChange}/>
                                              </FloatingLabel>
                                            </Col>
                                            <Col md={4}>
                                              <FloatingLabel controlId="floatingPassword" label="Feature Bullets">
                                                <Form.Control type="text" placeholder="" name="feature_bullets" value={props.feature_bullets} onChange={props.handleChange}/>
                                              </FloatingLabel>
                                            </Col>
                                            <Col md={4}>
                                              <FloatingLabel controlId="floatingPassword" label="Detailed Descriptions">
                                                <Form.Control type="text" placeholder="" name="detailed_descriptions" value={props.detailed_descriptions} onChange={props.handleChange}/>
                                              </FloatingLabel>
                                            </Col>
                                            <Col md={4}>
                                              <FloatingLabel controlId="floatingPassword" label="Closing Statement">
                                                <Form.Control type="text" placeholder="" name="closing_statement" value={props.closing_statement} onChange={props.handleChange}/>
                                              </FloatingLabel>
                                            </Col>
                                            <Col md={4}>
                                              <FloatingLabel controlId="floatingPassword" label="URL Slug">
                                                <Form.Control type="text" placeholder="" name="url_slug" value={props.url_slug} onChange={props.handleChange}/>
                                              </FloatingLabel>
                                            </Col>
                                            <Col md={4}>
                                              <FloatingLabel controlId="floatingPassword" label="Meta Description">
                                                <Form.Control type="text" placeholder="" name="meta_description" value={props.meta_description} onChange={props.handleChange}/>
                                              </FloatingLabel>
                                            </Col>
                                            <Col md={4}>
                                              <FloatingLabel controlId="floatingPassword" label="FAQs ">
                                                <Form.Control type="text" placeholder="" name="faq" value={props.faq} onChange={props.handleChange}/>
                                              </FloatingLabel>
                                            </Col>
                                            <Col md={4}>
                                              <FloatingLabel controlId="floatingPassword" label="Spec Table">
                                                <Form.Control type="text" placeholder="" name="spec_table" value={props.spec_table} onChange={props.handleChange}/>
                                              </FloatingLabel>
                                            </Col>
                                            <Col md={4}>
                                              <FloatingLabel controlId="floatingPassword" label="Applications">
                                                <Form.Control type="text" placeholder="" name="applications" value={props.applications} onChange={props.handleChange}/>
                                              </FloatingLabel>
                                            </Col>
                                            <Col md={4}>
                                              <FloatingLabel controlId="floatingPassword" label="Call to Action">
                                                <Form.Control type="text" placeholder="" name="call_to_action" value={props.call_to_action} onChange={props.handleChange}/>
                                              </FloatingLabel>
                                            </Col>
                                            <Col md={4}>
                                              <FloatingLabel controlId="floatingPassword" label="Battle Card (Us vs. Them)">
                                                <Form.Control type="text" placeholder="" name="battle_card" value={props.battle_card} onChange={props.handleChange}/>
                                              </FloatingLabel>
                                            </Col>
                                            <Col md={4}>
                                              <FloatingLabel controlId="floatingPassword" label="Customer Review Summary">
                                                <Form.Control type="text" placeholder="" name="cus_review" value={props.cus_review} onChange={props.handleChange}/>
                                              </FloatingLabel>
                                            </Col>
                                            <Col md={4}>
                                              <FloatingLabel controlId="floatingPassword" label="Product Structured Data">
                                                <Form.Control type="text" placeholder="" name="product_structure" value={props.product_structure} onChange={props.handleChange}/>
                                              </FloatingLabel>
                                            </Col>
                                            <Col md={4}>
                                              <FloatingLabel controlId="floatingPassword" label="Admin Owner">
                                                <Form.Control type="text" placeholder="" name="admin_owner" value={props.admin_owner} onChange={props.handleChange}/>
                                              </FloatingLabel>
                                            </Col>
                                            <Col md={4}>
                                              <FloatingLabel controlId="floatingPassword" label="Contributors">
                                                <Form.Control type="text" placeholder="" name="contributors" value={props.contributors} onChange={props.handleChange}/>
                                              </FloatingLabel>
                                            </Col>
                                            <Col md={4}>
                                              <FloatingLabel controlId="floatingPassword" label="Product Type">
                                                <Form.Control type="text" placeholder="" name="product_type" value={props.product_type} onChange={props.handleChange}/>
                                              </FloatingLabel>
                                            </Col>
                                            <Col md={4}>
                                              <FloatingLabel controlId="floatingPassword" label="Maximum Import">
                                                <Form.Control type="text" placeholder="" name="import" value={props.import} onChange={props.handleChange}/>
                                              </FloatingLabel>
                                            </Col>
                                            <Col md={4}>
                                              <FloatingLabel controlId="floatingPassword" label="Maximum Export">
                                                <Form.Control type="text" placeholder="" name="export" value={props.export} onChange={props.handleChange}/>
                                              </FloatingLabel>
                                            </Col>
                                          </Row>
                                        </div>
                                      </div>
                                      
                                    </Accordion.Body>
                                  </Accordion.Item>
                                  {props.activeKey == "2" ? <>
                                    <Button variant="light" className='btn-back' onClick={props.handleBack}>Back</Button>
                                    <Button variant="primary" className='btn-next' onClick={props.handleUpdate}>{props.package_id !== 0 ? "Update" : "Add"} Package</Button> </> : null}
                                </Accordion>

                              </div>
                            </Col>
                          </Row>
                        </Tab>
                        <Tab eventKey="view" title="View">
                          <Row className='mb-4'>
                            <div className='setting-field-wrap tbl pk'>
                              <Row>
                                <Col md={12}>
                                  <div className='pk-view'>
                                    <Table hover>
                                      <thead>
                                        <tr>
                                          <th>ID</th>
                                          <th>Name</th>
                                          <th>Number of SKUs</th>
                                          <th>Currency</th>
                                          <th>Price / Month</th>
                                          <th>Status</th>
                                          <th>Edit</th>
                                          <th>Delete</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {props.packages.package_list !== undefined ? props.packages.package_list.map((data, index) => (
                                          <tr>
                                            <td>{data.id}</td>
                                            <td>{data.package_name}</td>
                                            <td>{data.sku_allowed}</td>
                                            <td>USD</td>
                                            <td>{data.package_price}</td>
                                            <td>
                                              <Form.Select value={parseInt(data.status)} onChange={(e)=>props.updatePackageStatus(e, data, index)} aria-label="Floating label select" className='form-select'>
                                                <option value={0}>Inactive</option>
                                                <option value={1}>Active</option>
                                                <option value={2}>Draft</option>
                                              </Form.Select></td>
                                            <td> <Button className='btn-edit' onClick={()=>props.editpackage(data.id)}></Button></td>
                                            <td> <Button className='btn-delete' onClick={()=>props.deletePackage(data.id)}></Button></td>
                                          </tr>
                                        )):null}
                                      </tbody>
                                    </Table>
                                  </div>
                                </Col>
                              </Row>
                            </div>
                          </Row>
                        </Tab>
                      </Tabs>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      {<Footer />}
    </div>
  );
};
